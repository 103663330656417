import { Timestamp } from "./Timestamp";
import { ReportReference } from "./Report";
import { ProductKitReference } from './ProductKit';
import { JournalReference } from "./Journal";
import { RoutineReference } from "./Routine";

export class User {
  airtableRecordId?: string;
  uid: string;
  displayName: string;

  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;

  curlCupidReports?: ReportReference[];
  productKits?: ProductKitReference[];
  journals?: JournalReference[];
  routines?: RoutineReference[];

  photoURL?: string;
  lastLogin?: Timestamp;
  createdDate?: Timestamp;
  createdDateReadable?: string;
  lastLoginReadable?: string;

  public parseUser(data: any) {
    for (let property in data) {
      this[property] = data[property];
    }
    return this;
  }
}
