<ng-container *ngIf="layoutProperties$ | async as layoutProperties">
  <page-wrapper-three-column [backgroundColor]="'white'"
    [displayLeftSidebar]="true"
    [leftToolbarIcon]="'list'"
    class="product-kits-container">

    <ng-container leftSidebarHeader>
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <span class='page-title'>Curl Cupid™ <br>Product Kits</span>
        <div class="divider-bar left"></div>
        <span class="mat-small"
          [ngStyle]="{'line-height': '1'}">Enjoy hand-selected Product Kits created by our team to meet your specific hair needs.</span>
      </div>
    </ng-container>

    <ng-container leftSidebarContent>
      <div class="kit-container full-width"
        [@fade]="productsToDisplay">
        <div class="kit-menu">
          <mat-selection-list [multiple]="false"
            (selectionChange)="toggleCurrentKit($event.options[0].value)"
            *ngIf="productKits$ | async as productKits">
            <div class="kit-menu-option-container"
              *ngFor="let kit of productKits | keyvalue">
              <mat-list-option class="kit-menu-option"
                [value]="kit.value.id"
                [selected]="currentKit.id === kit.value.id"
                [disableRipple]="true"
                [ngClass]="{
                  'selected': kit.value.id === currentKit.id
                }"
                (click)="logProductKitViewedEvent()">
                <div fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="10px">
                  <mat-icon [ngStyle]="{'color': productKitMenu[kit.value.type].color}">{{ productKitMenu[kit.value.type].icon }}</mat-icon>
                  <span>{{ productKitMenu[kit.value.type].name }}</span>
                </div>
              </mat-list-option>
            </div>
          </mat-selection-list>
        </div>
      </div>
    </ng-container>

    <ng-container contentHeader
      class="content-header">
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px">
        <h3 [ngStyle]="{'margin-block-end': 0}">
          <div fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="15px">
            <ng-container *ngIf="(productKits$ | async) && currentKit">
              <mat-icon [ngStyle]="{'color': productKitMenu[currentKit.type].color}">{{ productKitMenu[currentKit.type].icon }}</mat-icon>
              <span>{{ currentKit ? productKitMenu[currentKit.type].name : "Loading kits" }}</span>
            </ng-container>
            <ng-template #noKit>
              No kit selected
            </ng-template>
          </div>
        </h3>
      </div>
    </ng-container>

    <ng-container centerContent>


      <ng-container *ngIf="!productKits$; else hasKits">
        <div class="product-catalog-container"
          [ngStyle]="{'padding': layoutProperties.innerPadding}">
          <p>Take the Quiz first to view your products!</p>
          <button mat-flat-button
            class="button"
            color="accent"
            [routerLink]="['/quiz']"
            routerLinkActive="active">Take the Quiz</button>
        </div>
      </ng-container>

      <ng-template #hasKits>
        <ng-container *ngIf="loading.loading$ | async; else productsLoaded">
          <div class="loading-container">
            <app-loading></app-loading>
          </div>
        </ng-container>

        <ng-template #productsLoaded>
          <ng-container *ngIf="currentProduct; else allProducts">
            <button mat-fab
              [@fade]="currentProduct"
              class="clear-button"
              color="accent"
              (click)="clearCurrentProduct()"
              [routerLink]="['/products/kits']"
              routerLinkActive="active">
              <mat-icon>
                close
              </mat-icon>
            </button>
            <product-page class="full-width"
              [product]="currentProduct"
              [report$]="report$"></product-page>
          </ng-container>
          <ng-template #allProducts>
            <div class="product-catalog-container full-width"
              [@fade]="!currentProduct">
              <ng-container *ngIf="!productsToDisplay; else productsLoaded">
                <div class="loading-container">
                  <app-loading></app-loading>
                </div>
              </ng-container>
              <ng-template #productsLoaded>
                <div class=""
                  [ngStyle]="{'width': '100%'}"
                  [@fade]="productsToDisplay">
                  <cdk-virtual-scroll-viewport #virtualScroll
                    class="viewport"
                    style="height: 100vh"
                    itemSize="{{ layoutProperties.isMobile ? '400' : '400' }}">
                    <div class="product-row"
                      *cdkVirtualFor="
                    let row of productsToDisplay | productFilter : combinedFiltersString : chunk;
                    let index = index;">
                      <mat-grid-list class="product-catalog"
                        cols="{{ productCatalogColumns }}"
                        rowHeight="{{ layoutProperties.isMobile ? '400' : '400' }}"
                        gutterSize="0">
                        <mat-grid-tile *ngFor="let product of row"
                          [colspan]="1"
                          [rowspan]="1"
                          [ngStyle]="{ 'border-radius': layoutProperties.borderRadius }">
                          <product-listing [product]="product"
                            [report$]="report$"
                            [height]="layoutProperties.isMobile ? 400 : 400"
                            (click)="productIndex = index"
                            [ngStyle]="{'height': '100%'}"></product-listing>
                        </mat-grid-tile>

                      </mat-grid-list>

                    </div>
                  </cdk-virtual-scroll-viewport>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-container>

    <ng-container rightSidebarContent>
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="25px">
        <user-profile [report$]="report$"
          [user$]="user$"
          [layoutProperties$]="layoutProperties$"
          [displayAll]="true"></user-profile>
      </div>
    </ng-container>
  </page-wrapper-three-column>
</ng-container>