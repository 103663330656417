import { Component, OnInit, HostListener } from "@angular/core";
import { QuizField, QuizFamily } from "@app/models/QuizField";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from 'rxjs';
import { fade } from '../../animations/fade.animation';
import { Router, ActivatedRoute } from "@angular/router";
import { TestSnackbarComponent } from "../../components/test-snackbar/test-snackbar.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogComponent } from "../../components/dialog/dialog/dialog.component";
import { QuizService } from "../../services/quiz/quiz.service";
import { MediaObserverService, LayoutProperties } from "../../services/media-observer/media-observer.service";
import { LoadingService } from '../../services/loading/loading.service';
import { environment } from '../../../environments/environment';

export enum KEY_CODE {
  ENTER = 13,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: "quiz-page",
  host: {
    class: "page"
  },
  templateUrl: "./quiz.component.html",
  styleUrls: ["./quiz.component.less"],
  animations: [fade]
})
export class QuizComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>
  isMobile: boolean;
  outerPadding: string;
  maxWidth: string;

  currentField: QuizField;
  currentFamily: QuizFamily;
  families: string[] = [
    "demographics",
    "environmentalFactors",
    "hairProperties",
    "effectsAndTransformations",
    "routine",
    "health",
    'review'
  ];
  errorHandler: { hasError: boolean; errorMsg: string };
  isInProgress: boolean = false;
  isComplete: boolean;
  isReportGenerated: boolean;
  progressValue: number;
  experienceLevel: string;
  adminMode: boolean;

  experienceLevelOptions = [
    {
      level: "beginner",
      topCaption: "New to the Game",
      bottomCaption: "I'm curious about my hair but unsure of where to start."
    },
    {
      level: "intermediate",
      topCaption: "Finding My Way",
      bottomCaption: "I have some hair knowledge but need help applying it."
    },
    {
      level: "advanced",
      topCaption: "Been On the Wave",
      bottomCaption: "I know my hair but I’m not where I want to be yet."
    }
  ];

  constructor(
    private quizService: QuizService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public layoutObserver: MediaObserverService,
    public dialog: MatDialog,
    public loading: LoadingService
  ) {
    this.loading.loadingOn();
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.init();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.adminId === environment.adminId || !environment.production) {
        this.adminMode = true;
      }
    })
  }

  private async init(): Promise<void> {
    await this.quizService.init();
    this.isReportGenerated = false;
    this.isComplete = false;
    this.errorHandler = { hasError: false, errorMsg: "" };
    this.progressValue = 0;
    this.loading.loadingOff();
  }

  public onOptionSelect(level: string): void {
    if (level === this.experienceLevel) {
      this.experienceLevel = null;
    } else {
      this.experienceLevel = level;
    }
  }

  start(experienceLevel: string): void {
    [
      this.currentField,
      this.currentFamily,
      this.isInProgress
    ] = this.quizService.startQuiz(experienceLevel);
    this.updateProgress();
  }

  async reset(): Promise<void> {
    this.isInProgress = await this.quizService.resetQuiz();
    this.init();
  }

  submit(): void {
    this.quizService.generateReport();
  }

  test(): void {
    this.quizService.testQuiz();
  }

  navigate(forward: boolean): void {
    let nextField: QuizField;

    if (forward) {
      if (this.validateField(this.currentField)) {
        nextField = this.quizService.navigateQuiz(forward, this.currentField);
      } else {
        return;
      }
    } else {
      nextField = this.quizService.navigateQuiz(forward, this.currentField);
      this.errorHandler.hasError = false;
      this.isComplete = false;
    }

    if (nextField.id === "review") {
      this.isComplete = true;
    } else {
      this.currentField = nextField;
      this.currentFamily = this.quizService.getCurrentQuizFamily(nextField);
    }
    window.scrollTo(0, 0);
    this.updateProgress();
  }

  isFirstField() {
    return this.quizService.isFirstField();
  }

  storeInputValue(value: string) {
    let currentField = this.currentField;
    switch (currentField.type) {
      case "text":
        if (value.length === 0) {
          currentField.values = [];
        } else {
          currentField.values[0] = value;
        }
        break;
      case "select":
      case "radio":
        currentField.values = [];
        if (!currentField.values.includes(value)) {
          currentField.values.push(value);
        }
        break;
      case "checkbox":
        if (!currentField.values.includes(value)) {
          currentField.values.push(value);
        } else {
          currentField.values = currentField.values.filter(v => v !== value);
        }
        break;
      default:
        return;
    }
    this.quizService.updateQuizField(currentField);
  }

  private updateProgress() {
    const i = this.families.indexOf(this.currentFamily.id);
    this.progressValue = Math.round(((i + 1) / this.families.length) * 100);
  }

  public closeDialog() {
    this.dialog.closeAll();
  }

  public openResetDialog() {
    const data = {
      title: "Hold on!",
      message:
        "Are you sure you want to exit the quiz? Your progress will lost.",
      buttonList: [
        {
          text: "Continue",
          isWarning: false,
          onClose: false
        },
        {
          text: "Quit",
          isWarning: true,
          onClose: true
        }
      ]
    };
    this.openDialog(data, (reset) => {
      if (reset) this.reset();
    })
  }

  public openErrorDialog() {
    const data = {
      title: "Something went wrong on our end.",
      message:
        "Unfortunately, we had a problem creating your report. Your quiz results have been saved and the Glimmer 411 team has been notified. We will manually run your report so expect to hear from us in a few days. We apologize for any convenience! ",
      buttonList: [
        {
          text: "Close",
          isWarning: false,
          onClose: true
        }
      ]
    }
  }

  private openDialog(data: any, callback: Function) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = data.autoFocus || true;
    dialogConfig.disableClose = data.disableClose || false;
    // dialogConfig.height = data.height || "100%";

    dialogConfig.data = { ...data };

    let dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      callback(response)
    });
  }

  public onStart(): void {
    this.isInProgress = true;
    this.start(this.experienceLevel);
  }

  public onReset(): void {
    this.reset();
  }

  public onNavigatePrevious(): void {
    this.navigate(false);
  }

  public onNavigateNext(): void {
    this.navigate(true);
  }

  public onSubmit(): void {
    this.submit();
  }

  private validateField(field: QuizField): boolean {
    const status = this.quizService.validateField(field);
    if (!status.isValid) {
      this.throwValidationError(status.error);
    }
    else {
      this.errorHandler.hasError = false;
    }
    return status.isValid;
  }

  private throwValidationError(error: string): void {
    this.errorHandler.hasError = true;
    let msg: string;

    switch (error) {
      case "no-input":
        msg = "Please make a selection before continuing.";
        break;
      case "no-age":
        msg = "Please enter your age before continuing.";
        break;
      case "no-text":
        msg = "Please enter a response before continuing.";
        break;
      default:
        break;
    }

    this.errorHandler.errorMsg = msg;
  }


  openSnackBar(data: any) {
    this._snackBar.openFromComponent(TestSnackbarComponent, {
      duration: 5000,
      data: data
    });
  }


  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (!this.isInProgress) return;
    switch (event.keyCode) {
      case KEY_CODE.ENTER:
      case KEY_CODE.RIGHT_ARROW:
        this.navigate(true);
        break;
      case KEY_CODE.LEFT_ARROW:
        this.navigate(false);
        break;
      default:
        break;
    }
  }
}
