import { Component, OnInit, Input } from '@angular/core';
import { LoadingService } from "../../../services/loading/loading.service";
import { fade } from '../../../animations/fade.animation';

@Component({
  selector: 'page-section',
  templateUrl: './page-section.component.html',
  animations: [fade],
  styleUrls: ['./page-section.component.less']
})
export class PageSectionComponent implements OnInit {
  @Input() paddingTop?: string;
  @Input() paddingBottom?: string;
  @Input() paddingLeft?: string;
  @Input() paddingRight?: string;
  @Input() padding?: string;
  @Input() backgroundColor?: string;
  @Input() align?: string;
  @Input() maxWidth?: string;

  layoutAlign?: string;

  constructor(
    public loading: LoadingService
  ) {
  }

  ngOnInit(): void {
    if (!this.paddingLeft) this.paddingLeft = this.padding.split(' ')[1];
    if (!this.paddingRight) this.paddingRight = this.padding.split(' ')[1];
    if (!this.paddingTop) this.paddingTop = this.padding.split(' ')[0];
    if (!this.paddingBottom) this.paddingBottom = this.padding.split(' ')[0];
  }

}
