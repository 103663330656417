import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PwdResetDialogComponent } from '../../components/dialog/pwd-reset-dialog/pwd-reset-dialog.component';
import { SignupDialogComponent } from '../../components/dialog/signup-dialog/signup-dialog.component';
import { Observable } from 'rxjs';
import { MediaObserverService, LayoutProperties } from '../../services/media-observer/media-observer.service'
import { AuthService } from '../../services/auth/auth.service';
import { DialogComponent } from '../../components/dialog/dialog/dialog.component';
import { fade } from '../../animations/fade.animation';
import { LoadingService } from "../../services/loading/loading.service";

@Component({
  selector: 'login-page',
  host: {
    class: 'page'
  },
  animations: [fade],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class LoginComponent implements OnInit {
  displayLogin: boolean;
  displaySignup: boolean;
  isMobile: boolean;
  outerPadding: string;
  maxWidth: string;
  signupMethod: string;
  layoutProperties$: Observable<LayoutProperties>;

  logInForm = this.formBuilder.group({
    email: ['', [
      Validators.email,
      Validators.required]
    ],
    pwd: ['', [
      Validators.required,
      Validators.minLength(8)],
    ]
  })

  isLogInState: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    public auth: AuthService,
    public layoutObserver: MediaObserverService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public loading: LoadingService
  ) {
  }

  ngOnInit(): void {
    this.auth.user$.subscribe(user => {
      if (user) {
        this.router.navigate(['/home']);
        this.dialog.closeAll();
      }
    })
    this.route.data.subscribe(data => {
      this.displaySignup = data.signup;
    })

    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  signUpWithGoogle(): void {
    this.auth.googleSignUp()
      .then(_ => {
        this.logInSuccessful();
      })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  logInSuccessful() {
    const intendedRoute = this.route.snapshot.queryParams.destination;
    this.router.navigate([intendedRoute || '/home']);
  }

  logInUnsuccessful(error: { code: string, message: string }) {
    this.loading.loadingOff();
    this.handleError(error);
  }

  logInWithGoogle(): void {
    this.loading.loadingOn();
    this.auth.googleLogIn().then(_ => {
      this.loading.loadingOff();
    })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  logInWithFacebook(): void {
    this.auth.facebookLogIn()
      .then(_ => {
        this.logInSuccessful();
      })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  logInWithEmail(email: string, pwd: string): void {
    this.auth.emailLogIn(email, pwd)
      .then(_ => {
        this.logInSuccessful();
      })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  onLogInToggle() {
    this.isLogInState = !this.isLogInState;
    this.signupMethod = null;
  }

  onPasswordReset() {
    this.openDialog("pwd-reset", {});
  }

  onLogInSubmit(): void {
    const val = this.logInForm.value;
    this.logInWithEmail(val.email, val.pwd);
  }

  onSignupWithEmail(): void {
    this.signupMethod = "email";
    this.displayLogin = false;
    this.openDialog("emailSignup", {});
  }

  onSignupWithGoogle(): void {
    this.signupMethod = "google";
    this.displayLogin = false;
    this.signUpWithGoogle();
  }

  onSignup(): void {
    this.displayLogin = false;

    switch (this.signupMethod) {
      case "email":
        this.openDialog("emailSignup", {});
        break;
      case "google":
        this.signUpWithGoogle();
        break;
      default:
        break;
    }
  }

  toggleSignup() {
    this.displaySignup = !this.displaySignup;
  }

  private handleError(error: { code: string, message: string }) {
    let errorToDisplay = error;
    switch (error.code) {
      case 'auth/invalid-email':
        errorToDisplay.message = "We couldn't find an account with that email and password combination"
        break;
      case 'auth/wrong-password':
        errorToDisplay.message = "We couldn't find an account with that email and password combination."
        break;
      default:
        break;
    }
    this.openDialog("error", errorToDisplay);
  }

  private openDialog(type: string, data: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = { ...data };
    dialogConfig.data.type = type;

    switch (type) {
      case "error":
        dialogConfig.disableClose = false;
        dialogConfig.data["title"] = "Oops!";
        if (data.code === "auth/popup-closed-by-user") return;
        this.dialog.open(DialogComponent, dialogConfig);
        break;
      case "pwd-reset":
        dialogConfig.disableClose = false;
        this.dialog.open(PwdResetDialogComponent, dialogConfig);
        break;
      case "emailSignup":
        dialogConfig.disableClose = false;
        this.dialog.open(SignupDialogComponent, dialogConfig);
        break;
      default:
        break;
    }
  }
}
