export interface QuizOption {
  value: string;
  label: string;
  icon?: string;
}

interface AirtableAttachment {
  id: string;
  url: string;
  type: string;
}

export class QuizField {
  id: string;
  dataId: string;
  title: string;
  caption: string;
  prompt: string;
  definitions: {
    wordToDefine: string,
    definition: string
  }[];
  instructions: {
    instruction: string,
    stepNumber: number
  }[];
  family: string;
  section: string;
  type: string;
  hasInputIcons: boolean;
  inputIcons: AirtableAttachment[];
  options: QuizOption[] = [];
  nav: {
    [key: string]: string;
  } = {};
  values: string[] = [];
  notes: string;
  body: string;
  details: Object[];
  placeholder?: string;
  isInput: boolean;

  constructor(properties: Object) {
    Object.keys(properties).forEach(property => {
      switch (property) {
        case "definitions":
          this.parseDefinitions(properties)
          break;
        case "instructions":
          this.parseInstructions(properties)
          break;
        case "optionLabels":
          break;
        case "optionValues":
          this.parseOptionValues(properties);
          break;
        case "navDestinations":
          break;
        case "navValues":
          this.parseNavValues(properties);
          break;
        case "isInput":
          this.parseBooleans(properties, property);
          break;
        case "isInDepth":
          this.parseBooleans(properties, property);
          break;
        case "hasInputIcons":
          this.parseBooleans(properties, property);
          break;
        case "inputIcons":
          const icons: AirtableAttachment[] = properties[property].map(
            (icon: AirtableAttachment) => {
              return {
                id: icon.id,
                url: icon.url,
                type: icon.type,
              };
            }
          );
          this.inputIcons = icons;
          break;
        case "isPreDiagnostic":
          this.parseBooleans(properties, property);
          break;
        default:
          this[property] = properties[property];
      }
    });
  }

  private parseDefinitions(properties: Object) {
    const definitionStrings = properties["definitions"].split('\n');
    this.definitions = definitionStrings.map((definitionString: string) => {
      const wordToDefine = definitionString.split(' | ')[0];
      const definition = definitionString.split(' | ')[1];
      return {
        wordToDefine: wordToDefine,
        definition: definition,
      }
    })
  }

  private parseInstructions(properties: Object) {
    let step = 1;
    const instructionStrings = properties["instructions"].split('\n');
    this.instructions = instructionStrings.map((instructionString: string) => {
      const instruction = instructionString.split(' | ')[1];
      const result = {
        instruction: instruction,
        stepNumber: step,
      }
      step += 1;
      return result;
    })
  }

  private parseOptionValues(properties: Object) {
    this.options = [];
    const labels = properties["optionLabels"] ?.split("\n");
    const values = properties["optionValues"].split("\n");
    for (let i = 0; i < values.length; i += 1) {
      const label = labels ? labels[i] : "";
      const value = values[i];
      const valueToStore = {
        value: value,
        label: label,
      };
      this.options.push(valueToStore);
    }
  }

  private parseBooleans(properties: Object, property: string) {
    switch (properties[property]) {
      case "TRUE":
        this[property] = true;
        break;
      default:
        this[property] = false;
        break;
    }
  }

  private parseNavValues(properties: Object) {
    this.nav = {};
    const values = properties["navValues"].split("\n");
    const destinations = properties["navDestinations"].split("\n");
    for (let i = 0; i < values.length; i += 1) {
      const value = values[i];
      const destination = destinations[i];
      this.nav[value] = destination;
    }
  }
}

export interface QuizFamily {
  id: string;
  title: string;
  sections: string[];
  interactions: string;
  description: string;
  image: any[];
}
