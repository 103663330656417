<div class="outer-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="25px">
  <div class="inner-container"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="25px">
    <div mat-dialog-content
      class="content"
      fxLayout="column"
      fxLayoutAlign="center start">
      <h3 mat-dialog-title>Forgot your password?</h3>
      <p> No worries, it happens. Enter your email address below and we'll shoot you an email with a password reset link.</p>
      <form class="form"
        [formGroup]="pwdResetForm"
        (ngSubmit)="onPwdReset()"
        [ngStyle]="{'width': '100%'}">
        <div class="form-field-group">
          <mat-form-field class="form-field"
            color="none"
            appearance="fill"
            [floatLabel]="true">
            <mat-label class="form-label">Email</mat-label>
            <input class="form-input"
              matInput
              formControlName="email"
              placeholder="Ex: email@example.com">
            <mat-error class="form-error"
              *ngIf="pwdResetForm.controls['email'].hasError('email')">
              Please enter a valid email address
            </mat-error>
            <mat-error class="form-error"
              *ngIf="pwdResetForm.controls['email'].hasError('required')">
              An email is required
            </mat-error>
          </mat-form-field>
        </div>
        <ng-container *ngIf="success; else noSuccess">
          <button class="button success form-submit-button"
            mat-flat-button
            color="accent"
            [disabled]="true">
            <div fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
              *ngIf="success"
              class="success-container">
              <span class="success-text">Help is on the way</span>
              <mat-icon class="success-icon">done</mat-icon>
            </div>
          </button>
        </ng-container>
        <ng-template #noSuccess>
          <button class="button form-submit-button"
            mat-flat-button
            color="accent"
            [disabled]="!pwdResetForm.valid">
            Reset Password
          </button>
        </ng-template>
        <div>
          <button mat-button
            class="button"
            mat-dialog-close>Close</button>
        </div>
      </form>
    </div>
  </div>
</div>