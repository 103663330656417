<ng-container *ngIf="layoutProperties$ | async as layoutProperties">
  <page-wrapper-three-column>

    <ng-container leftSidebarHeader>
      <div [@fade]="!loading.loading$"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <span class='page-title'>Admin Portal 🔮</span>
        <div class="divider-bar left"></div>
        <span class="mat-small"
          [ngStyle]="{'line-height': '1'}">You've stumbled across the inner workings of Curl Cupid™ 😈. Don't break anything!</span>
      </div>
    </ng-container>

    <ng-container leftSidebarContent>
      <div class="operation-menu">
        <mat-selection-list [multiple]="false"
          (selectionChange)="toggleCurrentOperation($event.options[0].value)"
          #RNTFamilies>
          <div class="operation-menu-option-container"
            *ngFor="let operation of operationOptions.ids">
            <mat-list-option class="operation-menu-option"
              [value]="operation"
              [selected]="selectedOperation.id === operation"
              [ngClass]="{
                'selected': selectedOperation.id === operation
              }">
              <mat-icon mat-list-icon>{{ operationOptions[operation].icon }}</mat-icon>
              <span>{{ operationOptions[operation].name }}</span>
            </mat-list-option>
          </div>
        </mat-selection-list>
      </div>
    </ng-container>

    <ng-container contentHeader
      class="content-header">
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px">
        <h3 [ngStyle]="{'margin-block-end': 0}">
          {{ selectedOperation ? selectedOperation.name : 'Select an Operation' }}
        </h3>
      </div>
    </ng-container>

    <div centerContent
      class="body full-width"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="25px">

      <div [ngSwitch]="selectedOperation.id"
        class="full-width">
        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="25px"
          *ngSwitchCase="'searchUsers'">
          <div class="full-width"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <form class="full-width"
              [formGroup]="userSearchForm"
              (ngSubmit)="searchUsers()"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="15px">
              <div class="form-field-group"
                fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
                fxLayoutAlign="start center"
                fxLayoutGap="15px">
                <mat-form-field appearance="fill"
                  class="form-field">
                  <mat-label>Email</mat-label>
                  <textarea matInput
                    formControlName="email"></textarea>
                </mat-form-field>
                <mat-form-field appearance="fill"
                  class="form-field">
                  <mat-label>User ID</mat-label>
                  <textarea matInput
                    formControlName="uid"></textarea>
                </mat-form-field>
                <mat-form-field appearance="fill"
                  class="form-field">
                  <mat-label>First Name</mat-label>
                  <textarea matInput
                    formControlName="firstName"></textarea>
                </mat-form-field>
              </div>
              <button mat-raised-button
                color="accent"
                class="button"
                type="submit">Search</button>
            </form>
          </div>

          <div class="search-results full-width">
            <div class="mat-elevation-z8 full-width">
              <table mat-table
                [dataSource]="users"
                class="full-width"
                multiTemplateDataRows>

                <ng-container matColumnDef="{{ column }}"
                  *ngFor="let column of displayedColumns">
                  <th mat-header-cell
                    *matHeaderCellDef> {{column}} </th>
                  <td mat-cell
                    *matCellDef="let user"> {{user[column]}} </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell
                    *matCellDef="let user"
                    [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail"
                      [@detailExpand]="user == currentSelectedUser ? 'expanded' : 'collapsed'">
                      <div class="full-width">
                        <ul>
                          <ng-container *ngIf="!user.curlCupidReports">
                            No reports.
                          </ng-container>

                          <li *ngFor="let report of user.curlCupidReports | keyvalue">
                            <button class="mat-small"
                              mat-button
                              color="accent"
                              (click)="setReport(report.key)">{{ report.value.createdDate.timestampReadable }}</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row
                  *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                  *matRowDef="let user; columns: displayedColumns;"
                  class="example-element-row"
                  [class.example-expanded-row]="currentSelectedUser === user"
                  (click)="currentSelectedUser = currentSelectedUser === user ? null : user">
                </tr>
                <tr mat-row
                  *matRowDef="let row; columns: ['expandedDetail']"
                  class="example-detail-row"></tr>
              </table>

              <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>

        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="25px"
          *ngSwitchCase="'importExport'">
          <ng-container *ngIf="loading.loading$ | async; else operationLoaded">
            <div class="loading-container">
              <app-loading></app-loading>
            </div>
          </ng-container>
          <ng-template #operationLoaded>
            <button mat-raised-button
              color="accent"
              class="button"
              (click)="runUserExport()">
              Export Users
            </button>
            <button mat-raised-button
              color="accent"
              class="button"
              (click)="runReportExport()">
              Export Reports
            </button>
            <button mat-raised-button
              color="accent"
              class="button"
              (click)="runProductKitExport()">
              Export Kits
            </button>
            <!-- <button mat-raised-button
              color="accent"
              class="button"
              (click)="refreshUserDataInAirtable()">
              Re-Sync Airtable Data
            </button> -->
          </ng-template>
        </div>

        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="25px"
          *ngSwitchCase="'createProductKits'">
          <form class="full-width"
            [formGroup]="userSearchForm"
            (ngSubmit)="searchUsers()"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <div class="form-field-group"
              fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
              fxLayoutAlign="start center"
              fxLayoutGap="15px">
              <mat-form-field appearance="fill"
                class="form-field">
                <mat-label>User ID</mat-label>
                <textarea matInput
                  formControlName="uid"></textarea>
              </mat-form-field>
            </div>
            <button mat-raised-button
              color="accent"
              class="button"
              type="submit">Search</button>
          </form>

          <ng-container *ngIf="users">

            <div class="">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="5px">
                <span class="mat-small"
                  strong>User found: </span>
                <span>{{ users[0].firstName + " " + users[0].lastName }}</span>
                <span></span>
              </div>

              <form [formGroup]="productKitForm"
                (ngSubmit)="createProductKit()"
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px">
                <mat-radio-group formControlName="kitType">
                  <mat-radio-button value="washKit">Wash Kit</mat-radio-button>
                  <mat-radio-button value="sleepKit">Sleep Kit</mat-radio-button>
                  <mat-radio-button value="moistureKit">Moisture Kit</mat-radio-button>
                  <mat-radio-button value="toolKit">Tool Kit</mat-radio-button>
                  <mat-radio-button value="stylingKit">Styling Kit</mat-radio-button>
                </mat-radio-group>
                <mat-form-field appearance="fill"
                  class="form-field">
                  <mat-label>Product IDs</mat-label>
                  <textarea matInput
                    formControlName="productIds"></textarea>
                </mat-form-field>
                <button mat-raised-button
                  color="accent"
                  class="button"
                  type="submit">Create Kit</button>
              </form>
            </div>

          </ng-container>

        </div>

        <p *ngSwitchDefault>
          Default
        </p>
      </div>

    </div>


  </page-wrapper-three-column>
</ng-container>