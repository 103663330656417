<div class="loading-spinner"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="15px"
  [ngStyle]="{
    'width': '100%',
    'height': '100%',
    'padding': '5%'
  }">
  <mat-spinner mode="indeterminate"
    style="margin: auto;"
    [ngStyle]="{'transform': 'scale(0.25)' }"></mat-spinner>
  <span class="mat-small"
    [ngStyle]="{'max-width': '500px', 'text-align': 'center'}"><b>{{ loadingMessage || "Loading..." }}</b></span>
</div>