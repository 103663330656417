<div class="outer-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="0px"
  *ngIf="layoutProperties$ | async as layoutProperties">
  <div class="inner-container"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="25px">
    <div mat-dialog-content
      class="content"
      fxLayout="column"
      fxLayoutAlign="center start">
      <div [ngStyle]="{'width': '100%', 'height': '100%'}">
        <h3 mat-dialog-title>Here are a few things to keep in mind!</h3>
        <mat-vertical-stepper #stepper
          color="accent">
          <mat-step label="Take a deep breath."
            state="air">
            <p>This report was made to fill any gaps you might have in your hair care knowledge. It is long and detailed but we promise you’ll get your money’s worth!</p>
            <button mat-flat-button
              matStepperNext
              class="button"
              color="accent">Next</button>
          </mat-step>
          <mat-step label="Take your time."
            state="snooze">
            <p>Don’t think you need to digest all of this rich, new information in one sitting. Remember you can always access your report through Curl Cupid™ whenever you need to.</p>
            <div fxLayout="row"
              fxLayoutAlign="start start"
              fxLayoutGap="10px">
              <button mat-flat-button
                matStepperNext
                class="button"
                color="accent">Next</button>
            </div>
          </mat-step>
          <mat-step label="Take it with you."
            state="add_road">
            <p>Take what you learn and apply it to your new and improved hair care journey! This report is only the beginning of a lifetime of being in touch with your hair’s wants and needs. We will be here to help you along your
              journey but your
              commitment to yourself is the true key to achieving your hair goals. Thank you for trusting in us and we hope you enjoy!</p>
            <div fxLayout="row"
              fxLayoutAlign="start start"
              fxLayoutGap="10px">
              <button mat-flat-button
                class="button"
                color="accent"
                [ngStyle]="{'width': layoutProperties.isMobile ? '100%' : 'auto'}"
                (click)="dialog.closeAll()">See Report</button>
            </div>
          </mat-step>
          <ng-template matStepperIcon="air">
            <mat-icon>air</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="snooze">
            <mat-icon>snooze</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="add_road">
            <mat-icon>add_road</mat-icon>
          </ng-template>
        </mat-vertical-stepper>
      </div>
    </div>
  </div>
</div>