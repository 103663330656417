import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Report } from '@app/models/Report';
import { LayoutProperties } from "../../../../services/media-observer/media-observer.service";

@Component({
  selector: 'rnt-hair-properties',
  templateUrl: './hair-properties.component.html',
  styleUrls: ['./hair-properties.component.less']
})
export class HairPropertiesComponent implements OnInit {
  @Input() report: Report;
  @Input() gradient: string;
  @Input() layoutProperties$: Observable<LayoutProperties>;
  @Input() errorMessage: string;

  constructor() { }

  ngOnInit(): void {

  }

}
