<ng-container *ngIf="displayReport; else noReport">
  <rnt [report$]="report$"
    [user$]="user$"
    [layoutProperties$]="layoutProperties$"
    (exitReportEvent)="exitReport()"></rnt>
</ng-container>

<ng-template #noReport>
  <page-wrapper>
    <ng-container *ngIf="loading.loading$ | async; else pageLoaded">
      <div class="loading-container">
        <app-loading></app-loading>
      </div>
    </ng-container>
    <ng-template #pageLoaded>
      <div *ngIf="layoutProperties$ | async as layoutProperties"
        class="container"
        fxLayout="column"
        fxLayoutAlign="stretch stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}"
        [@fade]="!loading.loading$">
        <div class="title-container page-title"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="15px">
          <span class='page-title'>The Curl Cupid™ Report</span>
          <div class="divider-bar left"></div>
        </div>

        <div class="report-container"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="25px">
          <div class="header-container"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="25px">
            <h2>Report History</h2>
            <p>We remember all of your past reports so you can view them at any time. Once purchased, you'll have access to your personal reports forever.</p>
          </div>
          <ng-container *ngIf="!paidReports && !unpaidReports || paidReports.length < 1 && unpaidReports.length < 1">
            <p>Take the Quiz first to view a report!</p>
            <button mat-flat-button
              class="button"
              color="accent"
              [routerLink]="['/quiz']"
              routerLinkActive="active">Take the Quiz</button>
          </ng-container>

          <ng-container *ngIf="adminMode">
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="15px"
              [ngStyle]="{'width': '100%'}">
              <form [formGroup]="resultsForm"
                (ngSubmit)="generateReportManually()"
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px">
                <mat-form-field appearance="fill">
                  <mat-label>Quiz Results</mat-label>
                  <textarea matInput
                    formControlName="manualReportData"></textarea>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>Display Name</mat-label>
                  <input matInput
                    formControlName="displayName">
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>UID</mat-label>
                  <input matInput
                    formControlName="uid">
                </mat-form-field>
                <mat-radio-group formControlName="reportGenerateType">
                  <mat-radio-button value="values">Generate using Values</mat-radio-button>
                  <mat-radio-button value="results">Generate using Results</mat-radio-button>
                </mat-radio-group>
                <button mat-raised-button
                  color="accent"
                  class="button"
                  type="submit">Generate Report Manaully</button>
              </form>
            </div>
          </ng-container>

          <!-- <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px"
            [ngStyle]="{'width': '100%'}"
            *ngIf="unpaidReports && unpaidReports.length > 0">
            <h3>Ready to Purchase</h3>
            <mat-grid-list class="reports-list"
              cols="{{ layoutProperties.isMobile ? '2' : '4'}}"
              rowHeight="1:1"
              gutterSize="25px">
              <mat-grid-tile class="report-tile-container"
                *ngFor="let report of unpaidReports"
                [colspan]="1"
                [@fade]="paidReports">
                <div class="report-tile"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxLayoutGap="20px">
                  <mat-icon *ngIf="!layoutProperties.isMobile">
                    lock
                  </mat-icon>
                  <span class="date">{{ report.createdDate.timestamp | date:'longDate' }}</span>
                  <span class="mat-small"
                    *ngIf="!layoutProperties.isMobile">{{ report.createdDate.timestamp | date:'shortTime' }}</span>

                  <button mat-flat-button
                    class="button"
                    color="accent"
                    (click)="purchaseReport(report.id)"
                    routerLinkActive="active">
                    <ng-container *ngIf="loading.loading$ | async; else notPurchasingReport">
                      <app-loading></app-loading>
                    </ng-container>
                    <ng-template #notPurchasingReport>
                      Buy Report
                    </ng-template>
                  </button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div> -->

          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px"
            [ngStyle]="{'width': '100%'}"
            *ngIf="paidReports && paidReports.length > 0">
            <h3>My Reports</h3>
            <mat-grid-list class="reports-list"
              cols="{{ layoutProperties.isMobile ? '2' : '4'}}"
              rowHeight="1:1"
              gutterSize="25px">
              <mat-grid-tile class="report-tile-container"
                *ngFor="let report of paidReports"
                [colspan]="1"
                [@fade]="paidReports">
                <div class="report-tile"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxLayoutGap="20px">
                  <mat-icon *ngIf="!layoutProperties.isMobile">
                    assignment
                  </mat-icon>
                  <span class="date">{{ report.createdDate.timestamp | date:'longDate' }}</span>
                  <span class="mat-small"
                    *ngIf="!layoutProperties.isMobile">{{ report.createdDate.timestamp | date:'shortTime' }}</span>

                  <button mat-flat-button
                    class="button"
                    color="accent"
                    [routerLink]="['/report']"
                    [queryParams]="{ reportId: report.id}"
                    routerLinkActive="active">View</button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </div>
      </div>
    </ng-template>
  </page-wrapper>
</ng-template>