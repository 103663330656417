<page-wrapper [overridePadding]="true"
  *ngIf="layoutProperties$ | async as layoutProperties">
  <div class="page-container"
    fxLayout="column"
    fxLayoutAlign="{{ layoutProperties.isMobile ? 'start center' : 'start center' }}"
    fxLayoutGap="0px"
    [@fade]="!loading.loading$">
    <div class="header-container"
      [ngStyle]="{'height': '100%' }"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="25px">
      <div class=""
        class="full-width"
        fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
        fxLayoutAlign="start center"
        fxLayoutGap="25px"
        [ngStyle]="{
              'padding': layoutProperties.outerPadding,
              'min-height': layoutProperties.isMobile ? 'auto' : '75vh'
            }">
        <div class=""
          fxLayout="column"
          fxLayoutGap="25px"
          fxLayoutAlign="{{ layoutProperties.isMobile ? 'start center' : 'center start' }}"
          fxFlex="1 1 60%">
          <div fxLayout="column"
            fxLayoutAlign="{{ layoutProperties.isMobile ? 'start center' : 'start start' }}"
            fxLayoutGap="25px">
            <h3 [style.font-weight]="'500'">Introducing Curl Cupid™</h3>
            <h1 class="site-title"
              [ngClass]="{'mobile': layoutProperties.isMobile}">Curly hair care,<br> made simple.</h1>
            <h4 class="subtitle"
              [ngClass]="{'mobile': layoutProperties.isMobile}">The first all-in-one platform designed for all your hair care needs.</h4>
            <h3 [style.font-weight]="'500'">Start seeing visible results today!</h3>
          </div>
          <div fxLayout="row"
            fxLayoutAlign="center start"
            fxLayoutGap="15px">
            <button mat-raised-button
              color="accent"
              class="button cta"
              [ngxScrollTo]="'#howItWorksSection'">Learn More</button>
            <button mat-stroked-button
              color="accent"
              class="button cta"
              [routerLink]="['/signup']"
              routerLinkActive="active">Sign Up</button>
          </div>
        </div>
        <div class="values-container card grey"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="25px"
          fxFlex="1 1 40%">
          <h4 [style.font-weight]="'500'">Curl Cupid™
            offers:</h4>
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <div fxLayout="row"
              fxLayoutAlign="start start"
              fxLayoutGap="15px">
              <mat-icon [style.color]="'#00A906'">check_circle</mat-icon>
              <span>A <b>personalized</b> hair care experience centered around you</span>
            </div>
            <div fxLayout="row"
              fxLayoutAlign="start start"
              fxLayoutGap="15px">
              <mat-icon [style.color]="'#00A906'">check_circle</mat-icon>
              <span>An expansive <b> product catalog</b> filtered to your needs</span>
            </div>
            <div fxLayout="row"
              fxLayoutAlign="start start"
              fxLayoutGap="15px">
              <mat-icon [style.color]="'#00A906'">check_circle</mat-icon>
              <span>The <b>expertise</b> you need to feel confident in your curls</span>
            </div>
          </div>
        </div>
      </div>
      <div class="feature-container full-width"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="0px"
        [ngStyle]="{
          'padding': layoutProperties.outerPadding,
          'padding-top': '0'
        }">
        <mat-grid-list #featureCards
          class="feature-list full-width"
          cols="{{ layoutProperties.isMobile ? '1' : '3' }}"
          rowHeight="{{ layoutProperties.isMobile ? '450px' : '500px' }}"
          gutterSize="50px">
          <mat-grid-tile class="feature-container card raised no-padding"
            *ngFor="let feature of featureOptions"
            [colspan]="1"
            [rowspan]="1"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <div class="feature"
              fxLayout="column"
              fxLayoutAlign="start center"
              fx-col
              fxLayoutGap="15px">
              <div class="full-width"
                fxFlex="{{ layoutProperties.isMobile ? '0 1 50%' : '0 1 40%'}}"
                [ngStyle]="{'overflow': 'hidden'}">
                <img mat-card-image
                  [ngStyle]="{'width': '100%'}"
                  src="{{ feature.img }}">
              </div>
              <div class="feature-body"
                fxLayout="column"
                fxLayoutAlign="space-around center"
                fxLayoutGap="10px"
                [ngStyle]="{'height': '100%'}">
                <h4><b>{{ feature.title }}</b></h4>
                <p>{{ feature.description }}</p>
                <button mat-stroked-button
                  class="button full-width"
                  color="accent"
                  [ngxScrollTo]="'#' + feature.feature + 'Section'">Learn More</button>
              </div>
            </div>
          </mat-grid-tile>

        </mat-grid-list>
      </div>
    </div>

    <page-section id="howItWorksSection"
      [padding]="layoutProperties.outerPadding"
      [maxWidth]="'1000px'"
      class="how-it-works-section full-width">
      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="50px">
        <div fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="35px">
          <h1>How It Works</h1>
          <div class="divider-bar"></div>
          <h3 class='body-text'>Your journey starts with the Curl Cupid™ Quiz and ends with the <b>curls of your dreams.</b></h3>
          <p class="">Curl Cupid™ allows you to explore personalized hair product & care recommendations, manage care routines for healthier hair, and discover your next favorite products, all in one place. </p>
        </div>
        <div *ngFor="let step of howItWorks"
          class="step-container step-{{ step.number }} round raised carousel-cell"
          fxLayout="column"
          fxLayoutGap="25px">
          <div class="step-inner-container"
            fxLayoutAlign="start start"
            fxLayoutGap="25px"
            fxLayout="column">
            <div class="full-width"
              fxLayoutAlign="{{ layoutProperties.isMobile ? 'start center' : 'stretch center'}}"
              fxLayoutGap="25px"
              fxLayout="{{ layoutProperties.isMobile ? 'row' : 'row' }}">
              <img src="../../../../assets/images/quiz/lp/step-{{ step.number }}.svg"
                [ngStyle]="{'max-width': layoutProperties.isMobile ? '50px' : '75px'}">
              <div class="full-width"
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="0px"
                [ngStyle]="{'width': '100%'}">
                <p class="step-number">Step {{ step.number }}</p>
                <p class="step-headline">{{ step.headline }}</p>
              </div>
            </div>
            <p class="step-description">{{ step.description }}</p>
          </div>
        </div>
        <div fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutGap="15px">
          <button mat-raised-button
            color="accent"
            class="button cta"
            [routerLink]="['/signup']"
            routerLinkActive="active">Sign Up</button>
        </div>
      </div>
    </page-section>

    <!-- <page-section id="reviewSection">
      <div class="full-width">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of dynamicSlides">
            <ng-template carouselSlide
              [id]="slide.id">
              <img [src]="slide.src"
                [alt]="slide.alt"
                [title]="slide.title">
            </ng-template>
          </ng-container>
          <ng-template carouselSlide>
            <div class="slide">
              <img src="https://via.placeholder.com/600/56a8c2"
                alt="img 6">
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </page-section> -->

    <page-section id="quizSection"
      [padding]="layoutProperties.outerPadding"
      [maxWidth]="'1000px'"
      [backgroundColor]="'gray'"
      class="quiz-section full-width">
      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="50px">
        <div class=""
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="35px">
          <div class="divider-bar"></div>
          <h1>The Curl Cupid™ Quiz</h1>
          <h3>Getting to know your curls.</h3>
          <p class="">Like branches in a real family, each Quiz Family has its own unique properties, but each Family also overlaps with others in unique ways. By breaking hair attributes into Families (i.e. Hair Properties), we're able
            to <b>assess
              the highly interdependent factors</b> that will lead you on the path to richer growth. </p>
        </div>
        <ng-container *ngIf="layoutProperties.isMobile; else quizDesktopDemo">
          <img class="round raised mobile"
            src="{{ '../../../../assets/images/quiz/lp/quiz-mobile-demo.gif' }}">
        </ng-container>
        <ng-template #quizDesktopDemo>
          <img class="round raised"
            src="{{ '../../../../assets/images/quiz/lp/quiz-demo.gif' }}">
        </ng-template>
        <div fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutGap="15px">
          <button mat-raised-button
            color="accent"
            class="button cta"
            [routerLink]="['/signup']"
            routerLinkActive="active">Sign Up</button>
        </div>
      </div>
    </page-section>

    <page-section id="reportSection"
      [padding]="layoutProperties.outerPadding"
      [maxWidth]="'1000px'"
      class="report-section full-width">
      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="50px">
        <div class=""
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="35px">
          <div class="divider-bar"></div>
          <h1>The Curl Cupid™ Report</h1>
          <h3>Personalized care guidance for healthier hair.</h3>
          <p class="">Countless hours of research went into the comprehensive recommendations you'll see in your Curl Cupid™ Report. Unlike other alternatives, Curl Cupid™ uses your specific hair and scalp symptoms, health history, and even
            environmental
            factors (and more) to <b>provide a holistic understanding of your curls.</b></p>
        </div>
        <ng-container *ngIf="layoutProperties.isMobile; else reportDesktopDemo">
          <img class="round raised mobile"
            src="{{ '../../../../assets/images/quiz/lp/report-mobile-demo.gif' }}">
        </ng-container>
        <ng-template #reportDesktopDemo>
          <img class="round raised"
            src="{{ '../../../../assets/images/quiz/lp/report-demo.gif' }}">
        </ng-template>
        <div fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutGap="15px">
          <button mat-raised-button
            color="accent"
            class="button cta"
            [routerLink]="['/signup']"
            routerLinkActive="active">Sign Up</button>
        </div>
      </div>
    </page-section>

    <page-section id="productsSection"
      [padding]="layoutProperties.outerPadding"
      [maxWidth]="'1000px'"
      class="products-section full-width"
      [backgroundColor]="'gray'">
      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="50px">
        <div class=""
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="35px">
          <div class="divider-bar"></div>
          <h1>The Curl Cupid™ Product Pantry</h1>
          <h3>A searchable catalog of products curated just for you.</h3>
          <p class="">Your Product Pantry is a catalog of products curated based on the <b>unique needs of your curls</b>. Easily filter through your Product Pantry according to your personal needs to save time and avoid wasting money in your search
            for
            better products.</p>
        </div>
        <ng-container *ngIf="layoutProperties.isMobile; else productsDesktopDemo">
          <img class="round raised mobile"
            src="{{ '../../../../assets/images/quiz/lp/products-mobile-demo.gif' }}">
        </ng-container>
        <ng-template #productsDesktopDemo>
          <img class="round raised"
            src="{{ '../../../../assets/images/quiz/lp/products-demo.gif' }}">
        </ng-template>
        <div fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutGap="15px">
          <button mat-raised-button
            color="accent"
            class="button cta"
            [routerLink]="['/signup']"
            routerLinkActive="active">Sign Up</button>
        </div>
      </div>
    </page-section>

    <!-- <div class="section section-4"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="50px"
      [ngStyle]="{
          'padding': layoutProperties.outerPadding,
          'padding-top': '0',
          'padding-bottom': '0'
        }">
      <div class=""
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="35px">
        <div class="divider-bar"></div>
        <h3>Whether you’re new to the game or a veteran, Curl Cupid™ was made for you.</h3>
        <p class="">The Curl Cupid™ Quiz was built by Glimmer 411 to help anyone with curly, coily, or kinky hair achieve the healthy hair results they desire. The beauty of the Quiz lies in its ability to meet the needs of those with
          textured hair of every type, no matter where you are on your hair journey.</p>
      </div>
      <mat-grid-list class="product-highlights-container"
        cols="{{ layoutProperties.isMobile ? '1' : '3' }}"
        rowHeight="1:1"
        gutterSize="25px">

        <mat-grid-tile class=""
          [colspan]="1"
          [rowspan]="1"
          fxLayout="column"
          fxLayoutAlign="end center"
          fxLayoutGap="10px">
          <div class="product-highlight product-highlight-1 ">
            <p class="mat-small">Gain a sense of direction by confirming what you’re doing right, suggesting new things to try and uncovering unknown issues.</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile class=""
          [colspan]="1"
          [rowspan]="1">
          <div class="product-highlight product-highlight-2 ">
            <p class="mat-small">Discover the power of consistency and apply your knowledge with just a little bit of help.</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile class=""
          [colspan]="1"
          [rowspan]="1">
          <div class="product-highlight product-highlight-3 ">
            <p class="mat-small">Journey to your next destination: your desired hair state. Hold yourself accountable every step of the way!</p>
          </div>
        </mat-grid-tile>

      </mat-grid-list>
      <div fxLayout="row"
        fxLayoutAlign="center start"
        fxLayoutGap="15px">
        <button mat-raised-button
          color="accent"
          class="button cta"
          [routerLink]="['/signup']"
          routerLinkActive="active">Sign Up</button>
      </div>
    </div> -->

    <page-section id="pricingSection"
      [padding]="layoutProperties.outerPadding"
      [maxWidth]="'1000px'"
      class="pricing-section full-width">
      <div class="section"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="50px">
        <div class=""
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="35px">
          <div class="divider-bar"></div>
          <h1>Pricing</h1>
          <h3>Try Curl Cupid™ now to get a lifetime of results for a fraction of the cost.</h3>
          <p>
            After taking the Quiz and purchasing your Report, you'll have access to Curl Cupid™ forever. Your Report and Product Pantry will be waiting for you when you're ready to take the next step in your hair journey. Just like that,
            becoming a <b>lifelong expert in your own hair </b> is as
            easy as 1, 2, 3.</p>
        </div>
        <div fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="10px">
          <h1>$10</h1>
          <p [style.opacity]="'25%'">one-time fee for your first report, $5 for every additional report</p>
        </div>
        <div fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutGap="15px">
          <button mat-raised-button
            color="accent"
            class="button cta"
            [routerLink]="['/signup']"
            routerLinkActive="active">Sign Up</button>
        </div>
      </div>
    </page-section>

    <page-section id="FAQSection"
      [padding]="layoutProperties.outerPadding"
      [maxWidth]="'1000px'"
      [backgroundColor]="'gray'"
      class="FAQ-section section full-width">
      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="50px">
        <div class=""
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="35px">
          <div class="divider-bar"></div>
          <h1>Frequently Asked Questions</h1>
        </div>
        <div fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="10px">
          <mat-accordion class="full-width"
            [displayMode]="'flat'">
            <mat-expansion-panel *ngFor="let question of FAQ">
              <mat-expansion-panel-header [ngClass]="{'mobile': layoutProperties.isMobile }">
                <mat-panel-title>
                  <span>{{ question.question }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p><b>A: </b> {{ question.answer }}</p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div fxLayout="row"
          fxLayoutAlign="center start"
          fxLayoutGap="15px">
          <button mat-raised-button
            color="accent"
            class="button cta"
            [routerLink]="['/signup']"
            routerLinkActive="active">Sign Up</button>
        </div>
      </div>
    </page-section>

  </div>


</page-wrapper>