import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { LoadingService } from '../../services/loading/loading.service';
import { Observable } from 'rxjs';
import { MediaObserverService, LayoutProperties } from '../../services/media-observer/media-observer.service'

@Component({
  selector: 'page-wrapper-three-column',
  templateUrl: './page-wrapper-three-column.component.html',
  styleUrls: ['./page-wrapper-three-column.component.less']
})
export class PageWrapperThreeColumnComponent implements OnInit {
  @ViewChild('leftSidebar') leftSidebar: MatSidenav;
  @ViewChild('rightSidebar') rightSidebar: MatSidenav;
  @Input() backgroundColor?: string;
  layoutProperties$: Observable<LayoutProperties>;
  contentHeaderWidth: string;
  @Input() displayLeftSidebar: boolean;
  @Input() displayRightSidebar: boolean;
  @Input() forceDisplayLeftSidebar: boolean = true;
  @Input() pageLoadedState: string;
  @Input() leftToolbarIcon?: string;

  constructor(
    public loading: LoadingService,
    public layoutObserver: MediaObserverService,

  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.layoutProperties$.subscribe(properties => {
      switch (properties.layoutSize) {
        case "xs":
        case "sm":
          this.displayRightSidebar = this.displayRightSidebar || false;
          this.displayLeftSidebar = this.displayLeftSidebar || false;
          break;
        case "md":
          this.displayRightSidebar = this.displayRightSidebar || false;
          this.displayLeftSidebar = this.forceDisplayLeftSidebar || this.displayLeftSidebar || true;
          break;
        case "lg":
        default:
          this.displayRightSidebar = this.displayRightSidebar || true;
          this.displayLeftSidebar = this.forceDisplayLeftSidebar || this.displayLeftSidebar || true;
          break;
      }
    })
  }

  ngOnInit(): void {
  }

  close() {
    this.leftSidebar.close();
    this.rightSidebar.close();
  }

  ngAfterViewInit() {
    // this.contentHeaderWidth = this.centerContent.nativeElement.offsetWidth;
    // console.log(this.contentHeaderWidth)
  }

  @ViewChild('centerContent')
  centerContent: ElementRef;
}
