import { Component, OnInit } from '@angular/core';
import { MediaObserverService, LayoutProperties } from "../../../services/media-observer/media-observer.service";
import { Observable } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";


@Component({
  selector: 'rnt-intro-dialog',
  templateUrl: './intro-dialog.component.html',
  styleUrls: ['./intro-dialog.component.less']
})
export class IntroDialogComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;

  constructor(
    public layoutObserver: MediaObserverService,
    public dialog: MatDialog
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  ngOnInit(): void {
  }

}
