<div class="container">
  <div mat-dialog-content
    class="content-container"
    fxLayout="row"
    fxLayoutAlign="start stretch"
    fxLayoutGap="0px">
    <mat-selection-list class="menu"
      fxFlex="33%"
      [multiple]="false">
      <mat-list-option *ngFor="let page of menuPages"
        class="menu-option"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        (click)="onPageSelect(page.id)">{{ page.title }}
      </mat-list-option>
    </mat-selection-list>
    <div class="content"
      fxFlex="67%">
      <span [ngSwitch]="currentPage"
        *ngIf="auth.user$ | async as user;">
        <div *ngSwitchCase="'profile'"
          class="profile-page"
          fxLayout="column"
          fxLayoutAlign="center start"
          fxLayoutGap="35px">
          <h3> My Profile </h3>
          <div class="avatar-container"
            fxFlex="40%"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="15px">
            <ng-container *ngIf="user.photoURL; else noPhoto">
              <img class="avatar"
                src="{{ user.photoURL }}" />
            </ng-container>
            <ng-template #noPhoto>
              <img class="avatar"
                src="../../../assets/images/misc/avatar.jpg">
            </ng-template>
            <div class=""
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="5px">
              <h4 class="name">{{ user.displayName }}</h4>
              <span class='field-title'>User since {{ user.createdDate.timestamp | date: 'M/d/yy' }}</span>
            </div>
          </div>
          <div *ngIf="user.phoneNumber"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="0px">
            <span class="field-title">Phone</span>
            <span>{{ user.phoneNumber }}</span>
          </div>
          <div *ngIf="user.email"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="0px">
            <span class="field-title">Email</span>
            <span>{{ user.email }}</span>
          </div>
          <div *ngIf="user.curlCupidReports"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="0px">
            <span class="field-title">Reports Created</span>
            <span>{{ (user.curlCupidReports | keyvalue).length }}</span>
          </div>

        </div>
        <div *ngSwitchCase="'manageAccount'"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="30px">
          <h3>Manage Account</h3>
          <a (click)="onPasswordReset()">Reset Password</a>
        </div>

        <div *ngSwitchDefault>
          Oops!
        </div>
      </span>
    </div>
    <button mat-icon-button
      class="close-button"
      (click)="oncloseDialog()">
      <mat-icon>
        close
      </mat-icon>
    </button>
  </div>
</div>