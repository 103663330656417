<ng-container *ngIf="layoutProperties$ | async as layoutProperties">
  <page-wrapper-three-column [backgroundColor]="'white'"
    [displayLeftSidebar]="!currentProduct"
    [leftToolbarIcon]="'filter_list'"
    class="products-container">

    <ng-container leftSidebarHeader>
      <div [@fade]="!loading.loading$"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <span class='page-title'>Curl Cupid™ <br>Product Pantry</span>
        <div class="divider-bar left"></div>
        <span class="mat-small"
          [ngStyle]="{'line-height': '1'}">We've used your Report to curate a pantry of products that fits your unique needs. Filter your personal pantry to find the products that best fit your needs.</span>
      </div>
    </ng-container>

    <ng-container leftSidebarContent>
      <ng-container *ngIf="!productsToDisplay || currentProduct; else filtersLoaded">
        <div [ngStyle]="{
          'margin': 'auto',
          'max-height': '75px',
          'width': '100%'
        }"
          *ngIf="!currentProduct">
          Loading...
        </div>
      </ng-container>
      <ng-template #filtersLoaded>
        <div class="filter-container"
          [@fade]="productsToDisplay">
          <mat-accordion [displayMode]="'flat'"
            [multi]="true"
            [@.disabled]="delayExpansionPanelAnimation">
            <mat-expansion-panel *ngIf="types && types.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By Type
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="type-filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'type')">
                <mat-list-option class="filter-option"
                  *ngFor="let type of types"
                  [selected]="selectedFilters.includes(type)"
                  [value]="type"> {{ type }}</mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="subtypes && subtypes.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By Subtype
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="subtype-filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'subtype')">
                <mat-list-option class="filter-option"
                  *ngFor="let subtype of subtypes"
                  [selected]="selectedFilters.includes(subtype)"
                  [value]="subtype"> {{ subtype }}</mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="brands && brands.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By Brand
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="brand-filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'brand')">
                <mat-list-option class="filter-option"
                  *ngFor="let brand of brands"
                  [selected]="selectedFilters.includes(brand)"
                  [value]="brand"> {{ brand }}</mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By Porosity
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="porosity-filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'porosity')">
                <mat-list-option class="filter-option"
                  *ngFor="let porosity of porosities"
                  [selected]="selectedFilters.includes(porosity)"
                  [value]="porosity"> {{ porosity.split(': ')[1] }}</mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel> -->
            <mat-expansion-panel *ngIf="symptoms && symptoms.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By Symptoms
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="symptom-filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'symptom')">
                <mat-list-option class="filter-option"
                  *ngFor="let symptom of symptoms"
                  [selected]="selectedFilters.includes(symptom)"
                  [value]="symptom"> {{ symptom.split(': ')[1] }}</mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="specifics && specifics.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By Specifics
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="specifics-filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'specifics')">
                <mat-list-option class="filter-option"
                  *ngFor="let spec of specifics"
                  [selected]="selectedFilters.includes(spec)"
                  [value]="spec"> {{ spec }}</mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By X-Free
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="x-free-filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'xFree')">
                <mat-list-option class="filter-option"
                  *ngFor="let xFree of xFrees"
                  [selected]="selectedFilters.includes(xFree)"
                  [value]="xFree"> {{ xFree }}</mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="filter-title">
                  By Other
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list class="filter"
                (selectionChange)="updateProductFilter($event.options[0].value, 'other')">
                <mat-list-option class="filter-option"
                  [selected]="selectedFilters.includes('Black-Owned Business')"
                  [value]="'Black-Owned Business'"> Black-Owned Business </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-template>
      <div class="full-width"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <div fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
          [ngStyle]="{
            'font-size': '0.8rem',
            'opacity': '0.4',
            'line-height': '1'
          }">
          <mat-icon>filter_list</mat-icon>
          <span>Only products that match your filters are displayed. </span>
        </div>
        <button mat-stroked-button
          [ngStyle]="{'width': '100%'}"
          class="button clear-filters-button"
          color="accent"
          (click)="clearFilters()">
          Clear Filters
        </button>
        <button mat-flat-button
          class="button full-width"
          color="accent"
          (click)="analytics.logClickedButtonEvent('products', 'products/kits')"
          routerLink="/products/kits"
          routerLinkActive="active">
          <div fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="10px">
            <mat-icon>dashboard_customize</mat-icon>
            <span>My Product Kits</span>
          </div>
        </button>
      </div>
    </ng-container>

    <ng-container contentHeader
      class="content-header">
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px">
        <h3 [ngStyle]="{'margin-block-end': 0}">
          <ng-container *ngIf="!currentProduct; else showCurrentProduct">
            {{ selectedFilters?.join(', ') || 'All Products' }}
          </ng-container>
          <ng-template #showCurrentProduct>
            {{ currentProduct.name }}
          </ng-template>
        </h3>
      </div>
    </ng-container>

    <ng-container centerContent>

      <ng-container *ngIf="!report$; else hasReport">
        <div class="product-catalog-container"
          [ngStyle]="{'padding': layoutProperties.innerPadding}">
          <p>Take the Quiz first to view your products!</p>
          <button mat-flat-button
            class="button"
            color="accent"
            [routerLink]="['/quiz']"
            routerLinkActive="active">Take the Quiz</button>
        </div>
      </ng-container>

      <ng-template #hasReport>
        <ng-container *ngIf="loading.loading$ | async; else productsLoaded">
          <div class="loading-container">
            <app-loading></app-loading>
          </div>
        </ng-container>

        <ng-template #productsLoaded>
          <ng-container *ngIf="currentProduct; else allProducts">
            <button mat-fab
              [@fade]="currentProduct"
              class="clear-button"
              color="accent"
              (click)="clearCurrentProduct()"
              [routerLink]="['/products']"
              routerLinkActive="active">
              <mat-icon>
                close
              </mat-icon>
            </button>
            <product-page class="full-width"
              [product]="currentProduct"
              [report$]="report$"></product-page>
          </ng-container>
          <ng-template #allProducts>
            <ng-container *ngIf="productsToDisplay && productsToDisplay.length > 0; else productListEmpty">
              <div class="product-catalog-container full-width"
                [@fade]="!currentProduct">
                <ng-container *ngIf="!productsToDisplay; else productsLoaded">
                  <div class="loading-container">
                    <app-loading></app-loading>
                  </div>
                </ng-container>
                <ng-template #productsLoaded>
                  <div class=""
                    [ngStyle]="{'width': '100%'}"
                    [@fade]="productsToDisplay">
                    <cdk-virtual-scroll-viewport #virtualScroll
                      class="viewport"
                      style="height: 100vh"
                      itemSize="{{ layoutProperties.isMobile ? '400' : '400' }}">
                      <div class="product-row"
                        *cdkVirtualFor="
                      let row of productsToDisplay | productFilter : combinedFiltersString : chunk;
                      let index = index;">
                        <mat-grid-list class="product-catalog"
                          cols="{{ productCatalogColumns }}"
                          rowHeight="{{ layoutProperties.isMobile ? '400' : '400' }}"
                          gutterSize="0">
                          <mat-grid-tile *ngFor="let product of row"
                            [colspan]="1"
                            [rowspan]="1"
                            [ngStyle]="{ 'border-radius': layoutProperties.borderRadius }">
                            <product-listing [product]="product"
                              [report$]="report$"
                              [height]="layoutProperties.isMobile ? 400 : 400"
                              (click)="productIndex = index"
                              [ngStyle]="{'height': '100%'}"></product-listing>
                          </mat-grid-tile>
                        </mat-grid-list>
                      </div>
                    </cdk-virtual-scroll-viewport>
                  </div>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #productListEmpty>
              <div [ngStyle]="{
                'margin': 'auto',
                'padding': '50px;'
              }">
                No products found.
              </div>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-container>

    <ng-container rightSidebarContent>
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="25px"
        class="full-width">
        <user-profile [report$]="report$"
          [user$]="user$"
          [layoutProperties$]="layoutProperties$"
          [displayAll]="true"></user-profile>
      </div>
    </ng-container>
  </page-wrapper-three-column>
</ng-container>