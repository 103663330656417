import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

/*
  Guards Curl Cupid from unathorized entry
 */
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.auth.user$.pipe(
      take(1), // read observable and take latest value
      map(user => !!user), // map value to boolean
      tap(loggedIn => {
        // if there is no user then loggedIn is false; send to /login route before continuing
        if (!loggedIn) {
          this.router.navigate(["/login"], { relativeTo: this.route });
        }
      })
    );
  }

}
