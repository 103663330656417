<div class="container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  *ngIf="layoutProperties$ | async as layoutProperties"
  fxLayoutGap="{{ layoutProperties.spacing }}">

  <p class="subsection-header"><b>INTRODUCTION</b></p>

  <key-point [layoutProperties$]="layoutProperties$"
    [backgroundColor]="gradient"
    [raised]='true'
    class="key-point"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="10px">
    <h1 class="h1">Effects & Transformations</h1>
    <p>One of the beauties of textured, curly or coily hair is it’s versatility. You are able to transform your hair in various ways to achieve a number of styles. From lace fronts to knotless braids, twist-outs to relaxers - the ways in which your
      hair can be transformed are seemingly endless. These endless <b>transformations all have an effect on your hair</b> outside of the actual style you are attempting. These effects are integral to consider when caring for your hair.</p>
  </key-point>

  <mat-tab-group dynamicHeight
    mat-stretch-tabs
    class="purple"
    [ngStyle]="{'width': '100%'}">
    <mat-tab label="Heat Transformation">
      <div fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}">
        <p class="subsection-header"><b>ABOUT YOUR HEAT TRANSFORMATION</b></p>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="gradient"
          [fullGradient]='true'
          [raised]='true'>
          <span class='h1'>Heat Transformation</span>
          <p class="">Heat transformations rely on the <b>application of heat to your hair to achieve certain styles.</b> Applying heat can be tempting and necessary when your desired style doesn’t work with curly, or coily hair, when you need to
            check
            your
            length, and when you need to blend in your leave out. Although using heat opens the door to more hairstyles, when applied incorrectly or frequently it can also damage the health of your tresses. To better care for your hair, we need to
            talk
            about how you use heat.</p>
        </key-point>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <span class="h3">Heat Transformation</span>
            <p class="h2 purple-text"><b>{{ report.effectsAndTransformations.narrative.ET1.data || errorMessage }}</b></p>
          </div>
          <p class="">{{ report.effectsAndTransformations.narrative.ET1D.data?.description || errorMessage }}</p>

        </key-point>
      </div>
    </mat-tab>
    <mat-tab label="Chemical Transformation">
      <div fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}">
        <p class="subsection-header"><b>ABOUT YOUR CHEMICAL TRANSFORMATIONS</b></p>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="gradient"
          [fullGradient]='true'
          [raised]='true'>
          <span class='h1'>Chemical Transformation</span>
          <p class="">Let’s be real, being a 100% natural may not provide the flexibility one needs to truly express themselves. Maintaining your natural curls can be a hassle. </p>
          <p>Thank God for hair dyes, perms, texturizers, and relaxers! With great power to
            chemically manipulate your hair, comes great upkeep responsibility. <b>Chemical transformation isn’t inherently “bad”</b> for your hair. The health of your hair is always contingent on how well you care for it and it’s needs. Chemically
            treated
            hair
            has different needs that need to be met. Let’s find out the implications of your chemical transformations so that you can have your cake (chemically treated hair), and eat it too (keep it healthy)!</p>
        </key-point>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <span class="h3">Chemical Transformation</span>
            <p class="h2 purple-text"><b>{{ report.effectsAndTransformations.narrative.ET2.data?.length + report.effectsAndTransformations.narrative.ET2.data?.length > 1 ? 'Transformations' : 'Transformation' || errorMessage }}</b></p>
          </div>
          <p>You have applied the following chemical transformations to your hair:</p>
          <div fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="10px">
            <div fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              class="transformation"
              *ngFor="let transformation of report.results.chemicalTransformation">
              <mat-icon>stream</mat-icon>
              <span>{{ transformation.split(': ')[1] }}</span>
            </div>
          </div>
          <p class="">{{ report.effectsAndTransformations.narrative.ET2D.data?.description || errorMessage }}</p>

        </key-point>
      </div>
    </mat-tab>
    <mat-tab label="Physical Transformation">
      <div fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}">
        <p class="subsection-header"><b>ABOUT YOUR PHYSICAL TRANSFORMATION</b></p>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="gradient"
          [fullGradient]='true'
          [raised]='true'>
          <span class='h1'>Physical Transformation</span>
          <p class="">Physical transformation is a form of hair transformation that focuses on tucking the ends of your hair to minimise the effects of everyday styling, weather conditions and more. They are an integral part to maintaining healthy
            textured, curly or coily hair. <b>Why your ends? </b>Because they are the oldest, and therefore the most delicate parts of your hair.
          </p>
          <p>Too much of anything is usually not good, and this applies to your hair as well. With this in mind, it is important to remember that Physical transformation has a range of effects on your hair. These effects aren’t all positive, so let’s
            find
            out where you stand.</p>
        </key-point>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <span class="h3">Physical Transformation</span>
            <p class="h2 purple-text"><b>{{ report.effectsAndTransformations.narrative.ET3.data?.split('Physical Transformation: ')[1] || errorMessage }}</b></p>
          </div>
          <p class="">{{ report.effectsAndTransformations.narrative.ET3D.data?.description || errorMessage }}</p>

        </key-point>

      </div>
    </mat-tab>
    <mat-tab label="Current State">
      <div fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}">
        <p class="subsection-header"><b>ABOUT YOUR CURRENT STATE</b></p>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="gradient"
          [fullGradient]='true'
          [raised]='true'>
          <span class='h1'>Current State</span>
          <p class="">To understand what your hair needs, you must consider the current state of your hair. Different long term styling choices dictate what your hair needs and how it should be taken care of. There’s nothing wrong with manipulating
            your
            hair, but let’s avoid bald spots, receding hairlines and snatched edges - okay?
          </p>
        </key-point>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <span class="h3">Your Hair's Current State</span>
            <p class="h2 purple-text"><b>{{ report.effectsAndTransformations.narrative.ET4.data?.split('Current State: ')[1] || errorMessage }}</b></p>
          </div>
          <p class="">{{ report.effectsAndTransformations.narrative.ET4D.data?.description || errorMessage }}</p>

        </key-point>

        <mat-divider></mat-divider>

        <p class="subsection-header"><b>WHAT WE RECOMMEND</b></p>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="0px">
            <span class="h2 purple-text"><b>Our Recommendation</b></span>
          </div>
          <p class="">{{ report.effectsAndTransformations.narrative.ET5D.data?.description || errorMessage }}</p>
          <div fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <mat-icon class="purple-text">
              vpn_key
            </mat-icon>
            <p class="">{{ report.effectsAndTransformations.narrative.ET5R.data?.recommendation || errorMessage }}</p>
          </div>
        </key-point>
      </div>
    </mat-tab>

  </mat-tab-group>

</div>