import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth/auth.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MediaObserverService, LayoutProperties } from '../../../services/media-observer/media-observer.service';
import { Observable } from 'rxjs';
import { ErrorDialogComponent } from "../../../components/dialog/error-dialog/error-dialog.component";
import { PwdResetDialogComponent } from "../../../components/dialog/pwd-reset-dialog/pwd-reset-dialog.component";
import { LogOutDialogComponent } from "../../../components/dialog/log-out-dialog/log-out-dialog.component";

@Component({
  selector: "app-profile-dialog",
  templateUrl: "./profile-dialog.component.html",
  styleUrls: ["./profile-dialog.component.less"]
})
export class ProfileDialogComponent implements OnInit {
  currentPage: string = "profile";
  layoutProperties$: Observable<LayoutProperties>;
  menuOpen: boolean;
  menuPages = [
    {
      id: "profile",
      title: "My Profile",
      icon: "account_circle"
    },
    {
      id: "manageAccount",
      title: "Manage Account",
      icon: "settings"
    }
  ];

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    public layoutObserver: MediaObserverService
  ) { }

  ngOnInit(): void {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  private openDialog(type: string, data: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = { ...data };
    dialogConfig.data.type = type;

    switch (type) {
      case "error":
        dialogConfig.disableClose = false;
        this.dialog.open(ErrorDialogComponent, dialogConfig);
        break;
      case "pwd-reset":
        dialogConfig.disableClose = false;
        this.dialog.open(PwdResetDialogComponent, dialogConfig);
        break;
      case "log-out":
        dialogConfig.disableClose = false;
        this.dialog.open(LogOutDialogComponent, dialogConfig);
        break;
      default:
        break;
    }
  }

  onPageSelect(id: string): void {
    this.currentPage = id;
  }

  onPasswordReset() {
    this.openDialog("pwd-reset", {});
  }

  onLogOut() {
    this.openDialog("log-out", {});
  }

  oncloseDialog() {
    this.dialog.closeAll();
  }
}
