import { Injectable } from "@angular/core";

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";

import { Observable, of } from "rxjs";
import { User } from "@app/models/User";
import { ProductKit, ProductKitReference } from "@app/models/ProductKit";
import { Report, ReportReference } from "@app/models/Report";
import { Timestamp } from "@app/models/Timestamp";
import { switchMap, combineLatest } from "rxjs/operators";
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { BehaviorSubject } from 'rxjs';

import { Routine, RoutineReference } from "@app/models/Routine";
import { Journal, JournalReference, JournalEntry, JournalEntryReference } from "@app/models/Journal";



/***************************************************************************/
/***************************************************************************/

@Injectable({
  providedIn: "root"
})
export class FirestoreService {
  private user: User;
  private userCollection: AngularFirestoreCollection<User>;
  private reportCollection: AngularFirestoreCollection<Report>;
  private productKitCollection: AngularFirestoreCollection<ProductKit>;
  private routineCollection: AngularFirestoreCollection<Routine>;
  private journalCollection: AngularFirestoreCollection<Journal>;
  private journalEntryCollection: AngularFirestoreCollection<JournalEntry>;

  user$: Observable<User>;

  constructor(
    private auth: AngularFireAuth,
    private fs: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {
    this.user$ = this.loadUser();
    this.user$.subscribe(user => {
      if (user) {
        this.analytics.setUserId(user.uid);
        this.user = user;
      }
    })
  }

  /***************************************************************************/
  /***************************************************************************/

  /* INIT */

  public init(): void {
    this.userCollection = this.fs.collection<User>("users");
    this.productKitCollection = this.fs.collection<ProductKit>("productKits");
    this.reportCollection = this.fs.collection<Report>("curlCupidReports");
    this.journalCollection = this.fs.collection<Journal>("journals");
    this.routineCollection = this.fs.collection<Routine>("routines");
    this.journalEntryCollection = this.fs.collection<JournalEntry>("journalEntries");
  }

  private loadUser(): Observable<User> {
    return this.auth.authState.pipe(
      switchMap(user => {
        if (user) {
          const path = `users/${user.uid}`;
          return this.getDocument<User>(path).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  /***************************************************************************/
  /***************************************************************************/

  /* USER */

  public getUsersByParameter(params: any): Observable<User[]> {
    let users$ = this.fs.collection<User>('users', ref => {
      let query: any = ref;
      if (params.email) { query = query.where('email', '==', params.email) };
      if (params.uid) { query = query.where('uid', '==', params.uid) };
      if (params.firstName) { query = query.where('firstName', '==', params.firstName) };
      return query;
    })

    return users$.valueChanges();
  }

  public createUser(user: User): Promise<void> {
    const [ref] = this.storeDocument(this.userCollection, user, user.uid);
    return ref;
  }

  public updateUser(uid: string, data: any) {
    return this.updateDocument<User>(`users/${uid}`, data);
  }

  /***************************************************************************/
  /***************************************************************************/

  /* REPORT */

  public async createReport(report: Report, uid?: string): Promise<void> {
    const [reportRef, id] = this.storeDocument(this.reportCollection, report);
    return reportRef
      .then(_ => {
        const path = `users/${uid || this.user.uid}`;
        const dataToUpdate = {};

        const timestamp = new Timestamp(new Date());

        const update: ReportReference = {
          id: id,
          createdDate: timestamp,
        };

        dataToUpdate[`curlCupidReports.${id}`] = { ...update };

        return this.updateDocument(path, dataToUpdate);
      })
      .catch(error => {
        console.error(error);
      });
  }

  public getReport(reportId: string): Observable<Report> {
    return this.getDocument<Report>(
      `curlCupidReports/${reportId}`
    ).valueChanges();
  }

  public updateReport(reportId: string, update: any): Promise<void> {
    return this.setDocument<Report>(
      `curlCupidReports/${reportId}`,
      update,
      true
    )
  }

  /***************************************************************************/
  /***************************************************************************/

  /* PRODUCTS */

  public getProductKits(uid?: string): Observable<ProductKit[]> {
    let kits$ = this.fs.collection<ProductKit>('productKits', ref => {
      return ref.where('uid', '==', uid || this.user.uid);
    })

    return kits$.valueChanges();
  }

  public async createProductKit(kit: ProductKit): Promise<void> {
    const [kitRef, id] = this.storeDocument(this.productKitCollection, kit);
    kit.id = id;
    return kitRef
      .then(_ => {
        const path = `users/${kit.uid}`;
        const dataToUpdate = {};

        const timestamp = new Timestamp(new Date());

        const update: ProductKitReference = {
          id: id,
          createdDate: timestamp
        };

        dataToUpdate[`productKits.${id}`] = { ...update };

        return this.updateDocument(path, dataToUpdate);
      })
      .catch(error => {
        console.error(error);
      });
  }

  /***************************************************************************/
  /***************************************************************************/

  /* ROUTINE */

  public async createRoutine(routine: Routine, uid?: string): Promise<void> {
    const [routineRef, id] = this.storeDocument(this.routineCollection, routine);
    return routineRef
      .then(_ => {
        const path = `users/${uid || this.user.uid}`;
        const dataToUpdate = {};

        const timestamp = new Timestamp(new Date());

        const update: RoutineReference = {
          id: id,
          createdDate: timestamp,
        };

        dataToUpdate[`routines.${id}`] = { ...update };

        return this.updateDocument(path, dataToUpdate);
      })
      .catch(error => {
        console.error(error);
      });
  }

  public getRoutine(routineId: string): Observable<Routine> {
    return this.getDocument<Routine>(
      `routines/${routineId}`
    ).valueChanges();
  }

  public updateRoutine(routineId: string, update: any): Promise<void> {
    return this.setDocument<Routine>(
      `routines/${routineId}`,
      update,
      true
    )
  }

  /***************************************************************************/
  /***************************************************************************/

  /* JOURNAL ENTRY */

  public async createJournalEntry(journalEntry: JournalEntry, uid?: string): Promise<void> {
    const [journalEntryRef, id] = this.storeDocument(this.journalEntryCollection, journalEntry);
    return journalEntryRef
      .then(_ => {
        const path = `users/${uid || this.user.uid}`;
        const dataToUpdate = {};

        const timestamp = new Timestamp(new Date());

        const update: JournalEntryReference = {
          id: id,
          createdDate: timestamp,
        };

        dataToUpdate[`journalEntries.${id}`] = { ...update };

        return this.updateDocument(path, dataToUpdate);
      })
      .catch(error => {
        console.error(error);
      });
  }

  public getJournalEntry(journalEntryId: string): Observable<JournalEntry> {
    return this.getDocument<JournalEntry>(
      `journalEntries/${journalEntryId}`
    ).valueChanges();
  }

  public updateJournalEntry(journalEntryId: string, update: any): Promise<void> {
    return this.setDocument<JournalEntry>(
      `journalEntries/${journalEntryId}`,
      update,
      true
    )
  }

  /***************************************************************************/
  /***************************************************************************/
  // JOURNAL

  public async createJournal(journal: Journal, uid?: string): Promise<void> {
    const [journalRef, id] = this.storeDocument(this.journalCollection, journal);
    return journalRef
      .then(_ => {
        const path = `users/${uid || this.user.uid}`;
        const dataToUpdate = {};

        const timestamp = new Timestamp(new Date());

        const update: JournalReference = {
          id: id,
          createdDate: timestamp,
        };

        dataToUpdate[`journals.${id}`] = { ...update };

        return this.updateDocument(path, dataToUpdate);
      })
      .catch(error => {
        console.error(error);
      });
  }

  public getJournal(journalId: string): Observable<Journal> {
    return this.getDocument<Journal>(
      `journals/${journalId}`
    ).valueChanges();
  }

  public updateJournal(journalId: string, update: any): Promise<void> {
    return this.setDocument<Journal>(
      `journals/${journalId}`,
      update,
      true
    )
  }


  /***************************************************************************/
  /***************************************************************************/

  /* HELPER FUNCTIONS */

  private getDocument<T>(path: string): AngularFirestoreDocument<T> {
    return this.fs.doc<T>(path);
  }

  private setDocument<T>(
    path: string,
    data: any,
    merge: boolean
  ): Promise<void> {
    let dataToStore = JSON.parse(JSON.stringify(data));
    return this.getDocument<T>(path).set(dataToStore, { merge: merge });
  }

  private updateDocument<T>(path: string, data: any): Promise<void> {
    let dataToStore = JSON.parse(JSON.stringify(data));
    return this.getDocument<T>(path).update(dataToStore);
  }

  private storeDocument(
    collection: AngularFirestoreCollection,
    data: any,
    id?: string
  ): [Promise<void>, string] {

    const timestamp = new Timestamp(new Date());
    const idToStore = id ? id : this.fs.createId();
    data.id = idToStore;
    data.lastUpdated = { ...timestamp };

    let ref = collection.doc(idToStore);

    let dataToStore = JSON.parse(JSON.stringify(data));

    return [ref.set({ ...dataToStore }), idToStore];
  }
}
