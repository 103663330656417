import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';
import { DatabaseService } from '../database/database.service';

import { User } from '@app/models/User';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private auth: AuthService,
    private functions: AngularFireFunctions,
    private database: DatabaseService
  ) { }

  async startCheckout(successRedirect: string, reportId: string, uid: string) {

    this.auth.user$.subscribe(async (user: User) => {

      let options = {
        successRedirect: successRedirect,
        cancelRedirect: `${window.location.protocol}//${window.location.href.split('/')[2]}/report`,
        metadata: {
          uid: uid,
          orderId: reportId
        },
        discounts: []
      };

      this.database.checkForDiscounts(user.email)
        .then(async (discount: string) => {
          switch (discount) {
            case 'free':
            case 'freereport':
              const fulfillReportOrder = this.functions.httpsCallable<any>("stripeFulfillReportOrder");
              await fulfillReportOrder({
                orderId: reportId,
                uid: uid
              }).toPromise();
              window.location.href = successRedirect;
              return;
            case 'babsonBSU':
              options.discounts = [{ coupon: environment.production ? '4XwhgpsT' : 'ubPcsX1p' }]
            default:
              return this.createChargeSession(options);
          }
        })
    });
  }

  private async createChargeSession(options: any) {
    var stripe = Stripe(environment.stripeAPIKey);
    const createChargeSession = this.functions.httpsCallable('stripeCreateChargeSession');
    return createChargeSession(options).toPromise()
      .then((session) => {
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return;
      });
  }

}
