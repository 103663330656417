import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '@app/models/User';
import { Report } from '@app/models/Report';
import { LayoutProperties } from "../../../services/media-observer/media-observer.service";
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from "@angular/router";
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { fade } from '../../../animations/fade.animation';
import { LoadingService } from "../../../services/loading/loading.service";
import { IntroDialogComponent } from '../intro-dialog/intro-dialog.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'rnt',
  templateUrl: './rnt.component.html',
  styleUrls: ['./rnt.component.less'],
  animations: [fade]
})
export class RNTComponent implements OnInit {
  @Input() user$: Observable<User>;
  @Input() layoutProperties$: Observable<LayoutProperties>;
  @Input() report$: Observable<Report>;
  @Output() exitReportEvent = new EventEmitter<boolean>();
  Of = of;
  adminMode: boolean;
  JSON;

  familiesOrdered = [
    "welcomeLetter",
    "hairProperties",
    "effectsAndTransformations",
    "health",
    "environmentalFactors"
  ]

  families = {
    "welcomeLetter": {
      id: "welcomeLetter",
      order: 1,
      name: "Welcome Letter",
      icon: "flare",
      color: "#E71781",
    },
    "hairProperties": {
      id: "hairProperties",
      order: 2,
      name: "Hair Properties",
      icon: "category",
      color: "#4CB4FC",
      subsections: ["Curl Pattern", "Strand Thickness", "Porosity", "Hair Length", "Hair Density"]
    },
    "effectsAndTransformations": {
      id: "effectsAndTransformations",
      order: 3,
      name: "Effects & Transformations",
      icon: "auto_awesome",
      color: "#B060FF",
      subsections: ["Scalp Health", "Pre-Existing Conditions & Health"]
    },
    "health": {
      id: "health",
      order: 4,
      name: "Health",
      icon: "stream",
      color: "#f54c00",
    },
    "environmentalFactors": {
      id: "environmentalFactors",
      order: 5,
      name: "Environmental Factors",
      icon: "landscape",
      color: "#36D361",
    }
  }

  user: User;
  currentFamily: string = "welcomeLetter";
  currentSection: string;

  constructor(
    private route: ActivatedRoute,
    public analytics: AnalyticsService,
    public loading: LoadingService,
    private dialog: MatDialog,
    private reportService: ReportService
  ) {
    this.JSON = JSON;
    this.route.queryParams.subscribe(params => {
      if (params.adminId === environment.adminId) {
        this.adminMode = true;
      }
    });
  }

  ngOnInit(): void {
    this.user$.subscribe(user => {
      this.user = user;
    });

    this.report$.subscribe(report => {
      report.results.scalpAndHairHealth = report.results.scalpAndHairHealth.filter(symptom => {
        return !report.hiddenKeys.includes(symptom);
      });

      report.results.preExistingConditionsAndHealth = report.results.preExistingConditionsAndHealth.filter(condition => {
        return !report.hiddenKeys.includes(condition);
      });

      report.results.chemicalTransformation = report.results.chemicalTransformation.filter(transformation => {
        return !report.hiddenKeys.includes(transformation);
      });
      if (!report.viewed) {
        this.openIntroDialog();
        this.reportService.updateReportViewedStatus(report.id);
      }
    })
  }

  toggleCurrentFamily(family?: string, direction?: string) {
    const families = Object.keys(this.families);
    if (family) {
      this.currentFamily = family;
    }
    else if (direction) {
      for (let i = 0; i < families.length; i++) {
        if (families[i] === this.currentFamily) {
          let j: number;
          if (direction === "forward") {
            j = i < families.length - 1 ? i + 1 : 0;
          }
          else {
            j = i > 0 ? i - 1 : families.length - 1;
          }
          this.currentFamily = families[j];
          break;
        }
      }
    }
    this.analytics.logEvent('report', 'viewed_report_' + this.currentFamily.toLowerCase());
    window.scrollTo(0, 0);
  }

  public openIntroDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.height = "100%";

    this.dialog.open(IntroDialogComponent, dialogConfig);
  }

  exitReport() {
    this.exitReportEvent.emit(true);
  }

}
