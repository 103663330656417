<navigation-bar></navigation-bar>
<div class="outer-container"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="0px">
  <div class="spacer"></div>

  <ng-container *ngIf="loading.loading$ | async; else pageLoaded">
    <div class="loading-container">
      <app-loading></app-loading>
    </div>
  </ng-container>

  <ng-template #pageLoaded>
    <mat-sidenav-container class="outer-container"
      *ngIf="layoutProperties$ | async as layoutProperties"
      fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
      fxLayoutAlign="space-between start"
      [ngStyle]="{'background-color': backgroundColor }"
      (backdropClick)="close()">

      <mat-sidenav opened
        #leftSidebar
        [mode]="layoutProperties.isMobile ? 'over' : 'side'"
        class="column-1"
        [opened]="displayLeftSidebar">
        <div>
          <div class=""
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="25px">
            <div class="container"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="10px">
              <ng-content select="[leftSidebarHeader]"></ng-content>
            </div>
            <ng-content select="[leftSidebarContent]"></ng-content>
          </div>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="column-2"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="25px"
          #centerContent>
          <div class="content-header"
            fxLayout="row"
            fxLayoutAlign="start space-around"
            fxLayoutGap="15px"
            [ngStyle]="{ 'width': contentHeaderWidth }">
            <ng-content select="[contentHeader]"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="15px"></ng-content>
          </div>
          <div class="full-width">
            <div [ngStyle]="{
              'padding': layoutProperties.innerPadding,
              'margin-top': '4rem',
              'width': 'calc(100% - layoutProperties.innerPadding)'
            }">
              <ng-content select="[centerContent]"
                class="content"></ng-content>
            </div>
          </div>
          <div class="mobile-menu-container"
            [ngStyle]="{'z-index': '1'}"
            *ngIf="layoutProperties.isMobile">
            <mat-toolbar>
              <div fxLayout="row"
                fxLayoutAlign="space-between stretch"
                fxLayoutGap="25px">
                <button mat-fab
                  color="accent"
                  (click)="leftSidebar.open()">
                  <mat-icon>
                    {{ leftToolbarIcon || 'menu' }}
                  </mat-icon>
                </button>
                <button mat-fab
                  color="accent"
                  (click)="rightSidebar.open()">
                  <mat-icon>
                    account_circle
                  </mat-icon>
                </button>
                <ng-content select=[toolbar]>
                </ng-content>
              </div>
            </mat-toolbar>
          </div>
        </div>
      </mat-sidenav-content>

      <mat-sidenav opened
        #rightSidebar
        [mode]="layoutProperties.isMobile ? 'over' : 'side'"
        position="end"
        class="column-3"
        [opened]="displayRightSidebar">
        <ng-content select="[rightSidebarContent]"></ng-content>
      </mat-sidenav>

    </mat-sidenav-container>
  </ng-template>
</div>