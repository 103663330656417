<div class="outer-container {{ backgroundColor }} full-width"
  [ngStyle]="{
    'padding': padding,
    'padding-bottom': paddingBottom,
    'padding-top': paddingTop,
    'padding-left': paddingLeft,
    'padding-right': paddingRight
  }"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="0px">
  <ng-container *ngIf="loading.loading$ | async; else sectionLoaded">
    <div class="loading-container">
      <app-loading></app-loading>
    </div>
  </ng-container>

  <ng-template #sectionLoaded>
    <div class="content-container"
      [ngStyle]="{
        'width': '100%',
        'max-width': maxWidth
      }"
      [@fade]="!loading.loading$">
      <ng-content #sectionToDisplay></ng-content>
    </div>
  </ng-template>
</div>