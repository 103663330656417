import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable } from 'rxjs';
import { QuizField } from "@app/models/QuizField";
import { MediaObserverService, LayoutProperties } from '../../services/media-observer/media-observer.service';

@Component({
  selector: "app-radio-input",
  templateUrl: "./radio-input.component.html",
  styleUrls: ["./radio-input.component.less"]
})
export class RadioInputComponent implements OnInit {
  @Input() currentField: QuizField;
  @Input() checked: boolean = false;
  @Input() isMobile: boolean;
  @Output() check = new EventEmitter<string>();
  layoutProperties$: Observable<LayoutProperties>;

  constructor(
    private layoutObserver: MediaObserverService
  ) { }

  ngOnInit(): void {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  public onCheck(value: string): void {
    this.check.emit(value);
  }
}
