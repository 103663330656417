export class Timestamp {
  timestamp: number;
  tz: number;
  timestampReadable: string;

  constructor(timeParameter?: Date) {
    let time = timeParameter || new Date();
    this.timestamp = time.getTime();
    this.tz = time.getTimezoneOffset();
    this.timestampReadable = time.toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
  }
}
