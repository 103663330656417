<page-wrapper *ngIf="layoutProperties$ | async as layoutProperties"
  [displayNavigation]="true">
  <ng-container *ngIf="loading.loading$ | async; else pageLoaded">
    <div class="loading-container">
      <app-loading></app-loading>
    </div>
  </ng-container>
  <ng-template #pageLoaded>
    <page-section class="full-width"
      [padding]="'0'"
      [paddingBottom]="layoutProperties.outerPadding.split(' ')[0]">
      <div class="page-grid"
        fxLayout="row"
        fxLayoutAlign="stretch stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}"
        *ngIf="auth.user$ | async as user"
        [@fade]="!loading.loading$">
        <div fxFlex="100%"
          class="body-column"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="25px">

          <div class='title-container page-title'
            fxLayout="column"
            fxLayoutAlign="{{ layoutProperties.isMobile ? 'start center ' : 'start start' }}"
            fxLayoutGap="25px">
            <span class="page-title"
              [ngStyle]="{'text-align': layoutProperties.isMobile ? 'center' : 'left' }">Welcome to Curl Cupid™</span>
            <!-- <div class="divider-bar"
              [ngClass]="{'left': !layoutProperties.isMobile }"></div> -->
          </div>

          <div class="content-container"
            fxLayout="row"
            fxLayoutAlign="stretch stretch"
            fxLayoutGap="{{ layoutProperties.spacing }}"
            [ngStyle]="{'width': '100%'}">
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="{{ layoutProperties.spacing }}"
              [ngStyle]="{
                'margin-top': layoutProperties.isMobile ? layoutProperties.spacing : '',
                'width': '100%'
            }">
              <div class="grid-container"
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap=" {{ layoutProperties.spacing }}">

                <mat-grid-list class="grid-inner-container"
                  cols="8"
                  rowHeight="{{ nextStepsRowHeight }}"
                  gutterSize="{{ layoutProperties.spacing }}">

                  <mat-grid-tile class="hoverable"
                    [colspan]="layoutProperties.isMobile ? '4' : '2'"
                    [rowspan]="1"
                    *ngFor="let step of nextStepsLinks">
                    <div class="card {{ step.id }}-container raised"
                      [ngClass]="{ 'mobile': layoutProperties.isMobile }"
                      fxLayout="column"
                      fxLayoutAlign="center start"
                      fxLayoutGap="25px"
                      matRipple
                      (click)="analytics.logViewedPageEvent('home', step.id)"
                      routerLink="{{ step.route }}"
                      routerLinkActive="active">
                      <div class="grid-inner-container"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxLayoutGap="25px">
                        <div fxLayout="column"
                          fxLayoutAlign="center center"
                          fxLayoutGap="15px">
                          <mat-icon>
                            {{ step.icon }}
                          </mat-icon>
                          <h2 class="title"
                            [ngClass]="{ 'mobile': layoutProperties.isMobile }">{{ step.title }}</h2>
                          <p class="subtitle"
                            *ngIf="!layoutProperties.isMobile"
                            [ngClass]="{ 'mobile': layoutProperties.isMobile }">{{ step.subtitle }}</p>
                        </div>
                      </div>
                    </div>
                  </mat-grid-tile>

                  <mat-grid-tile class="hoverable"
                    [colspan]="layoutProperties.isMobile ? '4' : '2'"
                    [rowspan]="1">
                    <div class="card feedback-container"
                      [ngClass]="{ 'mobile': layoutProperties.isMobile }"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="25px">
                      <div class="grid-inner-container"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxLayoutGap="15px"
                        [ngStyle]="{'width': '100%', 'height': '100%'}"
                        (click)="openLink('https://airtable.com/shrnsXF5nJ0mfppfB', 'feedback')">
                        <div fxLayout="column"
                          fxLayoutAlign="center center"
                          fxLayoutGap="15px">
                          <mat-icon>comment</mat-icon>
                          <!-- <span class="step">Leave a comment</span> -->
                          <h2 class="title"
                            [ngClass]="{ 'mobile': layoutProperties.isMobile }">4. Talk to Us</h2>
                          <p class="subtitle mat-small"
                            *ngIf="!layoutProperties.isMobile"> Request a feature, leave a suggestion, or just say hi!</p>
                        </div>
                      </div>
                    </div>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="layoutProperties.isMobile ? '8' : '3'"
                    [rowspan]="layoutProperties.isMobile ? '3' : '2'">
                    <div class="gray card raised"
                      [ngClass]="{ 'mobile': layoutProperties.isMobile }"
                      fxLayout="column"
                      fxLayoutAlign="start start"
                      fxLayoutGap="25px">
                      <div class="grid-inner-container"
                        fxLayout="row"
                        fxLayoutAlign="center start"
                        fxLayoutGap="25px">
                        <div class="avatar-container mobile"
                          fxLayout="column"
                          fxLayoutAlign="start start"
                          fxLayoutGap="15px">
                          <div fxLayout="column"
                            fxLayoutAlign="start start"
                            fxLayoutGap="15px"
                            class="avatar-container"
                            fxFlex="0 0 auto">
                            <div fxLayout="row"
                              fxLayoutAlign="start center"
                              fxLayoutGap="15px">
                              <ng-container *ngIf="user; else noPhoto">
                                <img class="avatar"
                                  src="{{ user.photoURL }}" />
                              </ng-container>
                              <ng-template #noPhoto>
                                <img class="avatar"
                                  src="../../../assets/images/misc/avatar.jpg">
                              </ng-template>
                              <span class="name">{{ user.displayName }}</span>
                            </div>
                            <ng-container *ngIf="report; else noReport">
                              <div fxLayout="column"
                                fxLayoutAlign="start start"
                                fxLayoutGap="5px">
                                <span [ngStyle]="{'font-size': '0.8rem'}"><b>MY CURL ATTRIBUTES</b></span>
                                <mat-divider [ngStyle]="{'color': 'black'}"></mat-divider>
                                <div fxLayout="row"
                                  fxLayoutAlign="start start"
                                  fxLayoutGap="15px">
                                  <mat-chip-list>
                                    <mat-chip class="attribute"
                                      *ngIf="report && report.results.currentState"
                                      color="primary">{{ report.results.currentState.split(': ')[1] }}</mat-chip>
                                    <mat-chip class="attribute"
                                      *ngIf="report && report.results.curlPattern">{{ report.results.curlPattern }}</mat-chip>
                                    <mat-chip class="attribute"
                                      *ngIf="report && report.results.porosity">{{ report.results.porosity.split(': ')[1] + ' Porosity' }}</mat-chip>
                                    <mat-chip class="attribute"
                                      *ngIf="report && report.results.hairDensity && !layoutProperties.isMobile">{{ report.results.hairDensity.split(': ')[1] + ' Density' }}</mat-chip>
                                    <mat-chip class="attribute"
                                      *ngIf="report && report.results.hairLength && !layoutProperties.isMobile">{{ report.results.hairLength.split(': ')[1] + ' Hair Length' }}</mat-chip>
                                  </mat-chip-list>
                                </div>
                              </div>
                              <div fxLayout="column"
                                fxLayoutAlign="start start"
                                fxLayoutGap="5px">
                                <span [ngStyle]="{'font-size': '0.8rem'}"><b>HELPFUL RESOURCES</b></span>
                                <mat-divider [ngStyle]="{'color': 'black'}"></mat-divider>
                                <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
                                  fxLayoutAlign="start start"
                                  fxLayoutGap="10px">
                                  <button mat-button
                                    class="button"
                                    (click)="openLink('https://instagram.com/glimmer411', 'instagram')">
                                    <div fxLayout="row"
                                      fxLayoutAlign="start center"
                                      fxLayoutGap="10px">
                                      <mat-icon svgIcon="instagram"></mat-icon>
                                      <span [ngStyle]="{'font-size': '0.8rem'}">Inspo</span>
                                    </div>
                                  </button>
                                  <button mat-button
                                    class="button"
                                    (click)="openLink('https://www.glimmer.info/blog', 'blog')">
                                    <div fxLayout="row"
                                      fxLayoutAlign="start center"
                                      fxLayoutGap="10px">
                                      <mat-icon [ngStyle]="{'color': '#FF8000'}">bookmark</mat-icon>
                                      <span [ngStyle]="{'font-size': '0.8rem'}">The Curl 411 Blog</span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #noReport>
                              <div [ngStyle]="{'text-align': 'left'}">
                                <span class="mat-small">Once you take the Quiz create a Report, your personal hair attributes will appear here.</span>
                                <button mat-flat-button
                                  class="button"
                                  color="accent"
                                  routerLink="/quiz"
                                  routerLinkActive="active">Take the Quiz</button>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-grid-tile>

                  <mat-grid-tile [colspan]="layoutProperties.isMobile ? '8' : '5'"
                    [rowspan]="layoutProperties.isMobile ? '6' : '2'">
                    <div class="gray card raised"
                      [ngStyle]="{'padding': '0'}"
                      [ngClass]="{ 'mobile': layoutProperties.isMobile }"
                      fxLayout="column"
                      fxLayoutAlign="start start"
                      fxLayoutGap="25px">
                      <div class="grid-inner-container"
                        fxLayout="row"
                        fxLayoutAlign="center start"
                        fxLayoutGap="25px">
                        <div class="blog-article-container"
                          fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
                          fxLayout.md="column"
                          fxLayoutAlign="start stretch"
                          fxLayoutGap="25px"
                          [ngStyle]="{'height': '100%', 'width': '100%'}"
                          fxFlex="2 2 100%">
                          <ng-container *ngIf="blogs$ | async as blogs; else noBlog">
                            <div class="full-height"
                              [@fade]="blogs$ | async"
                              fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
                              fxLayout.md="row"
                              fxLayoutAlign="start stretch"
                              fxLayoutGap="25px">
                              <div class="blog-image"
                                fxFlex="1 1 33%"
                                [ngStyle]="{
                                  'background-image': 'url(' + blogs[0].image[0].url + ')'
                              }">
                              </div>
                              <div fxLayout="column"
                                fxLayoutAlign="{{ layoutProperties.isMobile ? 'start start' : 'center start' }}"
                                fxLayoutGap="15px"
                                class="blog-text-container"
                                fxFlex="1 2 auto">
                                <span class="mat-small"><b>THE CURL 411 BLOG</b></span>
                                <h3 class="title">{{ blogs[0].title }}</h3>
                                <p class="description">{{ blogs[0].description }}</p>
                                <button mat-flat-button
                                  class="button"
                                  color="accent"
                                  (click)="openLink(blogs[0].link, 'blog_' + blogs[0].id)">Read More</button>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #noBlog>
                            <div [ngStyle]="{'width': '100%'}">
                              <app-loading></app-loading>
                            </div>
                          </ng-template>

                        </div>
                      </div>
                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
            </div>

          </div>
        </div>
      </div>
    </page-section>

  </ng-template>
</page-wrapper>