import { Component, OnInit, Input } from "@angular/core";
import { Observable } from 'rxjs';
import { AuthService } from "../../services/auth/auth.service";
import { ReportService } from "../../services/report/report.service";
import { MediaObserverService, LayoutProperties } from "../../services/media-observer/media-observer.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogComponent } from '../../components/dialog/dialog/dialog.component';
import { ProfileDialogComponent } from "../../components/dialog/profile-dialog/profile-dialog.component";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from '../../../../functions/src/models/User';
import { Report } from '../../../../functions/src/models/Report';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: "navigation-bar",
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.less"]
})
export class NavigationBarComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;
  report$: Observable<Report>;
  user: User;

  routes: Object = {
    "/home": {
      label: "Home",
      route: "home",
      title: "Home",
      icon: "home"
    },
    "/quiz": {
      label: "Quiz",
      route: "quiz",
      title: "Evaluate Your Hair",
      icon: "equalizer"
    },
    "/report": {
      label: "Report",
      route: "report",
      title: "Review Your Report",
      icon: "assignment"
    },
    // "/products": {
    //   label: "Products",
    //   route: "products",
    //   title: "Review Your Products",
    //   icon: "shopping_cart"
    // }
  };

  headerLinks: Object[] = [
    {
      route: "/home",
      feature: 'home',
      label: this.routes["/home"].label,
      title: this.routes["/home"].title,
      icon: this.routes["/home"].icon
    },
    {
      route: "/quiz",
      feature: 'quiz',
      label: this.routes["/quiz"].label,
      title: this.routes["/quiz"].title,
      icon: this.routes["/quiz"].icon
    },
    {
      route: "/report",
      feature: 'report',
      label: this.routes["/report"].label,
      title: this.routes["/report"].title,
      icon: this.routes["/report"].icon
    },
    // {
    //   route: "/products",
    //   feature: 'products',
    //   label: this.routes["/products"].label,
    //   title: this.routes["/products"].title,
    //   icon: this.routes["/products"].icon
    // }
  ];

  logInForm = this.formBuilder.group({
    email: ['', [
      Validators.email,
      Validators.required]
    ],
    pwd: ['', [
      Validators.required,
      Validators.minLength(8)],
    ]
  })

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    public layoutObserver: MediaObserverService,
    public router: Router,
    private route: ActivatedRoute,
    private reportService: ReportService,
    public analytics: AnalyticsService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.report$ = this.reportService.report$;
    this.auth.user$.subscribe(user => this.user = user);
  }

  public signIn() { }

  public signOut() {
    this.auth.signOut().then(_ => {
      this.router.navigate(["/welcome"]);
    });
  }

  signUpWithGoogle(): void {
    this.auth.googleSignUp()
      .then(_ => {
        this.logInSuccessful();
      })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  logInSuccessful() {
    const intendedRoute = this.route.snapshot.queryParams.destination;
    this.router.navigate([intendedRoute || '/']);
  }

  logInUnsuccessful(error: { code: string, message: string }) {
    this.handleError(error);
  }

  logInWithGoogle(): void {
    this.auth.googleLogIn()
      .then(_ => {
        this.logInSuccessful();
      })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  logInWithFacebook(): void {
    this.auth.facebookLogIn()
      .then(_ => {
        this.logInSuccessful();
      })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  logInWithEmail(email: string, pwd: string): void {
    this.auth.emailLogIn(email, pwd)
      .then(_ => {
        this.logInSuccessful();
      })
      .catch(error => {
        this.logInUnsuccessful(error);
      })
  }

  public onOpenProfile() {
    this.openDialog("profile", {});
  }

  private handleError(error: { code: string, message: string }) {
    this.openDialog("error", error);
  }

  private openDialog(type: string, data: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = { ...data };
    dialogConfig.data.type = type;

    switch (type) {
      case "error":
        dialogConfig.disableClose = false;
        dialogConfig.data["title"] = "Oops! An error occured.";
        if (data.code === "auth/popup-closed-by-user") return;
        this.dialog.open(DialogComponent, dialogConfig);
        break;
      case "profile":
        dialogConfig.disableClose = false;
        dialogConfig.panelClass = "no-padding";
        this.dialog.open(ProfileDialogComponent, dialogConfig);
        break;
      default:
        break;
    }
  }

  openLink(url: string) {
    window.open(url);
  }
}
