import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Product } from '../../../../functions/src/models/Product';
import { ProductKit } from '../../../../functions/src/models/ProductKit';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private analytics: AngularFireAnalytics
  ) { }

  public logEvent(origin: string, event: string) {
    this.analytics.logEvent(event, {
      origin: origin,
    });
  }

  public logLoggedInEvent(method: string) {
    this.analytics.logEvent('logged_in', {
      origin: 'login',
      login_method: method
    });
  }

  public logLoggedOutEvent() {
    this.analytics.logEvent('logged_out');
  }

  public logCreatedAccountEvent(method: string) {
    this.analytics.logEvent('created_account', {
      origin: 'login',
      login_method: method
    });
  }

  public logClickedLinkEvent(origin: string, destination: string) {
    this.analytics.logEvent("clicked_link", {
      origin: origin,
      destination: destination
    });
  }

  public logClickedButtonEvent(origin: string, button_id: string) {
    this.analytics.logEvent("clicked_btn", {
      origin: origin,
      button_id: button_id
    });
  }

  public logViewedPageEvent(origin: string, destination: string) {
    this.analytics.logEvent("viewed_page", {
      origin: origin,
      destination: destination
    });
  }

  public logFilteredProductEvent(filter: string, filterBy: string) {
    this.analytics.logEvent("filtered_products", {
      origin: "product",
      product_filter: filter,
      product_filter_type: filterBy
    });
  }

  public logViewedProductEvent(product: Product) {
    this.analytics.logEvent("viewed_product", {
      origin: "product",
      product_id: product.id,
      product_brand: product.brand,
      product_type: product.productType,
      product_is_black_owned: product.isBlackOwned
    });
  }

  public logClickedProductButtonEvent(product: Product) {
    this.analytics.logEvent("clicked_btn", {
      origin: "product",
      product_id: product.id,
      product_brand: product.brand,
      product_type: product.productType,
      product_is_black_owned: product.isBlackOwned
    });
  }

  public logCopyProductLinkButtonEvent(product: Product) {
    this.analytics.logEvent("copied_link", {
      origin: "product",
      product_id: product.id,
      product_brand: product.brand,
      product_type: product.productType,
      product_is_black_owned: product.isBlackOwned
    });
  }

  public logViewedProductKitEvent(kit: ProductKit) {
    this.analytics.logEvent("viewed_product_kit", {
      origin: "product_kit",
      kit_type: kit.type
    });
  }

  public setUserProperties(properties: any) {
    this.analytics.setUserProperties(properties);
  }
}
