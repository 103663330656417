import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();
  loadingMessage: string;

  constructor() { }

  showLoaderUntilComplete<T>(obs$: Observable<T>): Observable<T> {
    return undefined;
  }

  loadingOn(loadingMessage?: string) {
    this.loadingMessage = loadingMessage;
    this.loadingSubject.next(true);
  }

  loadingOff() {
    this.loadingSubject.next(false);
  }


}
