import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Report } from '@app/models/Report';
import { LayoutProperties } from "../../../../services/media-observer/media-observer.service";

@Component({
  selector: 'welcome-letter',
  templateUrl: './welcome-letter.component.html',
  styleUrls: ['./welcome-letter.component.less']
})
export class WelcomeLetterComponent implements OnInit {
  @Input() report: Report;
  @Input() gradient: string;
  @Input() layoutProperties$: Observable<LayoutProperties>;

  constructor() { }

  ngOnInit(): void {
  }

}
