import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { AnalyticsService } from '../analytics/analytics.service';

import { Observable, throwError } from "rxjs";
import { User } from "@app/models/User";
import { Timestamp } from "@app/models/Timestamp";
import { DatabaseService } from "../database/database.service";
import { FirestoreService } from "../firestore/firestore.service";

@Injectable({
  providedIn: "root"
})

/*
  The purpose of this service is to provide an authorization wall to allow only authorized users into the Curl Cupid system.
 */
export class AuthService {
  // retrievable user Observable for current user
  user$: Observable<User>;
  user: User;

  constructor(
    private auth: AngularFireAuth,
    private fs: FirestoreService,
    private db: DatabaseService,
    private analytics: AnalyticsService
  ) {

    this.user$ = this.fs.user$; // user observable will automatically update with new values if user login status changes
    this.fs.user$.subscribe(user => {
      this.user = new User().parseUser(user);
    })
  }

  async getUser(): Promise<User> {
    return this.user$.toPromise();
  }

  // sign in with Google
  async googleLogIn(): Promise<any> {
    // sign in provider
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    // credential returned from sign in provider
    this.auth.signInWithRedirect(provider)
      .then(() => {
        return this.auth.onAuthStateChanged(user => {
          return this.createUser(user);
        })
      })
      .then(_ => {
        this.analytics.logLoggedInEvent('google');
        return;
      })
      .catch(error => {
        this.auth.signOut();
        return this.throwError(error).toPromise();
      });
  }

  // sign in with Google
  async googleSignUp(): Promise<any> {
    // sign in provider
    const provider = new firebase.auth.GoogleAuthProvider();
    // credential returned from sign in provider
    this.auth.signInWithRedirect(provider);
    return this.auth.onAuthStateChanged(user => {
      return this.createUser(user);
    })
      .then(_ => {
        this.analytics.logCreatedAccountEvent('google');
        return;
      })
      .catch(error => {
        this.auth.signOut();
        return this.throwError(error).toPromise();
      });
  }

  // sign in with Facebook
  async facebookLogIn() {
    // sign in provider
    const provider = new firebase.auth.FacebookAuthProvider();
    // credential returned from sign in provider
    return this.auth
      .signInWithPopup(provider)
      .then(credential => {
        this.analytics.logLoggedInEvent('facebook');
        return this.updateUser(credential.user);
      })
      .catch(error => {
        return this.throwError(error).toPromise();
      });
  }

  // sign in with email
  async emailLogIn(email: string, pwd: string) {
    // credential returned from sign in
    return this.auth
      .signInWithEmailAndPassword(email, pwd)
      .then(credential => {
        this.analytics.logLoggedInEvent('email');
        return this.updateUser(credential.user);
      })
      .catch(error => {
        return this.throwError(error).toPromise();
      });
  }

  async createUserWithEmailAndPassword({
    email,
    pwd,
    firstName,
    lastName,
    phoneNumber
  }) {
    const displayName = "".concat(firstName, " ", lastName);
    // destination route before redirected to login)
    return this.auth
      .createUserWithEmailAndPassword(email, pwd)
      .then(credential => {
        let user = {
          uid: "",
          photoURL: "",
          email,
          pwd,
          displayName,
          phoneNumber
        };
        user.uid = credential.user.uid;
        user.photoURL = credential.user.photoURL;
        this.analytics.logCreatedAccountEvent('email');
        return this.createUser(user);
      })
      .catch(error => {
        this.auth.signOut();
        return this.throwError(error).toPromise();
      });
  }

  async sendPasswordResetEmail(email: string) {
    this.auth
      .sendPasswordResetEmail(email)
      .then(_ => {
        this.analytics.logEvent('login', 'requested_password_reset');
        return;
      })
      .catch(error => {
        this.auth.signOut();
        return this.throwError(error).toPromise();
      });
  }

  // sign out
  signOut(): Promise<void> {
    this.analytics.logLoggedOutEvent();
    return this.auth.signOut();
  }

  // update firebase user reference
  private updateUser({
    uid,
    email,
    phoneNumber,
    displayName,
    photoURL
  }): Promise<void> {

    const firstName = displayName.split(' ')[0];
    const lastName = displayName.split(' ')[1];

    const timestamp = new Timestamp(new Date());
    const lastLogin = { ...timestamp };

    // data to update
    let data = new User().parseUser({
      uid,
      email,
      phoneNumber,
      displayName,
      firstName,
      lastName,
      photoURL
    });

    data[`lastLogin.${timestamp.timestamp}`] = lastLogin;

    // update firestore document
    return this.db.updateUser(uid, data);
  }

  // update firebase user reference
  private createUser({
    uid,
    email,
    phoneNumber,
    displayName,
    photoURL
  }): Promise<void> {
    const timestamp = new Timestamp(new Date());
    const lastLogin = { ...timestamp };
    const createdDate = lastLogin;
    const curlCupidReports = [];
    const firstName = displayName.split(' ')[0];
    const lastName = displayName.split(' ')[1];

    // data to update
    const data = new User().parseUser({
      uid,
      email,
      displayName,
      firstName,
      lastName,
      photoURL,
      phoneNumber,
      curlCupidReports,
      lastLogin,
      createdDate
    });

    // update firestore document
    return this.db.createUser(data);
  }

  throwError(error: { code: string; message: string }): Observable<never> {
    console.error(error);
    return throwError(error);
  }
}
