import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LpComponent } from './pages/lp/lp.component';
import { TemporarySplashComponent } from './pages/temporary-splash/temporary-splash.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { ReportComponent } from './pages/report/report.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductKitsComponent } from './pages/products/product-kits/product-kits.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AuthGuard } from './services/auth-guard/auth.guard';
import { RoutineComponent } from './pages/routine/routine.component';

const routes: Routes = [
  {
    path: "temporarySplash",
    component: TemporarySplashComponent
  },
  {
    path: "welcome",
    component: LpComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "signup",
    component: LoginComponent,
    data: { signup: true }
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { destination: '/home' }
  },
  {
    path: "quiz",
    component: QuizComponent,
    canActivate: [AuthGuard],
    data: { destination: '/quiz' }
  },
  {
    path: "report",
    component: ReportComponent,
    canActivate: [AuthGuard],
    data: { destination: '/report' }
  },
  // {
  //   path: "routine",
  //   component: RoutineComponent,
  //   canActivate: [AuthGuard],
  //   data: { destination: '/routine' }
  // },
  {
    path: "products",
    component: ProductsComponent,
    canActivate: [AuthGuard],
    data: { destination: '/products' }
  },
  {
    path: "products/pantry",
    component: ProductsComponent,
    canActivate: [AuthGuard],
    data: { destination: '/products/pantry' }
  },
  {
    path: "products/kits",
    component: ProductKitsComponent,
    canActivate: [AuthGuard],
    data: { destination: '/products/kits' }
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: { destination: '/admin' }
  },
  {
    path: '**',
    component: LpComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
