<ng-container *ngIf="layoutProperties$ | async as layoutProperties">
  <mat-toolbar class="toolbar"
    *ngIf="displayNavigation"
    fxLayout="row"
    fxLayoutGap="0px"
    [ngStyle]="{
      'padding-left': layoutProperties.outerPadding.split(' ')[1],
      'padding-right': layoutProperties.outerPadding.split(' ')[1]
     }">
    <div class="item-container"
      fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
      fxLayoutAlign="{{ layoutProperties.isMobile ? 'start start' : 'center center' }}"
      fxLayoutGap="{{ layoutProperties.isMobile ? '0': '45px' }}">
      <a href="https://glimmer.info"
        target="_blank"
        (click)="analytics.logClickedLinkEvent('footer', 'landing_page')">WWW.GLIMMER.INFO</a>
      <a href="https://www.glimmer.info/blog"
        target="_blank"
        (click)="analytics.logClickedLinkEvent('footer', 'blog')">THE CURL 411 BLOG</a>
      <a href="https://www.instagram.com/glimmer411"
        target="_blank"
        (click)="analytics.logClickedLinkEvent('footer', 'instagram')">OUR INSTAGRAM</a>
    </div>
  </mat-toolbar>
</ng-container>