export interface ProductInterface {
  id: string;
  name: string;
  brand: string;
  productType: string;
  productSubtype: string[];
  scalpAndHairHealth?: string[];
  productSpecifics?: string[];
  curlPattern?: string[];
  hairLength?: string[];
  porosity?: string[];
  image: {
    url: string
  }[];
  xFree?: string[];
  starRating?: string;
  ingredients?: string[];
  linkToPurchase: string;
  numberOfRatings?: string;
  isBlackOwned?: boolean;
}

export class Product implements ProductInterface {
  id: string;
  name: string;
  brand: string;
  productType: string;
  productSubtype: string[];
  scalpAndHairHealth?: string[];
  productSpecifics?: string[];
  curlPattern?: string[];
  hairLength?: string[];
  porosity?: string[];
  image: {
    url: string
  }[];
  xFree?: string[];
  starRating: string;
  ingredients: string[];
  linkToPurchase: string;
  numberOfRatings: string;
  isBlackOwned?: boolean;

  constructor(product: any) {
    Object.assign(this, product);
  }
}
