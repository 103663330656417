<page-wrapper-three-column [backgroundColor]="'#F7F7F7'"
  class="rnt-container"
  *ngIf="layoutProperties$ | async as layoutProperties">

  <ng-container leftSidebarHeader
    *ngIf="report$ | async as report">
    <div [@fade]='report$'
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="15px">
      <span class="page-title">Report </span>
      <span class='mat-small'><b>{{ report.createdDate.timestamp | date: 'longDate' }}</b></span>
      <span class="mat-small">{{ report.createdDate.timestamp | date:'shortTime' }}</span>
    </div>
  </ng-container>

  <ng-container leftSidebarContent
    *ngIf="report$ | async as report">
    <div fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="25px"
      [@fade]='report$'>
      <div class="family-menu">
        <mat-selection-list [multiple]="false"
          (selectionChange)="toggleCurrentFamily($event.options[0].value)"
          #RNTFamilies>
          <div class="family-menu-option-container"
            *ngFor="let family of familiesOrdered">
            <mat-list-option class="family-menu-option"
              [value]="families[family].id"
              [selected]="currentFamily === family"
              [disableRipple]="true"
              [ngClass]="{
                'selected': currentFamily === family
              }">
              <mat-icon mat-list-icon
                [ngStyle]="{ 'color': families[family].color }">{{ families[family].icon }}</mat-icon>
              {{ families[family].name }}
            </mat-list-option>
          </div>
        </mat-selection-list>
      </div>
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="10px">
        <div class="toolbar"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="25px">
          <div class="navigation-toolbar"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px">
            <button mat-flat-button
              class="toolbar-button button first"
              fxFlex="1 1 50%"
              color="accent"
              (click)="toggleCurrentFamily(null, 'backward')">
              <mat-icon>
                keyboard_arrow_up
              </mat-icon>
            </button>
            <button mat-flat-button
              class="toolbar-button button last"
              fxFlex="1 1 50%"
              color="accent"
              (click)="toggleCurrentFamily(null, 'forward')">
              <mat-icon>
                keyboard_arrow_down
              </mat-icon>
            </button>
          </div>
        </div>
        <button mat-flat-button
          color="accent"
          class="button products-button"
          routerLink="/products"
          [ngStyle]="{'width': '100%'}"
          (click)="analytics.logClickedButtonEvent('report', 'browse_products')"
          routerLinkActive="active">Browse My Products</button>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="10px">
        <a [ngStyle]="{'color': 'black', 'font-size': '0.8rem'}"
          (click)="exitReport()">Exit Report</a>
        <a [ngStyle]="{'color': 'black', 'font-size': '0.8rem'}"
          (click)="openIntroDialog()">Need help?</a>
      </div>
      <!-- <div class="full-width"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="0px">
        <button mat-icon-button
          class="full-width"
          (click)="openIntroDialog()">
          <div fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="10px">
            <mat-icon>
              info
            </mat-icon>
          </div>
        </button>

      </div> -->
    </div>
  </ng-container>

  <ng-container contentHeader
    *ngIf="report$ | async as report"
    class="content-header">
    <div fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
      [@fade]="report$">
      <mat-icon [ngStyle]="{ 'color': families[currentFamily].color }">
        {{ families[currentFamily].icon }}
      </mat-icon>
      <h3 [ngStyle]="{'margin-block-end': 0}">{{ families[currentFamily].name }}</h3>
    </div>
  </ng-container>


  <ng-container centerContent
    *ngIf="report$ | async as report">
    <div class="insights-container"
      [ngSwitch]="currentFamily"
      [@fade]="report$">
      <div *ngSwitchCase="'welcomeLetter'">
        <welcome-letter [report]="report"
          [gradient]="'linear-gradient(125deg, #FD4B36 0%, #FAAF37 100%)'"
          [layoutProperties$]="layoutProperties$"
          [@fade]="currentFamily"></welcome-letter>
      </div>
      <div *ngSwitchCase="'hairProperties'">
        <rnt-hair-properties [report]="report"
          [gradient]="'linear-gradient(125deg, #19B8FF 0%, #95ACF7 100%)'"
          [layoutProperties$]="layoutProperties$"
          [errorMessage]="'We were unable to give you a recommendation based on your answers for this section. '">
        </rnt-hair-properties>
      </div>
      <div *ngSwitchCase="'effectsAndTransformations'">
        <rnt-effects-and-transformations [report]="report"
          [gradient]="'linear-gradient(125deg, #7994EB 0%, #A881E6 100%)'"
          [gradient]="report"
          [layoutProperties$]="layoutProperties$"
          [errorMessage]="'We were unable to give you a recommendation based on your answers for this section. '">
        </rnt-effects-and-transformations>
      </div>
      <div *ngSwitchCase="'health'">
        <rnt-health [report]="report"
          [gradient]="'linear-gradient(125deg, #f54c00 0%, #f58800 100%)'"
          [gradient]="report"
          [layoutProperties$]="layoutProperties$"
          [errorMessage]="'We were unable to give you a recommendation based on your answers for this section. '">
        </rnt-health>
      </div>
      <div *ngSwitchCase="'environmentalFactors'">
        <rnt-environmental-factors [report]="report"
          [gradient]="'linear-gradient(125deg, #36D361 0%, #5cc438 100%)'"
          [gradient]="report"
          [layoutProperties$]="layoutProperties$"
          [errorMessage]="'We were unable to give you a recommendation based on your answers for this section. '">
        </rnt-environmental-factors>
      </div>
      <!-- <div *ngSwitchCase="'routine'">
        <rnt-routine [report]="report"
          [gradient]="'linear-gradient(125deg, #36D361 0%, #98CC44 100%)'"
          [gradient]="report"
          [layoutProperties$]="layoutProperties$"
          [errorMessage]="'We were unable to give you a recommendation based on your answers for this section. '">
        </rnt-routine>
      </div> -->
      <div *ngSwitchDefault>
        {{ currentFamily }}
      </div>
    </div>
  </ng-container>

  <ng-container toolbar
    *ngIf="report$ | async as report">
    <button mat-fab
      class="toolbar-button first"
      fxFlex="1 1 50%"
      color="accent"
      (click)="toggleCurrentFamily(null, 'backward'); analytics.logClickedButtonEvent('report', 'navigate_backward')">
      <mat-icon>
        keyboard_arrow_up
      </mat-icon>
    </button>
    <button mat-fab
      class="toolbar-button last"
      fxFlex="1 1 50%"
      color="accent"
      (click)="toggleCurrentFamily(null, 'forward'); analytics.logClickedButtonEvent('report', 'navigate_forward')">
      <mat-icon>
        keyboard_arrow_down
      </mat-icon>
    </button>
  </ng-container>


  <ng-container rightSidebarContent>
    <div fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="25px"
      *ngIf="report$ | async as report">
      <user-profile [report$]="report$"
        [user$]="user$"
        [layoutProperties$]="layoutProperties$"
        [displayAll]="true"></user-profile>
      <div class="report-test-container"
        *ngIf="adminMode"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <button mat-stroked-button
          class="button"
          [cdkCopyToClipboard]="JSON.stringify(report)"
          (click)="analytics.logClickedButtonEvent('report', 'copy_report_json')">Copy Report JSON</button>
        <button mat-stroked-button
          class="button"
          [cdkCopyToClipboard]="JSON.stringify(report.results)"
          (click)="analytics.logClickedButtonEvent('report', 'copy_results_json')">Copy Results JSON</button>
      </div>
    </div>
  </ng-container>
</page-wrapper-three-column>