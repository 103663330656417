import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../../../functions/src/models/Product';
import { Report } from '../../../../functions/src/models/Report';
import { LoadingService } from '../../services/loading/loading.service';

@Pipe({
  name: 'productFilter'
})
export class PersonalizedProductsPipe implements PipeTransform {
  report$: Observable<Report>;

  constructor(
    private loading: LoadingService,
  ) {

  }

  transform(products: Product[][],
    combinedFiltersString: string, chunk: number): Product[][] {

    if (!products) return;

    const combinedFilters = combinedFiltersString.split(', ').map((filterString: string) => {
      const filterBy = filterString.split(' | ')[0];
      const filter = filterString.split(' | ')[1];
      return {
        filterBy: filterBy,
        filter: filter
      }
    })

    let productsReduced: Product[] = [];
    products.forEach(productRow => {
      productsReduced = productsReduced.concat(productRow)
    });

    this.loading.loadingOn();

    combinedFilters.forEach(filterObject => {
      const filter = filterObject.filter;
      const filterBy = filterObject.filterBy;

      switch (filterBy) {
        case 'type':
          productsReduced = productsReduced.filter((product: Product) => {
            return product.productType.includes(filter);
          });
          break;
        case 'subtype':
          productsReduced = productsReduced.filter((product: Product) => {
            return product.productSubtype.includes(filter);
          });
          break;
        case 'brand':
          productsReduced = productsReduced.filter((product: Product) => {
            return product.brand === filter;
          });
          break;
        case 'porosity':
          productsReduced = productsReduced.filter((product: Product) => {
            if (!product.porosity) return false;
            return product.porosity.includes(filter);
          });
          break;
        case 'symptom':
          productsReduced = productsReduced.filter((product: Product) => {
            if (!product.scalpAndHairHealth) return false;
            return product.scalpAndHairHealth.includes(filter);
          });
          break;
        case 'specifics':
          productsReduced = productsReduced.filter((product: Product) => {
            if (!product.productSpecifics) return false;
            return product.productSpecifics.includes(filter);
          });
          break;
        case 'xFree':
          productsReduced = productsReduced.filter((product: Product) => {
            if (!product.xFree) return false;
            return product.xFree.includes(filter);
          });
          break;
        case 'other':
          productsReduced = productsReduced.filter((product: Product) => {
            if (filter === 'Black-Owned Business') {
              return product.isBlackOwned;
            }
            return product[filter];
          });
          break;
        default:
          break;
      }
    })

    return this.loadProductsToDisplay(productsReduced, chunk);
  }

  private loadProductsToDisplay(products: Product[], chunk: number): Product[][] {
    let i: number, j: number, temparray: Product[][] = [];
    for (i = 0, j = products.length; i < j; i += chunk) {
      temparray.push(products.slice(i, i + chunk));
    }
    this.loading.loadingOff();
    return temparray;
  }

}
