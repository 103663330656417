import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { MediaObserverService, LayoutProperties } from '../../../services/media-observer/media-observer.service'


@Component({
  selector: 'app-signup-dialog',
  templateUrl: './signup-dialog.component.html',
  styleUrls: ['./signup-dialog.component.less']
})
export class SignupDialogComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;
  signUpForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [
      Validators.email,
      Validators.required]
    ],
    phoneNumber: ['', [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]
    ],
    pwd: ['', [
      Validators.required,
      Validators.minLength(8)],
    ]
  });
  hasError: boolean = false;
  errorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private layoutObserver: MediaObserverService) { }

  ngOnInit(): void {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  signUpWithEmail({ email, pwd, firstName, lastName, phoneNumber }): void {
    this.auth.createUserWithEmailAndPassword({ email, pwd, firstName, lastName, phoneNumber })
      .then(_ => {
        this.hasError = false;
        const intendedRoute = this.route.snapshot.queryParams.destination;
        this.router.navigate([intendedRoute || '/home']);
      })
      .catch(error => {
        this.handleError(error);
      })
  }

  onSignUpSubmit(): void {
    const val = this.signUpForm.value;
    this.signUpWithEmail(val);
  }

  private handleError(error: { code: string, message: string }) {
    this.errorMessage = error.message;
    this.hasError = true;
  }


}
