import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, isDevMode } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ProductCatalogItemComponent } from './pages/products/product-catalog-item/product-catalog-item.component';

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG, DEBUG_MODE, APP_NAME, APP_VERSION } from '@angular/fire/analytics';

import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from "@angular/fire/functions";
import { AngularFireAuth, USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/auth';

import { environment } from "../environments/environment";

import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatInputModule } from "@angular/material/input";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { FlexLayoutModule } from "@angular/flex-layout";
import { GridModule } from '@angular/flex-layout/grid';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from "@angular/material/icon";
import { LayoutModule } from "@angular/cdk/layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatChipsModule } from "@angular/material/chips";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator';

import { AppComponent } from "./app.component";
import { QuizComponent } from "./pages/quiz/quiz.component";
import { ReportComponent } from "./pages/report/report.component";
import { LoginComponent } from "./pages/login/login.component";
import { HomeComponent } from "./pages/home/home.component";
import { TestDialogComponent } from "./pages/quiz/test-dialog/test-dialog.component";
import { ErrorDialogComponent } from "./components/dialog/error-dialog/error-dialog.component";
import { PwdResetDialogComponent } from "./components/dialog/pwd-reset-dialog/pwd-reset-dialog.component";
import { ProfileDialogComponent } from "./components/dialog/profile-dialog/profile-dialog.component";
import { LogOutDialogComponent } from "./components/dialog/log-out-dialog/log-out-dialog.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { AdminComponent } from "./pages/admin/admin.component";
import { TestSnackbarComponent } from "./components/test-snackbar/test-snackbar.component";
import { PageWrapperComponent } from "./components/page-wrapper/page-wrapper.component";
import { NavigationBarComponent } from "./components/navigation-bar/navigation-bar.component";
import { LpComponent } from "./pages/lp/lp.component";
import { WarningDialogComponent } from "./components/dialog/warning-dialog/warning-dialog.component";
import { DialogComponent } from "./components/dialog/dialog/dialog.component";
import { RadioInputComponent } from './components/radio-input/radio-input.component';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { SignupDialogComponent } from './components/dialog/signup-dialog/signup-dialog.component';
import { TemporarySplashComponent } from './pages/temporary-splash/temporary-splash.component';
import { RNTComponent } from './pages/report/rnt/rnt.component';
import { UserProfileComponent } from './pages/report/rnt/user-profile/user-profile.component';
import { HairPropertiesComponent } from './pages/report/rnt/hair-properties/hair-properties.component';
import { KeyPointComponent } from './pages/report/rnt/key-point/key-point.component';
import { FamilyCardComponent } from './pages/report/rnt/family-card/family-card.component';
import { WelcomeLetterComponent } from './pages/report/rnt/welcome-letter/welcome-letter.component';
import { EffectsAndTransformationsComponent } from './pages/report/rnt/effects-and-transformations/effects-and-transformations.component';
import { ProductsComponent } from './pages/products/products.component';
import { PageWrapperThreeColumnComponent } from './components/page-wrapper-three-column/page-wrapper-three-column.component';
import { ProductChipComponent } from './pages/products/product-chip/product-chip.component';

import { PersonalizedProductsPipe } from './pipes/personalized-products/personalized-products.pipe';
import { FilterBySubtypePipe } from './pipes/filterBySubtype/filter-by-subtype.pipe';
import { TakeTheQuizComponent } from './components/take-the-quiz/take-the-quiz.component';
import { HealthComponent } from './pages/report/rnt/health/health.component';
import { RoutineComponent } from './pages/routine/routine.component';
import { EnvironmentalFactorsComponent } from './pages/report/rnt/environmental-factors/environmental-factors.component';
import { ProductComponent } from './pages/products/product/product.component';
import { ProductListingComponent } from './pages/products/product-listing/product-listing.component';
import { FooterComponent } from './components/footer/footer.component';
import { IntroDialogComponent } from './pages/report/intro-dialog/intro-dialog.component';
import { PageSectionComponent } from './components/layout/page-section/page-section.component';
import { ProductKitsComponent } from './pages/products/product-kits/product-kits.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CarouselComponent } from './components/carousel/carousel.component';

@NgModule({
  declarations: [
    AppComponent,
    QuizComponent,
    ReportComponent,
    LoginComponent,
    HomeComponent,
    TestDialogComponent,
    ErrorDialogComponent,
    PwdResetDialogComponent,
    ProfileDialogComponent,
    LogOutDialogComponent,
    LoadingComponent,
    AdminComponent,
    TestSnackbarComponent,
    PageWrapperComponent,
    NavigationBarComponent,
    LpComponent,
    WarningDialogComponent,
    DialogComponent,
    RadioInputComponent,
    CheckboxInputComponent,
    SignupDialogComponent,
    TemporarySplashComponent,
    RNTComponent,
    UserProfileComponent,
    HairPropertiesComponent,
    KeyPointComponent,
    FamilyCardComponent,
    WelcomeLetterComponent,
    EffectsAndTransformationsComponent,
    ProductsComponent,
    PageWrapperThreeColumnComponent,
    ProductChipComponent,
    PersonalizedProductsPipe,
    FilterBySubtypePipe,
    TakeTheQuizComponent,
    HealthComponent,
    EnvironmentalFactorsComponent,
    ProductComponent,
    ProductCatalogItemComponent,
    RoutineComponent,
    ProductListingComponent,
    FooterComponent,
    IntroDialogComponent,
    PageSectionComponent,
    ProductKitsComponent,
    CalendarComponent,
    CarouselComponent,
  ],
  entryComponents: [
    TestSnackbarComponent,
    ProfileDialogComponent,
    LogOutDialogComponent,
    ErrorDialogComponent,
    PwdResetDialogComponent,
    SignupDialogComponent,
    DialogComponent,
    IntroDialogComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatSidenavModule,
    MatDividerModule,
    MatInputModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    GridModule,
    LayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatChipsModule,
    MatGridListModule,
    MatDialogModule,
    AngularFireFunctionsModule,
    MatSelectModule,
    MatMenuModule,
    ClipboardModule,
    Ng2SearchPipeModule,
    VirtualScrollerModule,
    MatTooltipModule,
    ScrollingModule,
    MatTabsModule,
    MatRippleModule,
    MatStepperModule,
    ScrollToModule.forRoot(),
    CarouselModule,
    MatTableModule,
    MatPaginatorModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ["localhost", 2025] : undefined
    },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators ? ["localhost", 9099] : undefined
    },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: CONFIG, useValue: {
        send_page_view: false,
        allow_ad_personalization_signals: false,
        anonymize_ip: true
      }
    },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: DEBUG_MODE, useValue: environment.debugMode },
    { provide: APP_NAME, useValue: environment.version }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
