import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { map } from 'rxjs/operators';
import { BlogArticle } from '@app/models/BlogArticle';

import { User } from "@app/models/User";
import { QuizFamily } from "@app/models/QuizFamily";
import { QuizField } from "@app/models/QuizField";
import { Report } from "@app/models/Report";
import { Product } from "@app/models/Product";
import { ProductKit } from "@app/models/ProductKit";

import { AirtableService } from "../airtable/airtable.service";
import { FirestoreService } from "../firestore/firestore.service";
import { AuthService } from "../auth/auth.service";
import { Routine } from "@app/models/Routine";
import { Journal, JournalEntry } from "@app/models/Journal";


@Injectable({
  providedIn: "root"
})
export class DatabaseService {
  user$: Observable<User>;

  public quizFields: {
    [id: string]: QuizField;
  };

  public quizFamilies: {
    [id: string]: QuizFamily;
  };

  public blogArticles: BlogArticle[];

  public quizAnswerKey: {
    quizAnswer: string;
    recommendationTerm: string;
    section: string;
  };

  constructor(
    private auth: AuthService,
    private at: AirtableService,
    private fs: FirestoreService
  ) {
    this.user$ = this.fs.user$; // user observable will automatically update with new values if user login status changes
  }

  /* INIT */

  public async init() {

    this.fs.init(); // init firestore db

    // await this.at
    //   .init() // init airtable db
    //   .then(data => {
    //     this.quizFields = data[0]
    //       .map((record: any) => {
    //         return new QuizField(record.fields);
    //       })
    //       .reduce((recordsToReturn: any, record: any) => {
    //         recordsToReturn[record.id] = record;
    //         return recordsToReturn;
    //       }, {});
    //
    //     this.quizFamilies = data[1]
    //       .map((record: any) => {
    //         return new QuizFamily(record.fields);
    //       })
    //       .reduce((recordsToReturn: any, record: any) => {
    //         recordsToReturn[record.id] = record;
    //         return recordsToReturn;
    //       }, {});
    //
    //     this.quizAnswerKey = data[3]
    //       .map((record: any) => {
    //         return record.fields;
    //       })
    //       .reduce((recordsToReturn: any, record: any) => {
    //         recordsToReturn[record.quizAnswer] = record;
    //         return recordsToReturn;
    //       }, {});
    //
    //     this.blogArticles = data[4].map((record: any) => {
    //       return new BlogArticle(record.fields);
    //     });
    //
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  }

  /* QUIZ */

  public async getQuizFields(): Promise<{
    [id: string]: QuizField;
  }> {
    if (!this.quizFields) {
      return this.at.getQuizFieldsTable().then(fields => {
        this.quizFields = fields;
        return fields;
      });
    }
    return this.quizFields;
  }

  public async getQuizFamilies(): Promise<{
    [id: string]: QuizFamily;
  }> {
    if (!this.quizFamilies) {
      return this.at.getQuizFamiliesTable().then(families => {
        this.quizFamilies = families;
        return families;
      });
    }
    return this.quizFamilies;
  }

  public async getQuizAnswerKey(): Promise<{
    quizAnswer: string;
    recommendationTerm: string;
    section: string;
  }> {
    if (!this.quizAnswerKey) {
      return this.at.getQuizAnswerKeyTable().then(keys => {
        this.quizAnswerKey = keys;
        return keys;
      });
    }
    return this.quizAnswerKey;
  }

  /* USERS */
  public getUsersByParameter(params: any): Observable<User[]> {
    return this.fs.getUsersByParameter(params);
  }

  public createUser(user: User): Promise<void> {
    return this.fs.createUser(user);
  }

  public updateUser(uid: string, data: any): Promise<void> {
    return this.fs.updateUser(uid, data);
  }

  public refreshUserDataInAirtable(): Promise<void> {
    return this.at.refreshUserDataInAirtable().toPromise();
  }

  /* REPORT */

  public getReport(reportId: string): Observable<Report> {
    return this.fs.getReport(reportId);
  }

  public updateReport(reportId: string, update: any): Promise<void> {
    return this.fs.updateReport(reportId, update);
  }

  public createReport(report: Report, uid?: string): Promise<void> {
    return this.fs.createReport(report, uid);
  }

  public checkForDiscounts(email: string): Promise<string> {
    return this.at.checkForDiscounts(email).toPromise();
  }

  /* PRODUCTS */

  public async getProductsById(ids: string[]): Promise<Product[]> {
    return this.at.getProductsById(ids).then((productRecords: any) => {
      return productRecords.map(product => new Product(product.fields));
    })
  }

  public createProductKit(kit: ProductKit): Promise<void> {
    return this.fs.createProductKit(kit);
  }

  public getProductKits(uid?: string): Observable<ProductKit[]> {
    return this.fs.getProductKits(uid);
  }

  /* JOURNAL */
  public createJournal(journal: Journal): Promise<void> {
    return this.fs.createJournal(journal);
  }

  public getJournal(journalId: string): Observable<Journal> {
    return this.fs.getJournal(journalId);
  }

  public updateJournal(journalId: string, update: any): Promise<void> {
    return this.fs.updateJournal(journalId, update);
  }

  /* ROUTINE */
  public createRoutine(routine: Routine): Promise<void> {
    return this.fs.createRoutine(routine);
  }

  public getRoutine(routineId: string): Observable<Routine> {
    return this.fs.getRoutine(routineId);
  }

  public updateRoutine(routineId: string, update: any): Promise<void> {
    return this.fs.updateRoutine(routineId, update);
  }


    /* Journal Entry */
    public createJournalEntry(journalEntry: JournalEntry): Promise<void> {
      return this.fs.createJournalEntry(journalEntry);
    }

    public getJournalEntry(journalEntryId: string): Observable<JournalEntry> {
      return this.fs.getJournalEntry(journalEntryId);
    }

    public updateJournalEntry(journalEntryId: string, update: any): Promise<void> {
      return this.fs.updateJournalEntry(journalEntryId, update);
    }

  /* MISC */

  public getBlogs(): Observable<BlogArticle[]> {
    return this.at.getBlogs();
  }
}
