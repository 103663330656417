<div class="container"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="25px">
  <div mat-dialog-content
    class="content"
    fxLayout="column"
    fxLayoutAlign="center">
    <h3 mat-dialog-title>Leaving so soon?</h3>
    <p> See you next time!</p>
    <div mat-dialog-actions
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="0px">
      <button mat-button
        fxFlex="1 1 auto"
        class="button"
        mat-dialog-close>Close</button>
      <button mat-button
        fxFlex="1 1 auto"
        class="log-out button"
        (click)="signOut()">Log Out</button>
    </div>
  </div>