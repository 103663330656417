import { Timestamp } from './Timestamp';

export class ProductKit {
  id: string;
  uid: string;
  products: string[];
  type: string;

  constructor(productIds: string[], kitType: string, uid: string) {
    this.products = productIds;
    this.type = kitType;
    this.uid = uid;
  }
}

export interface ProductKitReference {
  id: string;
  createdDate: Timestamp;
}
