import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";
import { map } from 'rxjs/operators';
import { BlogArticle } from '@app/models/BlogArticle';
import { User } from '@app/models/User';
import { QuizField } from "@app/models/QuizField";
import { QuizFamily } from "@app/models/QuizFamily";
import { AngularFireFunctions } from "@angular/fire/functions";

@Injectable({
  providedIn: "root"
})
export class AirtableService {

  constructor(private functions: AngularFireFunctions) { }

  public async getQuizFieldsTable(): Promise<{
    [id: string]: QuizField
  }> {
    let result = this.getTable("FIELDS", "MASTER").toPromise()
      .then(fields => {
        return fields.map((record: any) => {
          return new QuizField(record.fields);
        })
          .reduce((recordsToReturn: any, record: any) => {
            recordsToReturn[record.id] = record;
            return recordsToReturn;
          }, {});
      })
    return result;
  }

  public async getQuizFamiliesTable(): Promise<{
    [id: string]: QuizFamily
  }> {
    let result = this.getTable("FAMILIES", "MASTER").toPromise()
      .then(families => {
        return families.map((record: any) => {
          return new QuizFamily(record.fields);
        })
          .reduce((recordsToReturn: any, record: any) => {
            recordsToReturn[record.id] = record;
            return recordsToReturn;
          }, {});
      })
    return result;
  }

  public async getQuizAnswerKeyTable(): Promise<{
    quizAnswer: string;
    recommendationTerm: string;
    section: string;
  }> {
    let result = this.getTable("ANSWER KEY", "MASTER").toPromise()
      .then(keys => {
        return keys.map((record: any) => {
          return record.fields;
        })
          .reduce((recordsToReturn: any, record: any) => {
            recordsToReturn[record.quizAnswer] = record;
            return recordsToReturn;
          }, {});
      })
    return result;
  }

  public async getProductsById(ids: string[]): Promise<any> {
    const getProductsById = this.functions.httpsCallable<any>(
      "getProductsById"
    );
    const data = { ids: ids };
    return getProductsById(data).toPromise();
  }

  public getBlogs(): Observable<BlogArticle[]> {
    const getBlogs = this.functions.httpsCallable<any>(
      "getBlogs"
    );
    return getBlogs(null).pipe(
      map(blogs => {
        return blogs.map(blog => {
          return new BlogArticle(blog.fields);
        })
      })
    );
  }

  public checkForDiscounts(email: string): Observable<string> {
    const checkForDiscounts = this.functions.httpsCallable<any>("checkForDiscounts");
    return checkForDiscounts({ email: email });
  }

  public refreshUserDataInAirtable(): Observable<void> {
    const refreshUserDataInAirtable = this.functions.httpsCallable<any>("refreshUserDataInAirtable");
    return refreshUserDataInAirtable({});
  }

  private getTable(name: string, view: string): Observable<any> {
    const getTable = this.functions.httpsCallable<any>(
      "getTable"
    );
    let data = { table: { name: name, view: view } };
    return getTable(data);
  }
}
