import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutProperties } from "../../../../services/media-observer/media-observer.service";

@Component({
  selector: 'key-point',
  templateUrl: './key-point.component.html',
  styleUrls: ['./key-point.component.less']
})
export class KeyPointComponent implements OnInit {
  @Input() layoutProperties$: Observable<LayoutProperties>;
  @Input() backgroundColor: string;
  @Input() fullGradient: boolean;
  @Input() raised: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
