import { Injectable } from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class MediaObserverService {
  layoutObserver$: Observable<LayoutProperties>;

  constructor(private layoutObserver: MediaObserver) {
    this.layoutObserver$ = this.layoutObserver.media$.pipe(
      map(result => {
        const layoutSize = result.mqAlias;
        let outerPadding: string;
        let spacing: string;
        const maxWidth = "70%";
        outerPadding = "6rem 6rem";
        const borderRadius = '10px';
        const innerPadding = '25px';

        switch (result.mqAlias) {
          case "xs":
            spacing = "15px";
            outerPadding = "4rem 1.5rem";
            break;
          case "sm":
            spacing = "15px";
            outerPadding = "4rem 4rem";
            break;
          case "md":
            spacing = "15px";
            outerPadding = "5rem 5rem";
            break;
          case "lg":
            spacing = "25px";
            outerPadding = "5rem 6rem";
            break;
          default:
            spacing = "25px";
            outerPadding = "5rem 6rem";
            break;
        }

        const isMobile = layoutSize === "xs" || layoutSize === "sm";

        return {
          layoutSize: layoutSize,
          isMobile: isMobile,
          outerPadding: outerPadding,
          innerPadding: innerPadding,
          spacing: spacing,
          maxWidth: maxWidth,
          borderRadius: borderRadius,
          headerHeight: "4rem",
        };
      })
    );
  }
}

export interface LayoutProperties {
  layoutSize: string;
  isMobile: boolean;
  outerPadding: string;
  innerPadding: string;
  spacing: string;
  maxWidth: string;
  borderRadius: string;
}
