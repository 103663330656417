<div class="container"
  fxLayout="{{ isMobile ? 'column' : 'row' }}"
  fxLayoutGap="0px"
  fxLayoutAlign="start start">

  <div class="left-container"
    fxLayout="column"
    *ngIf="!isMobile"
    fxLayoutGap="32px"
    fxLayoutAlign="center center"
    [ngClass]="{ 'mobile': isMobile }">
    <div fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="50px">
      <div class="value-prop"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <mat-icon>
          insights
        </mat-icon>
        <span>Assess your current hair care needs</span>
      </div>
      <div class="value-prop"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <mat-icon>
          emoji_objects
        </mat-icon>
        <span>Understand your unique curls intimately</span>
      </div>
      <div class="value-prop"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <mat-icon>
          explore
        </mat-icon>
        <span>Begin an all-new kind of hair journey</span>
      </div>
    </div>
  </div>

  <div class="right-container"
    fxLayout="column"
    fxLayoutGap="100px"
    fxLayoutAlign="center center"
    [ngStyle]="{'padding': outerPadding}"
    [ngStyle]="{'padding': outerPadding}"
    [ngClass]="{ 'mobile': isMobile }">
    <div class="inner-container"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="35px">
      <img class="logo"
        src="/assets/images/logo/logo-filled-circle.svg" />
      <h2 class="header">We’re updating the magic crystal ball powering Curl Cupid™ 🔮</h2>
      <div>
        <p class="title">You're early for the launch, but you're just in time to show support through our crowdfunding campaign.</p>
      </div>
      <button class="button"
        mat-flat-button
        color="accent">
        <a href="https://glimmer.info/crowdfunding">Support our Campaign</a>
      </button>
      <a [ngStyle]="{'color': 'white'}"
        href="https://instagram.com/glimmer411">Follow along on Instagram</a>
    </div>

  </div>

</div>