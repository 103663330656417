export class BlogArticle {
  id: string;
  title: string;
  description: string;
  image: {
    url: string
  }[];
  link: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
