import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-log-out-dialog',
  templateUrl: './log-out-dialog.component.html',
  styleUrls: ['./log-out-dialog.component.less']
})
export class LogOutDialogComponent implements OnInit {

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  signOut(): void {
    this.auth.signOut()
    .then(_ => {
      this.dialog.closeAll();
      this.router.navigate(['/login']);
    })
  }

}
