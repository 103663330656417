import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaObserverService, LayoutProperties } from '../../services/media-observer/media-observer.service'
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;
  @Input() displayNavigation: boolean

  constructor(
    public layoutObserver: MediaObserverService,
    public analytics: AnalyticsService
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  ngOnInit(): void {
  }

}
