import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DatabaseService } from "../database/database.service";
import { AngularFireFunctions } from "@angular/fire/functions";
import { AnalyticsService } from '../analytics/analytics.service';
import { Router } from "@angular/router";
import { LoadingService } from '../../services/loading/loading.service';
import { QuizResults } from "@app/models/QuizResults";
import { Report, ReportReference } from "@app/models/Report";
import { User } from "@app/models/User";
import { PaymentService } from "../../services/payment/payment.service";
import { QuizService } from '../quiz/quiz.service';

@Injectable({
  providedIn: "root"
})
export class ReportService {
  report: Report;
  unpaidReports: ReportReference[];
  paidReports: ReportReference[];
  user$: Observable<User>;
  user: User;
  report$: Observable<Report>;

  constructor(
    private database: DatabaseService,
    private functions: AngularFireFunctions,
    private analytics: AnalyticsService,
    private payment: PaymentService,
    private router: Router,
    public loading: LoadingService,
    private quiz: QuizService
  ) {
    this.init();
  }
    
  public async init() {
    this.user$ = this.database.user$;
    this.user$.subscribe(user => {
      if (user) {
        this.user = user;
        this.paidReports = user.curlCupidReports ? this.filterReportReferences(user, true) : [];
        this.unpaidReports = user.curlCupidReports ? this.filterReportReferences(user, false) : [];
        if (this.paidReports.length > 0) {
          const id = this.paidReports[0].id;
          this.report$ = this.getReport(id);
        }
      }
    });
    this.report$ ?.subscribe(report => {
      this.analytics.setUserProperties({
        porosity: report.results.porosity,
        curl_pattern: report.results.curlPattern,
        hair_length: report.results.hairLength,
        hair_density: report.results.hairDensity,
        heat_transformation: report.results.heatTransformation,
        strand_thickness: report.results.strandThickness,
        current_state: report.results.currentState,
        physical_transformation: report.results.physicalTransformation,
        gender_identity: report.results.genderIdentity,
        experience_level: report.results.experienceLevel,
        residency: report.results.residency,
      })
    })
  }

  async generateReport(quizResults: QuizResults): Promise<void> {
    let quizResultsRaw = JSON.stringify(quizResults);
    const createReport = this.functions.httpsCallable<any>("createReport");

    const reportPromise = createReport({
      quizResults: quizResultsRaw,
      email: this.user.email
    }).toPromise();

    let report: Report;

    const loadingMessage = "This might take a moment. Thanks for being patient while our crystal ball generates your report! 🔮";
    this.loading.loadingOn(loadingMessage);

    return reportPromise.then(async (result: Report) => {
      report = result;
      return this.database.createReport(report, quizResults.uid);
    })
      .then(_ => {
        this.analytics.logEvent('report', "created_report");
        const reportId = report.id;
        this.report$ = of(report);
        this.report = report;
        this.analytics.setUserProperties({
          porosity: report.results.porosity,
          curl_pattern: report.results.curlPattern,
          hair_length: report.results.hairLength,
          hair_density: report.results.hairDensity,
          heat_transformation: report.results.heatTransformation,
          strand_thickness: report.results.strandThickness,
          current_state: report.results.currentState,
          physical_transformation: report.results.physicalTransformation,
          gender_identity: report.results.genderIdentity,
          experience_level: report.results.experienceLevel,
          residency: report.results.residency,
        })
        const reportUrl = this.getReportUrl(report.id);
        debugger;
        return this.purchaseReport(report.uid, reportId, reportUrl);
      })
  }

  updateReportViewedStatus(reportId: string) {
    const update = {
      viewed: true
    }
    this.database.updateReport(reportId, update);
  }

  async purchaseReport(uid: string, reportId: string, reportUrl: string) {
    debugger
    return this.payment.startCheckout(reportUrl, reportId, uid)
      .then(_ => {
        this.loading.loadingOff();
        this.analytics.logEvent('report', "created_payment_session");
        return;
      })
      .catch(error => {
        console.error(error);
        this.loading.loadingOff();
      })
  }

  getReport(reportId?: string): Observable<Report> {
    return this.database.getReport(reportId);
  }

  getReportUrl(reportId: string): string {
    return `${window.location.protocol}//${window.location.href.split('/')[2]}/report?reportId=${reportId}`;
  }

  private openReport(reportId: string) {
    this.router.navigate(['/report'], {
      queryParams: {
        reportId: reportId,
      }
    });
  }

  createReportObservable(report: Report): Observable<Report> {
    const report$: Observable<Report> = new Observable((observer) => {
      observer.next(report);
    });
    return report$;
  }

  private filterReportReferences(user: User, isPaid: boolean): ReportReference[] {
    // let reports: ReportReference[] = Object.keys(user.curlCupidReports).filter(reportId => {
    //   if (isPaid) return user.curlCupidReports[reportId].isPaid;
    //   else return !user.curlCupidReports[reportId].isPaid;
    // }).map(reportId => {
    //   const report: ReportReference = user.curlCupidReports[reportId];
    //   let reportToReturn = {
    //     id: reportId,
    //     createdDate: report.createdDate,
    //     isPaid: report.isPaid
    //   };
    //   return reportToReturn;
    // });
    let reports: ReportReference[] = Object.keys(user.curlCupidReports).map(reportId => {
      const report: ReportReference = user.curlCupidReports[reportId];
      let reportToReturn = {
        id: reportId,
        createdDate: report.createdDate,
        isPaid: report.isPaid
      };
      return reportToReturn;
    });
    reports = this.sortReverseChronological(reports);
    return reports;
  }

  private sortReverseChronological(
    reports: ReportReference[]
  ): ReportReference[] {
    reports.sort((a, b) => b.createdDate.timestamp - a.createdDate.timestamp);
    reports.map(report => report);
    return reports;
  }
}
