import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaObserverService, LayoutProperties } from '../../services/media-observer/media-observer.service'
import { LoadingService } from '../../services/loading/loading.service';
import { fade } from '../../animations/fade.animation';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.less'],
  animations: [fade]
})
export class PageWrapperComponent implements OnInit {
  @Input() displayNavigation: boolean = true;
  @Input() displayLefSidebar: boolean = true;
  @Input() overridePadding?: boolean = false;
  layoutProperties$: Observable<LayoutProperties>;

  constructor(
    public layoutObserver: MediaObserverService,
    public loading: LoadingService,
    public auth: AuthService
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  ngOnInit(): void {
  }

}
