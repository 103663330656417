import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { take, map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { QuizService } from "../../services/quiz/quiz.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TestSnackbarComponent } from "../../components/test-snackbar/test-snackbar.component";
import { ReportService } from "../../services/report/report.service";
import { Report, ReportReference } from "@app/models/Report";
import { ProductKit } from "@app/models/ProductKit";
import { User } from "@app/models/User";
import { LoadingService } from '../../services/loading/loading.service';
import { fade } from '../../animations/fade.animation';
import { FormBuilder } from '@angular/forms';
import { MediaObserverService, LayoutProperties } from "../../services/media-observer/media-observer.service";
import { DatabaseService } from '../../services/database/database.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
import exportFromJSON from 'export-from-json';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: "admin-page",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.less"],
  animations: [fade,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;
  adminMode: boolean;
  selectedOperation: {
    name: string,
    icon: string,
    id: string
  } = {
      name: "Search Users",
      icon: "search",
      id: "searchUsers"
    };
  operationOptions = {
    ids: ["searchUsers", "importExport", "createProductKits"],
    "searchUsers": {
      name: "Search Users",
      icon: "search",
      id: "searchUsers"
    },
    "importExport": {
      name: "Export Users",
      icon: "file_download",
      id: "importExport"
    },
    "createProductKits": {
      name: "Create Product Kits",
      icon: "dashboard_customize",
      id: "createProductKits"
    }
  }

  userSearchForm = this.formBuilder.group(
    {
      email: '',
      uid: '',
      firstName: ''
    },
  );


  productKitForm = this.formBuilder.group(
    {
      kitType: '',
      productIds: ''
    },
  );

  users$: Observable<User[]>
  reports$: Observable<Report[]>
  users: User[];
  currentSelectedUser: User;
  displayedColumns: string[] = ['uid', 'firstName', 'lastName', 'email'];
  OBJECT = Object;

  constructor(
    private _snackBar: MatSnackBar,
    private quizService: QuizService,
    private reportService: ReportService,
    private db: DatabaseService,
    public loading: LoadingService,
    public layoutObserver: MediaObserverService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private functions: AngularFireFunctions,
    private firestore: AngularFirestore,
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.route.queryParams.subscribe(params => {
      if (params.adminId === environment.adminId) {
        this.adminMode = true;
      }
    })
  }

  ngOnInit(): void { }

  test(): void {
    this.quizService.testQuiz().then(url => {
      this.quizService.resetQuiz();
      this.openSnackBar({ fileurl: url });
    });
  }

  openSnackBar(data: any) {
    this._snackBar.openFromComponent(TestSnackbarComponent, {
      duration: 5000,
      data: data
    });
  }

  toggleCurrentOperation(functionId: string) {
    this.selectedOperation = this.operationOptions[functionId];
  }

  searchUsers() {
    // this.loading.loadingOn();
    let formValues = this.userSearchForm.value;
    const email = formValues.email;
    const uid = formValues.uid;
    const firstName = formValues.firstName;
    const params = {
      email: email,
      uid: uid,
      firstName: firstName
    }
    this.users$ = this.db.getUsersByParameter(params);
    this.users$.subscribe(users => this.users = users);
  }

  setReport(id: string) {
    this.reportService.report$ = this.reportService.getReport(id);
    this.router.navigate(['/products'], {
      queryParams: {
        firstName: this.currentSelectedUser.firstName,
        lastName: this.currentSelectedUser.lastName,
        adminId: environment.adminId
      }
    })
  }

  async refreshUserDataInAirtable() {
    this.loading.loadingOn();
    this.db.refreshUserDataInAirtable().then(_ => {
      this.loading.loadingOff();
    })
    .catch(err => {
      this.loading.loadingOff();
      console.log(err);
    })
  }

  async runUserExport() {
    this.loading.loadingOn();

    let x = this.firestore.collection<User>('/users').get()
      .pipe(
        map((res) => res.docs.map(d => d.data())),
        take(1)
      ).toPromise().then(data => {
        const users = data.map((user: User) => {
          // TODO
          const createdDateReadable = user.createdDate ?.timestampReadable;
          const lastLoginReadable = user.lastLogin ?.timestampReadable;
          user["createdDateReadable"] = createdDateReadable;
          user["lastLoginReadable"] = lastLoginReadable;

          if (user.curlCupidReports && !Array.isArray(user.curlCupidReports)) {
            const sortedReports = Object.keys(user.curlCupidReports).sort((reportId1, reportId2) => {
              let report1: ReportReference = user.curlCupidReports[reportId1];
              let report2: ReportReference = user.curlCupidReports[reportId2];
              return report1.createdDate.timestamp - report2.createdDate.timestamp;
            });
            const lastReportId = sortedReports.pop();
            const lastReport: ReportReference = user.curlCupidReports[lastReportId];
            const lastReportCreatedDate = lastReport.createdDate ?.timestampReadable;

            user["lastReportId"] = lastReportId;
            user["curlCupidReportIds"] = Object.keys(user.curlCupidReports).join(", ");
            user["lastReportCreatedDate"] = lastReportCreatedDate;
          }

          if (user.productKits && !Array.isArray(user.productKits)) {
            const sortedKits = Object.keys(user.productKits).sort((kitId1, kitId2) => {
              let kit1: ReportReference = user.productKits[kitId1];
              let kit2: ReportReference = user.productKits[kitId2];
              return kit1.createdDate.timestamp - kit2.createdDate.timestamp;
            });
            const lastKitId = sortedKits.pop();
            const lastKit: ReportReference = user.productKits[lastKitId];
            const lastKitCreatedDate = lastKit.createdDate ?.timestampReadable;

            user["lastKitId"] = lastKitId;
            user["productKitIds"] = Object.keys(user.productKits).join(", ");
            user["lastKitCreatedDate"] = lastKitCreatedDate;
          }
          return user;
        })
        const fileName = 'CurlCupidUserExport';
        const exportType = 'csv';
        exportFromJSON({
          data: users,
          fileName: fileName,
          fields: [
            "uid",
            "displayName",
            "firstName",
            "lastName",
            "email",
            "phoneNumber",
            "curlCupidReportIds",
            "productKitIds",
            "photoURL",
            "lastLoginReadable",
            "createdDateReadable",
            "lastUpdatedReadable",
          ],
          exportType: exportType
        })
        this.loading.loadingOff();
      })
      .catch(error => {
        console.error(error)
        this.loading.loadingOff()
      })
  }

  async runReportExport() {
    this.loading.loadingOn();

    let x = this.firestore.collection<Report>('/curlCupidReports').get()
      .pipe(
        map((res) => res.docs.map(d => d.data())),
        take(1)
      ).toPromise().then(data => {
        const reports = data.map((report: Report) => {
          let reportToSave = new Report();
          const createdDateReadable = report.createdDate ?.timestampReadable;
          reportToSave["createdDateReadable"] = createdDateReadable;
          reportToSave.id = report.id;
          reportToSave.uid = report.uid;
          reportToSave.resultsId = report.resultsId;
          reportToSave.isPaid = report.isPaid;
          reportToSave.viewed = report.viewed;
          return reportToSave;
        })
        const fileName = 'CurlCupidReportExport';
        const fields = [
          "id",
          "uid",
          "resultsId",
          "isPaid",
          "viewed",
        ]
        this.exportData(fileName, reports, fields);
        this.loading.loadingOff();
      })
      .catch(error => {
        console.error(error)
        this.loading.loadingOff()
      })
  }

  async runProductKitExport() {
    this.loading.loadingOn();

    let x = this.firestore.collection<ProductKit>('/productKits').get()
      .pipe(
        map((res) => res.docs.map(d => d.data())),
        take(1)
      ).toPromise().then(data => {
        const kits = data.map((kit: ProductKit) => {
          kit["productIds"] = kit.products.join(", ");
          return kit;
        })
        const fileName = 'CurlCupidProductKitExport';
        const fields = [
          "id",
          "uid",
          "type",
          "productIds",
        ]
        this.exportData(fileName, kits, fields);
        this.loading.loadingOff();
      })
      .catch(error => {
        console.error(error)
        this.loading.loadingOff()
      })
  }

  private exportData(fileName: string, data: any, fields: string[]) {
    const exportType = 'csv';
    exportFromJSON({
      data: data,
      fileName: fileName,
      fields: [fields],
      exportType: exportType
    })
  }

  async createProductKit() {
    let formValues = this.productKitForm.value;
    const productIds = formValues.productIds.split(',');
    const kitType = formValues.kitType;
    const uid = this.users[0].uid;
    if (productIds && kitType && uid) {
      await this.db.createProductKit(new ProductKit(productIds, kitType, uid));
      console.log(`Kit created for UID ${uid}`);
    }
    else {
      console.error("Unable to create kit.")
    }
  }
}
