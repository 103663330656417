import { Component, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from '@app/models/User';
import { Report } from '@app/models/Report';
import { Product } from '@app/models/Product';
import { MediaObserverService, LayoutProperties } from "../../services/media-observer/media-observer.service";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from '../../services/auth/auth.service';
import { ReportService } from '../../services/report/report.service';
import { DatabaseService } from '../../services/database/database.service';
import { LoadingService } from '../../services/loading/loading.service';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { fade } from '../../animations/fade.animation';

@Component({
  selector: 'products-page',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.less'],
  animations: [fade]
})
export class ProductsComponent implements OnInit, AfterViewChecked {
  user$: Observable<User>;
  user: User;
  report$: Observable<Report>;

  layoutProperties$: Observable<LayoutProperties>;

  currentProduct: Product;
  products: Product[];
  reportProducts: Product[];
  productsToDisplay: Product[][];
  sections: string[];
  types: string[];
  subtypes: string[];
  brands: string[];
  porosities: string[];
  symptoms: string[];
  specifics: string[];
  xFrees: string[];

  filterBy: string[];
  selectedFilters: string[] = [];
  combinedFilters: {
    filter: string,
    filterBy: string
  }[] = [];
  combinedFiltersString: string = "";
  productAttributesIndexArray: [string, any, string][];
  delayExpansionPanelAnimation = true;

  productCatalogColumns: number;
  productIndex: number;
  chunk: number;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public layoutObserver: MediaObserverService,
    public database: DatabaseService,
    public loading: LoadingService,
    public analytics: AnalyticsService
  ) {
    this.loadUser();
    this.loadReport();
    this.route.queryParams.subscribe(_ => {
      this.loadProducts();
    })
    this.loading.loadingOff();
  }

  ngOnInit(): void {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.productCatalogColumns = 2;
    this.layoutProperties$.subscribe(properties => {
      switch (properties.layoutSize) {
        case "xs":
          this.productCatalogColumns = 1;
          break;
        case "sm":
          this.productCatalogColumns = 2;
          break;
        case "md":
          this.productCatalogColumns = 3;
          break;
        case "lg":
          this.productCatalogColumns = 3;
          break;
        case "xl":
          this.productCatalogColumns = 3;
          break;
        default:
          this.productCatalogColumns = 2;
          break;
      }
    })
  }

  ngAfterViewChecked() {
    this.loading.loadingOff();
  }

  private loadUser() {
    this.user$ = this.auth.user$;
    this.user$.subscribe(user => this.user = user)
  }

  private loadReport() {
    this.loading.loadingOn();
    this.report$ = this.reportService.report$;
  }

  private getProducts(productIds: string[]): Promise<Product[]> {
    return this.database.getProductsById(productIds);
  }

  private async loadProducts() {
    let productsToRetrieve: string[];
    const params = await this.route.queryParams.pipe(first()).toPromise().then(params => params);
    const productId = params.productId;

    if (productId) productsToRetrieve = [productId];
    else this.currentProduct = null;

    if (!productId && !this.reportProducts) {
      let report = await this.report$.pipe(first()).toPromise().then(report => report);
      productsToRetrieve = report.products;
    }

    if (productsToRetrieve) {
      let products = await this.getProducts(productsToRetrieve);
      this.products = products;
      if (productId) {
        this.currentProduct = products.filter(product => {
          return product.id.toString() === productId;
        })[0];
      }
    }
    if (!productId && !this.reportProducts) this.reportProducts = this.products;
    this.prepFilters(this.products);
    this.loadProductsToDisplay(this.products);
  }

  private loadProductsToDisplay(products: Product[]) {
    let i: number, j: number, temparray: Product[][] = [];
    let chunk = this.productCatalogColumns;
    this.chunk = chunk;
    for (i = 0, j = products.length; i < j; i += chunk) {
      temparray.push(products.slice(i, i + chunk));
    }
    this.productsToDisplay = temparray;
    setTimeout(_ => { this.delayExpansionPanelAnimation = false }, 100)
  }

  private prepFilters(products: Product[]) {
    this.productAttributesIndexArray = [
      ["productSubtype", "subtypes", "Subtype"],
      ["brand", "brands", "Brand"],
      ["porosity", "porosities", "Porosity"],
      ["scalpAndHairHealth", "symptoms", "Symptom"],
      ["productSpecifics", "specifics", "Specifics"],
      ["productType", "types", "Type"],
      ["xFree", "xFrees", "X-Free"],
    ]

    this.productAttributesIndexArray.forEach((index) => {
      this[index[1]] = this.indexProductAttributes(products, index[0]);
    })
  }

  private indexProductAttributes(products: Product[], attribute: string) {
    let resultsAsSet = new Set();
    let resultsToReturn = [];
    products.map(product => {
      switch (attribute) {
        case 'brand':
          resultsAsSet.add(product[attribute]);
          break;
        default:
          if (!product[attribute]) break;
          if (typeof product[attribute] === 'object') {
            product[attribute].forEach((a: string) => {
              resultsAsSet.add(a);
            })
          }
          else {
            resultsAsSet.add(product[attribute]);
          }
          break;
      }
    })
    resultsToReturn = Array.from(resultsAsSet).sort();
    return resultsToReturn;
  }

  updateProductFilter(filter: string, filterBy: string) {
    let combinedFilter = { filter: filter, filterBy: filterBy };
    if (this.selectedFilters.includes(filter)) {
      this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);
      this.combinedFilters = this.combinedFilters.filter(f => {
        return f.filter !== filter;
      })
    }
    else {
      this.selectedFilters.push(filter);
      this.combinedFilters.push(combinedFilter);
      this.analytics.logFilteredProductEvent(filter, filterBy)
    }

    const tempFilters = this.combinedFilters.map(filterObject => {
      return `${filterObject.filterBy} | ${filterObject.filter}`;
    })

    this.combinedFiltersString = tempFilters.join(', ');
    this.loadProductsToDisplay(this.products);
    window.scrollTo(0, 0);
  }

  clearCurrentProduct() {
    this.currentProduct = null;
    this.products = this.reportProducts;
    this.scrollToIndex(this.productIndex);
  }

  clearFilters() {
    this.selectedFilters = [];
    this.combinedFilters = [];
    this.combinedFiltersString = "";
    this.loadProductsToDisplay(this.products);
  }

  private scrollToIndex(index: number) {
    setTimeout(() => {
      this.viewPort.scrollToIndex(index);
    });
  }

}
