<div class="container">
  <div class="user-profile"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="25px"
    *ngIf="layoutProperties$ | async as layoutProperties">
    <div class="avatar-container"
      fxFlex="40%"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
      *ngIf="user$ | async as user"
      [@fade]="report$">
      <ng-container *ngIf="user; else noPhoto">
        <img class="
        avatar"
          src="{{ user.photoURL }}" />
      </ng-container>
      <ng-template #noPhoto>
        <img class="avatar"
          src="../../../assets/images/misc/avatar.jpg">
      </ng-template>

      <span class="user-name">{{ user.displayName }}</span>
    </div>
    <ng-container *ngIf="report$ | async as report; else noReport">
      <div class="demographics-container"
        *ngIf="displayAll"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
        [@fade]="report$">
        <ng-container *ngIf="adminMode">
          <div class="full-width"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="15px"
            [ngStyle]="{'background-color': 'red', 'color': 'white', 'padding': '5px' }">
            <p class="mat-small"
              color="warn"><b>Viewing report for {{ firstNameOverride + ' ' + lastNameOverride }}. </b></p>
          </div>
        </ng-container>
        <li fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
          *ngIf="report.results.locationData">
          <mat-icon>location_on</mat-icon><span>{{ report.results.locationData.city + ', ' + report.results.locationData.state }}</span>
        </li>
        <li fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px">
          <mat-icon>emoji_emotions
          </mat-icon><span>{{ report.demographics.D6 }}</span>
        </li>
        <li fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px">
          <mat-icon>cake
          </mat-icon><span>{{ report.demographics.D4 }} years old</span>
        </li>
        <li fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px">
          <mat-icon>public
          </mat-icon><span>{{ report.demographics.D8?.join(', ') }}</span>
        </li>
      </div>
      <div class="hair-attributes-container"
        *ngIf="displayAll"
        [@fade]="report$">
        <mat-accordion [displayMode]="'flat'"
          [multi]="true"
          [@.disabled]="delayExpansionPanelAnimation">
          <mat-expansion-panel class="scalp-and-health-symptoms"
            [expanded]='true'>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Hair Properties
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="10px">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                class="hair-property"
                *ngIf="report.results.curlPattern">
                <mat-icon>category</mat-icon>
                <span>{{ report.results.curlPattern }}</span>
              </div>
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                class="hair-property"
                *ngIf="report.results.currentState">
                <mat-icon>category</mat-icon>
                <span>{{ report.results.currentState.split(': ')[1] }}</span>
              </div>
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                class="hair-property"
                *ngIf="report.results.porosity">
                <mat-icon>category</mat-icon>
                <ng-container *ngIf="report.results.porosity !== 'Porosity: Error'; else errorPorosity">
                  <span>{{ report.results.porosity.split(': ')[1] }} Porosity</span>
                </ng-container>
                <ng-template #errorPorosity>
                  <span>Unknown Porosity</span>
                </ng-template>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="scalp-and-health-symptoms">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Hair and Scalp Health
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="10px">
              <ng-container *ngIf="report.results.scalpAndHairHealth.length > 0; else noSymptoms">
                <ng-container *ngFor="let condition of report.results.scalpAndHairHealth">
                  <div fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px"
                    class="health-symptom"
                    *ngIf="!report.hiddenKeys.includes(condition)">
                    <ng-container>
                      <mat-icon>stream</mat-icon>
                      <span>{{ condition.split('Scalp Health: ')[1] }}</span>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #noSymptoms>
                <span class="mat-small">No symptoms reported</span>
              </ng-template>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel class="scalp-and-health-symptoms">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Physical Health
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="10px">
              <ng-container *ngIf="report.results.preExistingConditionsAndHealth?.length > 0; else noConditions">
                <ng-container *ngFor="let condition of report.results.preExistingConditionsAndHealth">
                  <ng-container *ngIf="!report.hiddenKeys.includes(condition)">
                    <div fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="10px"
                      class="health-symptom"
                      *ngIf="condition.split(': ')[1] !== 'None' && condition.split(': ')[1] !== 'No'">
                      <mat-icon>stream</mat-icon>
                      <span>{{ condition.split(': ')[1] }}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #noConditions>
                <span class="mat-small">No conditions reported</span>
              </ng-template>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
    <ng-template #noReport>
      <div class="loading-container">
        <app-loading></app-loading>
      </div>
    </ng-template>


  </div>
</div>