import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MediaObserverService, LayoutProperties } from "../../services/media-observer/media-observer.service";
import { Observable } from 'rxjs';
import { AuthService } from "../../services/auth/auth.service";
import { LoadingService } from '../../services/loading/loading.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { fade } from '../../animations/fade.animation';
import FAQ from './data/FAQ';

@Component({
  selector: "lp",
  templateUrl: "./lp.component.html",
  styleUrls: ["./lp.component.less"],
  animations: [fade]
})
export class LpComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;
  isMobile: boolean;
  outerPadding: string;
  FAQ = FAQ;

  featureOptions = [
    {
      title: "Understand your Curls",
      description: "Uncover the underlying needs and issues unique to your curls with the Curl Cupid™ Quiz.",
      img: "../../../../assets/images/lp/quiz-lp-mockup.png",
      feature: "quiz"
    },
    {
      title: "Explore your Report",
      description: "Revitalize your routine with a tailor-made hair Curl Cupid™ Report designed to help you experience the best results.",
      img: "../../../../assets/images/lp/report-lp-mockup.png",
      feature: "report"
    },
    {
      title: "Shop for Products",
      description: "Browse your personalized Product Pantry of products to discover gems your hair has been dying to try.",
      img: "../../../../assets/images/lp/products-lp-mockup.png",
      feature: "products"
    },
  ]

  howItWorks = [
    {
      number: 1,
      headline: "Let's get acquainted!",
      description:
        "Curl Cupid™ Quiz starts at square one by diagnosing potential problem areas in your care routine, hair health history, current environment, and more.",
      demo: 'quiz'
    },
    {
      number: 2,
      headline: "We care about your hair like it's ours.",
      description:
        "Based on your unique Quiz results, Curl Cupid™ Report explains each key attribute of your hair, suggests care techniques that your hair will love, and curates a list of products that are most likely to work for you.",
      demo: 'report'
    },
    {
      number: 3,
      headline: "We'll always be by your side.",
      description:
        "Understanding your hair is one thing, but putting that knowledge to use is even better. Curl Cupid™ Product Pantry remains the homebase for your hair journey, as we continue to build a suite of exciting new features to get you to your goals.",
      demo: 'products'
    }
  ];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }

  dynamicSlides = [
    {
      id: 1,
      src: 'https://via.placeholder.com/600/92c952',
      alt: 'Side 1',
      title: 'Side 1'
    },
    {
      id: 2,
      src: 'https://via.placeholder.com/600/771796',
      alt: 'Side 2',
      title: 'Side 2'
    },
    {
      id: 3,
      src: 'https://via.placeholder.com/600/24f355',
      alt: 'Side 3',
      title: 'Side 3'
    },
    {
      id: 4,
      src: 'https://via.placeholder.com/600/d32776',
      alt: 'Side 4',
      title: 'Side 4'
    },
    {
      id: 5,
      src: 'https://via.placeholder.com/600/f66b97',
      alt: 'Side 5',
      title: 'Side 5'
    }
  ]

  constructor(
    public layoutObserver: MediaObserverService,
    public auth: AuthService,
    public loading: LoadingService
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  ngOnInit(): void {
    this.layoutObserver.layoutObserver$.subscribe(result => {
      this.isMobile = result.isMobile;
      this.outerPadding = result.outerPadding;
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
