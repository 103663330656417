<div class="product"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="25px"
  routerLinkActive="active"
  [ngStyle]="{'height': height}"
  (click)="loadProduct()"
  matRipple>
  <div fxFlex="1 1 60%">
    <img class="product-image"
      *ngIf="product.image"
      src="{{ product.image[0].url }}">
  </div>
  <div [ngStyle]="{ 'width': '100%' }">
    <div class="product-attributes-container card raised"
      fxFlex="1 0 40%"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="25px">
      <div fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <span class="brand">{{ product.brand }}</span>
        <span class="name">{{ product.name }}</span>
      </div>
    </div>
  </div>
</div>