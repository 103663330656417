import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less']
})
export class LoadingComponent implements OnInit {
  loadingMessage: string;

  constructor(public loading: LoadingService) { }

  ngOnInit(): void {
    this.loadingMessage = this.loading.loadingMessage;
  }

}
