import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MediaObserverService, LayoutProperties } from "../../../services/media-observer/media-observer.service";
import { Observable } from 'rxjs';

interface DialogData {
  title: string;
  message: string;
  buttonList: Button[];
}

interface Button {
  text: string;
  isWarning: boolean;
  onClose: boolean;
}

@Component({
  selector: "general-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.less"]
})
export class DialogComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    public layoutObserver: MediaObserverService,
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  ngOnInit(): void { }

  oncloseDialog() {
    this.dialog.closeAll();
  }
}
