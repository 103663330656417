export class QuizFamily {
  id: string;
  title: string;
  sections: string[];
  interactions: string;
  description: string;
  image: any[];

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
