import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-test-snackbar',
  templateUrl: './test-snackbar.component.html',
  styleUrls: ['./test-snackbar.component.less']
})
export class TestSnackbarComponent implements OnInit {
  fileUrl;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
    this.fileUrl = this.data.fileurl;
  }

}
