// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  useEmulators: true,
  firebase: {
    apiKey: "AIzaSyClYtGg-jEQ4PyL-PtiionBssSeeNazJ24",
    authDomain: "hair-iq-v1-dev.firebaseapp.com",
    databaseURL: "https://hair-iq-v1-dev.firebaseio.com",
    projectId: "hair-iq-v1-dev",
    storageBucket: "hair-iq-v1-dev.appspot.com",
    messagingSenderId: "906514506640",
    appId: "1:906514506640:web:be00f8b048633fecea8915",
    measurementId: "G-EJ5XSL9QH9"
  },
  stripeAPIKey: "pk_test_51II4IqIbKc881FMsY89X5jwacDbrGjpfYF8AnXqlRxJuZYnwu5VQBv7dGoaZjyIljElk5KYxSGNJW3uWYhsCdDPs00B9oZmnDh",
  adminId: '1234',
  debugMode: true,
  version: '1.0'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
