// import QuizFieldsMasterList from '../data/QuizFieldsMasterList.json';
import { QuizField } from "./QuizField";

export class QuizState {
  history: {
    fieldTrack: QuizField[];
    storedValues: Object;
  }[];
  fieldTrack: QuizField[];
  storedValues: Object;
  currentFieldIndex: number;
  quizFields: {
    [id: string]: QuizField;
  };
  isFirstField: boolean;
  isInProgress: boolean;
  initialFieldID: string;

  constructor(fields: { [id: string]: QuizField }) {
    this.quizFields = fields;
  }

  initState(initialFieldID: string) {
    this.initialFieldID = initialFieldID;
    this.resetState();
  }

  updateState(newState: Object) {
    Object.assign(this, newState);
  }

  startQuiz(experienceLevel: string) {
    this.storedValues = {
      experienceLevel: experienceLevel,
    };
    this.isInProgress = true;
  }

  resetState() {
    this.fieldTrack = [this.quizFields[this.initialFieldID]];
    this.storedValues = {};
    this.history = [
      {
        fieldTrack: this.fieldTrack,
        storedValues: this.storedValues,
      },
    ];
    this.currentFieldIndex = 0;
    this.isFirstField = true;
    this.isInProgress = false;
    this.fieldTrack.forEach(field => {
      field.values = [];
    });
  }

  currentField(): QuizField {
    return this.fieldTrack[this.currentFieldIndex];
  }

  getStoredValues(): any {
    let result = {};
    this.fieldTrack.forEach(field => {
      result[field.id] = field.values;
    })
    return result;
  }
}
