<ng-container *ngIf="layoutProperties$ | async as layoutProperties">

  <div class="product-page"
    fxLayout="{{layoutProperties.isMobile ? 'column' : 'row'}}"
    fxLayoutAlign="start stretch"
    fxLayoutGap="25px"
    *ngIf="report$ | async as report"
    [@fade]="report$">
    <div class="image-container"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="25px"
      fxFlex="1 1 50%">
      <img src="{{ product.image[0].url }}">
    </div>
    <div class="product-attributes-container card raised"
      [ngStyle]="{'padding': layoutProperties.isMobile ? '25px' : '50px'}"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="25px"
      fxFlex="1 1 50%">
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="10px">
        <span class="brand">{{ product.brand }}</span>
        <span class="name">{{ product.name }}</span>
        <span class="subtype">{{ product.productSubtype.join(', ') }}</span>
      </div>
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="10px">
        <ng-container *ngFor="let porosity of product.porosity">
          <div *ngIf="porosity === report.results.porosity || porosity === 'Porosity: Any'"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="15px"
            class="hair-property porosity"
            matTooltip="The Curl Cupid™ Porosity Bot is an algorithm that uses science and logic to analyze the ingredients of hair products and tag them with their appropriate porosity. Understanding the chemical make-up of your products is key to choosing hair products that work for you - the first time! While we hope this feature works amazing for your porosity level, please be advised that we are still working to perfect the Porosity Bot and results are not 100% guaranteed.">
            <mat-icon>category</mat-icon>
            <span>{{ porosity.split(': ')[1] + ' Porosity'}}</span>
          </div>
        </ng-container>
        <ng-container *ngFor="let pattern of product.curlPattern">
          <div fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="15px"
            class="hair-property"
            *ngIf="pattern === report.results.curlPattern || pattern === 'All'">
            <mat-icon>category</mat-icon>
            <span>{{ pattern === 'All' ? 'All Curl Patterns' : pattern}}</span>
          </div>
        </ng-container>
        <ng-container *ngFor="let symptom of product.scalpAndHairHealth">
          <div fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="15px"
            class="health-symptom">
            <mat-icon>stream</mat-icon>
            <span>{{ symptom.split(': ')[1] }}</span>
          </div>
        </ng-container>

      </div>
      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        *ngIf="product.productSpecifics?.length > 0 || product.xFree?.length > 0">
        <mat-chip-list>
          <mat-chip class="attribute"
            *ngFor="let attribute of product.productSpecifics">
            {{ attribute }}
          </mat-chip>
          <mat-chip class="attribute"
            *ngFor="let xFree of product.xFree">
            {{ xFree }}
          </mat-chip>
        </mat-chip-list>
      </div>
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="0px"
        *ngIf="product.ingredients">
        <mat-accordion [displayMode]="'flat'"
          [multi]="true">
          <mat-expansion-panel (afterExpand)="analytics.logEvent('product', 'ingredients_viewed')">
            <mat-expansion-panel-header>
              <mat-panel-title class="mat-small">
                <b>What is it made of?</b>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="mat-small">{{ product.ingredients.join(', ')}}</p>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-divider> </mat-divider>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        [ngStyle]="{'width': '100%'}">
        <button mat-raised-button
          class="button"
          [ngStyle]="{'width': '75%'}"
          color="accent"
          (click)="viewProductPurchasePage(product.linkToPurchase)">
          <div fxLayout="row"
            fxLayoutAlign="center start"
            fxLayoutGap="10px">
            <span>Buy on Amazon</span>
          </div>
        </button>
        <button mat-icon-button
          color="accent"
          [cdkCopyToClipboard]="href"
          (click)="analytics.logCopyProductLinkButtonEvent(product)">
          <mat-icon>ios_share</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>