var FAQ =

  [
    {
      question: 'How much does it cost to use Glimmer 411’s software and services?',
      answer: 'It costs $10 to access your Curl Cupid™ Report and Product Pantry after taking the Quiz.'
    },
    {
      question: 'Do you only focus on big, well-known brands? What can you do for the upcoming and smaller hair product brands?',
      answer: 'Not at all! Though we started off with the bigger well-known brands, we’re currently  building out our product database to include budding and smaller product brands that cater to specific hair profiles.'
    },
    {
      question: 'I want to get my product recommended! What’s the process?',
      answer: 'We’d love to work with you!  Please fill out the brand Interest form to submit your products for consideration to be featured on our Curl Cupid™ platform and other social media platforms: https://www.glimmer.info/brand-interest-form. After you submit the form  we’ll work together to analyze the product and determine which hair profiles it can benefit the most.  Please keep in mind that we only recommend products that are a good fit for our clients. '
    },
    {
      question: 'Do you guarantee better hair results for users of your platform?',
      answer: "There is always a small chance for error in your results, but Glimmer 411® stands by the value and benefit you'll receive with Curl Cupid™. If you don't feel like we've lived up to this promise, please reach out to our Support Team at contact@glimmer.info."
    },

  ];
export default FAQ;
