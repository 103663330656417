import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { take, switchMap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { ReportService } from "../../services/report/report.service";
import { Report, ReportReference } from "@app/models/Report";
import { User } from "@app/models/User";
import { QuizResults } from "@app/models/QuizResults";
import { MediaObserverService, LayoutProperties } from "../../services/media-observer/media-observer.service";
import { LoadingService } from '../../services/loading/loading.service';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from "@angular/fire/auth";
import { FormBuilder } from '@angular/forms';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { fade } from '../../animations/fade.animation';
import { QuizService } from '../../services/quiz/quiz.service';

@Component({
  selector: "report-page",
  host: {
    class: "page"
  },
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.less"],
  animations: [fade]
})
export class ReportComponent implements OnInit {
  layoutProperties$: Observable<LayoutProperties>;
  report$: Observable<Report>;
  user$: Observable<User>
  user: User;
  unpaidReports: ReportReference[];
  paidReports: ReportReference[];
  displayReport: boolean;
  adminMode: boolean;
  params: any;

  sections = [
    {
      title: "Overview",
      id: "overview"
    },
    {
      title: "Hair Type",
      id: "hairType"
    },
    {
      title: "Hair Properties",
      id: "hairProperties"
    },
    {
      title: "Scalp Health",
      id: "scalpHealth"
    },
    {
      title: "Hair Health",
      id: "hairHealth"
    }
  ];
  sectionIndex = 0;
  currentSection = this.sections[0];

  resultsForm = this.formBuilder.group(
    {
      manualReportData: '',
      reportGenerateType: '',
      displayName: '',
      uid: ''
    },
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    public layoutObserver: MediaObserverService,
    public loading: LoadingService,
    private auth: AngularFireAuth,
    private analytics: AnalyticsService,
    private quizService: QuizService
  ) {
    this.loadReport();
  }

  ngOnInit(): void {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.route.queryParams.subscribe(params => {
      if (params.adminId === environment.adminId) {
        this.adminMode = true;
      }
    })
    this.auth.authState.pipe(
      take(1),
      switchMap(async user => {
        const token = await user.getIdTokenResult();
        this.adminMode = token.claims.admin;
      })
    )
  }

  loadReport(): void {
    this.loading.loadingOn();
    this.user$ = this.reportService.user$;
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (params.reportId) {
        this.init();
        this.report$ = this.reportService.getReport(params.reportId);
        this.displayReport = this.report$ ? true : false;
        this.analytics.logEvent('report', 'report_viewed');
        this.loading.loadingOff();
      } else {
        this.reportService.user$.subscribe(user => {
          this.user = user;
          this.paidReports = this.reportService.paidReports;
          this.unpaidReports = this.reportService.unpaidReports;
          this.loading.loadingOff();
        });
      }
    });
  }

  purchaseReport(reportId: string): void {
    this.loading.loadingOn();
    const url = this.reportService.getReportUrl(reportId);
    this.reportService.purchaseReport(this.user.uid, reportId, url);
  }

  exitReport() {
    const params = {};
    Object.keys(this.params).map(param => {
      if (!this.params['reportId']) params[param] = this.params[param];
    })
    let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
    this.router.navigateByUrl(url);
    this.displayReport = false;
  }

  generateReportManually() {
    this.loading.loadingOn();
    let results: QuizResults;
    let formValues = this.resultsForm.value;
    const displayName = formValues.displayName;
    const uid = formValues.uid;
    this.user$.pipe(take(1)).toPromise().then(_ => {
      switch (formValues.reportGenerateType) {
        case "results":
          results = new QuizResults(JSON.parse(formValues.manualReportData));
          this.reportService.generateReport(results).then(_ => {
            this.loading.loadingOff();
          })
          break;
        case "values":
          this.quizService.generateReport(JSON.parse(formValues.manualReportData), uid, displayName).then(_ => {
            this.loading.loadingOff();
          })
          break;
        default:
          console.error('No generation type selected.')
          break;
      }
    })
  }

  init() {
    this.sectionIndex = 0;
    this.currentSection = this.sections[0];
  }

  previousSection() {
    if (this.sectionIndex <= 0) {
      return;
    }
    this.sectionIndex -= 1;
    this.currentSection = this.sections[this.sectionIndex];
  }

  nextSection() {
    if (this.sectionIndex >= this.sections.length) {
      return;
    }
    this.sectionIndex += 1;
    this.currentSection = this.sections[this.sectionIndex];
  }
}
