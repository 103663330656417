import { Component, OnInit } from '@angular/core';
import { MediaObserverService } from '../../services/media-observer/media-observer.service'

@Component({
  selector: 'app-temporary-splash',
  templateUrl: './temporary-splash.component.html',
  styleUrls: ['./temporary-splash.component.less']
})
export class TemporarySplashComponent implements OnInit {
  isMobile: boolean;
  outerPadding: string;
  maxWidth: string;

  constructor(public layoutObserver: MediaObserverService, ) { }

  ngOnInit(): void {
    this.layoutObserver.layoutObserver$.subscribe(result => {
      this.isMobile = result.isMobile;
      this.outerPadding = result.outerPadding;
      this.maxWidth = result.maxWidth;
    })
  }

}
