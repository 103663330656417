<page-wrapper [displayNavigation]='!isInProgress'
  *ngIf="layoutProperties$| async as layoutProperties">

  <div>

    <ng-container *ngIf="!isInProgress; else quizInProgress">

      <div class="section section-1"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="40px">

        <div class=" title-card"
          [ngClass]="{ 'mobile': layoutProperties.isMobile }"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="25px">
          <span class='page-title'>The Curl Cupid™ Quiz</span>
          <h2>Piece together the beautiful puzzle of your textured hair.</h2>
          <div class="divider-bar"></div>
        </div>

        <div class="start-card"
          [ngClass]="{ 'mobile': layoutProperties.isMobile }"
          fxLayout="column"
          fxLayoutAlign="center start"
          fxLayoutGap="25px">
          <span strong
            class='subtitle'><b>How can we help you?</b></span>

          <mat-grid-list class="option-container"
            cols="{{ layoutProperties.isMobile ? '1' : '3' }}"
            rowHeight="{{ layoutProperties.isMobile ? '125px' : '300px' }}"
            gutterSize="{{ layoutProperties.isMobile ? '5px' : '25px' }}">

            <mat-grid-tile *ngFor="let option of experienceLevelOptions"
              [colspan]="1"
              [rowspan]="1"
              (click)="onOptionSelect(option.level)"
              [ngClass]="{'selected': experienceLevel === option.level}">
              <div [ngClass]="{
                    'selected': experienceLevel === option.level,
                    'mobile': layoutProperties.isMobile
                  }"
                class="card raised hoverable"
                fxLayout="column"
                fxLayoutAlign="{{ layoutProperties.isMobile ? 'start center' : 'center center' }}"
                fxLayoutGap="0px"
                matRipple
                [matRippleCentered]="true">
                <span class="top-div {{ option.level }}-option"
                  fxFlex="{{ layoutProperties.isMobile ? '1 1 50%'' : '1 1 25%' }}"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  *ngIf="!layoutProperties.isMobile">
                  <p>{{ option.topCaption }}</p>
                </span>
                <div class="option-img {{ option.level }}-option"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  *ngIf="!layoutProperties.isMobile">
                  <img src="../../../assets/images/quiz/lp/{{ option.level }}-option.svg">
                </div>
                <div class="bottom-div {{ option.level }}-option"
                  fxFlex="{{ layoutProperties.isMobile ? '1 1 50%'' : '1 1 25%' }}"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  *ngIf="!layoutProperties.isMobile">
                  <p>{{ option.bottomCaption }}</p>
                </div>
                <div class="mobile-div {{ option.level }}-option"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  *ngIf="layoutProperties.isMobile">
                  <span>{{ option.topCaption }}</span>
                  <p>{{ option.bottomCaption }}</p>
                </div>
              </div>
            </mat-grid-tile>

          </mat-grid-list>

        </div>

        <div class="start-button-container"
          [ngClass]="{ 'mobile': layoutProperties.isMobile }"
          [ngStyle]="{'padding': layoutProperties.outerPadding, 'padding-top': '0', 'padding-bottom': '0px'}"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="px">
          <button mat-raised-button
            class="button start-button"
            color="accent"
            (click)="onStart()"
            [disabled]="!experienceLevel">
            Start Quiz
            <mat-icon>
              forward
            </mat-icon>
          </button>
        </div>

      </div>
    </ng-container>

  </div>

  <ng-template #quizInProgress>
    <div [@fade]="isInProgress">

      <div class="quiz-background"
        *ngIf="isInProgress"></div>
      <div class="background-overlay"
        *ngIf="isInProgress"></div>

      <div class="quiz-outer-container"
        fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
        fxLayoutGap="{{ layoutProperties.isMobile ? '15px' : '35px' }}">

        <div class="quiz-inner-container"
          fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
          fxLayoutAlign="center space-between "
          fxLayoutGap="25px">

          <div class="family-card raised"
            fxFlex="33%"
            *ngIf="currentField.family !== 'intro'">
            <div class="">
              <div class="card raised"
                [ngClass]="{ 'mobile': layoutProperties.isMobile }"
                fxLayout="column"
                fxLayoutGap="35px"
                fxLayoutAlign="{{ layoutProperties.isMobile ? 'start start' : 'start center' }}">
                <div class="family-image-container"
                  *ngIf="!layoutProperties.isMobile">
                  <img class="family-image"
                    src="{{ currentFamily.image[0].url }}">
                </div>
                <div fxLayout="row"
                  fxLayoutAlign="{{ layoutProperties.isMobile ? 'start center' : 'center center' }}"
                  fxLayoutGap="15px"
                  [ngStyle]="{'width': '100%'}">
                  <div class="family-image-container"
                    *ngIf="layoutProperties.isMobile">
                    <img class="family-image-mobile"
                      src="{{ currentFamily.image[0].url }}">
                  </div>
                  <h3 class="family-title"
                    fxFlex="{{ layoutProperties.isMobile ? '0 1 25%' : '0 1 auto' }}"
                    [ngStyle]="{'font-weight': layoutProperties.isMobile ? '500' : '600'}">Your {{ currentFamily.title }}</h3>
                </div>
                <div class="divider-bar"
                  *ngIf="!layoutProperties.isMobile"></div>
                <p class="family-description"
                  *ngIf="!layoutProperties.isMobile && currentFamily.description !== ''"
                  fxFlex="{{ layoutProperties.isMobile ? '0 1 75%' : '0 1 auto' }}">{{ currentFamily.description }}</p>
              </div>
            </div>
          </div>

          <div [@fade]="currentField.id"
            class="question-card card raised"
            fxFlex="{{ currentField.family === 'intro' ? layoutProperties.isMobile ? '100%' : '67%' : '67%' }}">
            <div class=""
              [ngClass]="{ 'mobile': layoutProperties.isMobile}">

              <div class=""
                [ngClass]="{ 'mobile': layoutProperties.isMobile}"
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="35px">

                <div class="title-container"
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxLayoutGap="25px">
                  <h3 class="question-title"> {{ currentField.title }} </h3>
                  <ng-container *ngIf="currentField.id === 'INTRO2'; else otherCaption">
                    <p class="question-caption">
                      Although our recommendations are thoroughly backed by research, we are not clinical experts in the field of dermatology. Should you choose to implement any of our recommendations, you do so at your own risk.
                      Continuing with the quiz indicates that you accept our <a target="_blank"
                        href="https://glimmer.info/privacy-policy">Terms & Conditions and Privacy Policy.</a>
                    </p>
                  </ng-container>
                  <ng-template #otherCaption>
                    <p class="question-caption"
                      *ngIf="currentField.caption !== ''"> {{ currentField.caption }} </p>
                  </ng-template>
                  <ng-container *ngIf="currentField.instructions">
                    <div class="instruction-bubble"
                      fxLayout="column"
                      fxLayoutAlign="start start"
                      fxLayoutGap="15px">
                      <div *ngFor="let instruction of currentField.instructions"
                        fxLayout="column"
                        fxLayoutAlign="start start"
                        fxLayoutGap="5px">
                        <div fxLayout="row"
                          fxLayoutAlign="start start"
                          fxLayoutGap="10px"
                          [ngStyle]="{'width': '100%'}">
                          <span class="mat-small step"><b>STEP {{ instruction.stepNumber }}: </b></span>
                          <span class="mat-small">{{ instruction.instruction }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="currentField.definitions">
                    <div class="definition-bubble"
                      fxLayout="column"
                      fxLayoutAlign="start start"
                      fxLayoutGap="15px">
                      <div *ngFor="let def of currentField.definitions"
                        fxLayout="column"
                        fxLayoutAlign="start start"
                        fxLayoutGap="5px">
                        <div fxLayout="row"
                          fxLayoutAlign="center center"
                          fxLayoutGap="5px">
                          <mat-icon fxFlexAlign="center">
                            auto_stories
                          </mat-icon>
                          <span class="mat-small"><b>{{ def.wordToDefine }}</b></span>
                        </div>
                        <span class="mat-small">{{ def.definition }}</span>
                      </div>
                    </div>
                  </ng-container>

                  <mat-error class="input-error bold"
                    *ngIf="errorHandler.hasError">
                    {{ errorHandler.errorMsg }}
                  </mat-error>
                </div>

                <div class="field-input-container"
                  *ngIf="currentField.isInput"
                  [ngClass]="{ 'mobile': layoutProperties.isMobile}"
                  [ngSwitch]="currentField.type">
                  <ng-container *ngSwitchCase="'radio'">
                    <app-radio-input [currentField]="currentField"
                      (check)="storeInputValue($event)"
                      isMobile="layoutProperties.isMobile">
                    </app-radio-input>
                  </ng-container>
                  <ng-container *ngSwitchCase="'checkbox'">
                    <div class="form-field checkbox-form-field"
                      fxLayout="column"
                      fxLayoutAlign="start center"
                      fxLayoutGap="35px">
                      <small [ngStyle]="{
                          'width': '100%',
                          'font-weight': '600'}">Choose all that apply</small>
                      <mat-chip-list class="checkbox-group"
                        [multiple]="true">
                        <mat-chip class="checkbox"
                          *ngFor="let option of currentField.options"
                          id="{{ currentField.id + option.value }}"
                          [selected]="currentField.values.includes(option.value)"
                          [selectable]="true"
                          [ngClass]="{'checkbox-checked': currentField.values.includes(option.value)}"
                          (click)="storeInputValue(option.value)"
                          fxLayout="column"
                          fxLayoutAlign="center center"
                          fxLayoutGap="0px">
                          <p class="input-label">{{ option.label }}</p>
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'text'">
                    <div class="form-field text-form-field"
                      fxLayout="column"
                      fxLayoutAlign="start start"
                      fxLayoutGap="35px">
                      <mat-form-field color="none"
                        appearance="outline"
                        [floatLabel]="true">
                        <input matInput
                          id="{{ currentField.id }}"
                          placeholder="{{ currentField.placeholder }}"
                          value="{{ currentField.values[0] }}"
                          (change)="storeInputValue($event.target.value)">
                      </mat-form-field>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'select'">
                    <div class="form-field select-form-field"
                      fxLayout="column"
                      fxLayoutAlign="start start"
                      fxLayoutGap="35px">
                      <mat-form-field color="none"
                        appearance="fill"
                        [floatLabel]="true">
                        <mat-label>Choose one</mat-label>
                        <mat-select (selectionChange)="storeInputValue($event.value)">
                          <mat-option *ngFor="let option of currentField.options"
                            id="{{ currentField.id + option.value }}"
                            [ngClass]="{'select-checked': currentField.values.includes(option.value)}"
                            value="{{ option.value }}">
                            <span class="input-label">{{ option.label }}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    Oops! Something went wrong. Contact contact@glimmer.info for assistance.
                  </ng-container>
                </div>

                <ng-container *ngIf="currentField.id === 'REVIEW'; else next">
                  <div class="">
                    <p><b>Not convinced yet?</b> Check out <a target="_blank"
                        href="https://www.glimmer.info/blog/home/2021/3/14/curlcupid">this blog post</a> to find out why it's well worth the price! (link will open in a new tab)</p>
                    <p class="mat-small">We stand by our product but if you're not satisfied with Curl Cupid™ experience, let us know and we'll give you a 100% refund.</p>
                  </div>
                  <div class="next-container"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px">
                    <button class="submit-button button"
                      mat-raised-button
                      color="accent"
                      (click)="onSubmit()">Purchase Report for $10</button>
                  </div>
                </ng-container>
                <ng-template #next>
                  <div class="next-container"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px"
                    *ngIf="currentField.isInput === false || currentField.values.length > 0">
                    <button class="next-button button"
                      mat-raised-button
                      color="accent"
                      (click)="onNavigateNext()">Next</button>
                    <span class="next-text"
                      *ngIf="!layoutProperties.isMobile">or press <span class="next-text bold">Enter ↵</span></span>
                  </div>
                </ng-template>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="toolbar-container"
        [ngClass]="{ 'mobile': layoutProperties.isMobile }"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="0px"
        [ngStyle]="{
            'padding': layoutProperties.outerPadding
            }">
        <div class="toolbar"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="25px">
          <mat-toolbar class="progress-toolbar raised"
            *ngIf="!layoutProperties.isMobile"
            fxFlex="1 3 66%">
            <div class="full-width"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="0px">
              <!-- <span>{{ progressValue }}% complete</span> -->
              <mat-progress-bar mode="determinate"
                value="{{ progressValue }}"></mat-progress-bar>
            </div>
          </mat-toolbar>
          <mat-toolbar class="quiz-toolbar raised"
            fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 0 33%'}}"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="0px">
            <button mat-stroked-button
              class="toolbar-button first"
              fxFlex="1 1 20%"
              (click)="openResetDialog()">
              <mat-icon>
                close
              </mat-icon>
            </button>
            <button mat-stroked-button
              class="toolbar-button middle"
              fxFlex="1 1 20%"
              (click)="test()"
              *ngIf="adminMode">
              <mat-icon>
                trending_up
              </mat-icon>
            </button>
            <button mat-stroked-button
              class="toolbar-button middle"
              fxFlex="1 1 20%"
              (click)="openResetDialog()">
              <mat-icon>
                refresh
              </mat-icon>
            </button>
            <button mat-stroked-button
              class="toolbar-button middle"
              fxFlex="1 1 20%"
              (click)="onNavigatePrevious()"
              [ngClass]="{ disabled: !isInProgress || isFirstField()}">
              <mat-icon>
                chevron_left
              </mat-icon>
            </button>
            <button mat-stroked-button
              class="toolbar-button last"
              fxFlex="1 1 20%"
              (click)="onNavigateNext()"
              [disabled]="currentField.id === 'REVIEW'"
              [ngClass]="{ disabled: !isInProgress }">
              <mat-icon>
                chevron_right
              </mat-icon>
            </button>
          </mat-toolbar>
        </div>

      </div>

    </div>

  </ng-template>

</page-wrapper>