<div class="navigation-toolbar-container"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="0px"
  *ngIf="layoutProperties$ | async as layoutProperties">

  <mat-toolbar class="navigation-toolbar"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="0px"
    [ngStyle]="{
      'padding-left': layoutProperties.outerPadding.split(' ')[1],
      'padding-right': layoutProperties.outerPadding.split(' ')[1]
     }">
    <ng-container *ngIf="!layoutProperties.isMobile; else mobileBranding">
      <div class="brand-container"
        fxFlex="20%">
        <a class="brand"
          [routerLink]="['/']"
          routerLinkActive="active"
          (click)="analytics.logClickedLinkEvent('navigation_bar', 'brand')"><img src="../assets/images/logo/white-inline-trans.svg"></a>
      </div>
    </ng-container>
    <ng-template #mobileBranding>
      <div class="brand-container"
        fxFlex="100%">
        <a class="brand"
          [routerLink]="['/']"
          routerLinkActive="active"><img src="../assets/images/logo/white-inline-trans.svg"></a>
      </div>
    </ng-template>

    <div class="navigation-link-container"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="{{ layoutProperties.isMobile ? '10px' : '35px' }}"
      fxFlex="75%">
      <ng-container *ngIf="auth.user; else noUser">
        <ng-container *ngIf="!layoutProperties.isMobile">
          <button mat-button
            *ngFor="let link of headerLinks"
            routerLink="{{ link.route }}"
            routerLinkActive="active"
            [disabled]="!auth.user.curlCupidReports && (link.label === 'Products' || link.label === 'Report')"
            (click)="analytics.logClickedLinkEvent('navigation_bar', link.route.split('/')[1])">
            <span fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="5px">
              <mat-icon>{{ link.icon }}</mat-icon>
              <span class="link-label mat-small">{{ link.label }}</span>
            </span>
          </button>
          <button mat-button
            [matMenuTriggerFor]="productsMenu"
            [disabled]="!auth.user.curlCupidReports">
            <span fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="5px">
              <mat-icon>shopping_cart</mat-icon>
              <span class="link-label mat-small">Products</span>
            </span>
          </button>
        </ng-container>

        <button mat-icon-button
          [matMenuTriggerFor]=" menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #productsMenu="matMenu"
          class="menu"
          [ngStyle]="{'margin-right': '0'}">
          <button mat-menu-item
            (click)="analytics.logClickedLinkEvent('navigation_bar', 'products/pantry')"
            routerLink="/products/pantry"
            routerLinkActive="active">
            <mat-icon>receipt_long</mat-icon>
            <span>My Pantry</span>
          </button>
          <button mat-menu-item
            (click)="analytics.logClickedLinkEvent('navigation_bar', 'products/kits')"
            routerLink="/products/kits"
            routerLinkActive="active"
            [disabled]="!auth.user.productKits">
            <mat-icon>dashboard_customize</mat-icon>
            <span>My Kits</span>
          </button>
        </mat-menu>
        <mat-menu #menu="matMenu"
          class="menu"
          [ngStyle]="{'margin-right': '0'}">
          <ng-container *ngIf="layoutProperties.isMobile">
            <button mat-menu-item
              [ngStyle]="{'color': 'black'}"
              *ngFor="let link of headerLinks"
              routerLink="{{ link.route }}"
              routerLinkActive="active"
              [disabled]="!auth.user.curlCupidReports && (link.label === 'Products' || link.label === 'Report')"
              (click)="analytics.logClickedLinkEvent('navigation_menu', link.route.split('/')[1])">
              <mat-icon>{{ link.icon }}</mat-icon>
              <span>{{ link.label }}</span>
            </button>
            <button *ngIf="layoutProperties.isMobile"
              mat-menu-item
              (click)="analytics.logClickedLinkEvent('navigation_bar', 'products/pantry')"
              routerLink="/products/pantry"
              routerLinkActive="active">
              <mat-icon>receipt_long</mat-icon>
              <span>My Pantry</span>
            </button>
            <button *ngIf="layoutProperties.isMobile"
              mat-menu-item
              (click)="analytics.logClickedLinkEvent('navigation_bar', 'products/kits')"
              routerLink="/products/kits"
              routerLinkActive="active">
              <mat-icon>dashboard_customize</mat-icon>
              <span>My Kits</span>
            </button>
          </ng-container>

          <button mat-menu-item
            (click)="onOpenProfile(); analytics.logClickedLinkEvent('navigation_menu', 'my_account')">
            <mat-icon>settings</mat-icon>
            <span>My Account</span>
          </button>

          <button mat-menu-item
            (click)="analytics.logViewedPageEvent('navigation', 'feedback'); openLink('https://airtable.com/shrnsXF5nJ0mfppfB')">
            <mat-icon>help</mat-icon>
            <span>Get help</span>
          </button>

          <button mat-menu-item
            (click)="signOut()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Log Out</span>
          </button>
        </mat-menu>

      </ng-container>

      <ng-template #noUser>
        <ng-container *ngIf="!layoutProperties.isMobile">

          <mat-menu #featureMenu="matMenu"
            class="menu">
            <button mat-menu-item
              [ngxScrollTo]="'#howItWorksSection'">
              <mat-icon>auto_fix_high</mat-icon>
              <span>How It Works</span>
            </button>
            <ng-container *ngFor="let link of headerLinks">
              <button mat-menu-item
                *ngIf="link.feature !== 'home'"
                [ngxScrollTo]="'#' + link.feature + 'Section'">
                <mat-icon>{{ link.icon }}</mat-icon>
                <span>{{ link.label }}</span>
              </button>
            </ng-container>
          </mat-menu>
          <button mat-button
            [matMenuTriggerFor]="featureMenu">
            <span fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="5px">
              <mat-icon>auto_awesome</mat-icon>
              <span>Features</span>
            </span>
          </button>
          <button mat-button
            [ngxScrollTo]="'#pricingSection'">
            <span fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="5px">
              <mat-icon>sell</mat-icon>
              <span>Pricing</span>
            </span>
          </button>
          <button mat-button
            [ngxScrollTo]="'#FAQSection'">
            <span fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="5px">
              <mat-icon>help_center</mat-icon>
              <span>FAQ</span>
            </span>
          </button>
          <button class="button login-button"
            mat-stroked-button
            [routerLink]="['/login']"
            routerLinkActive="active">
            Log In
          </button>
        </ng-container>

        <ng-container *ngIf="layoutProperties.isMobile">
          <button mat-button
            [routerLink]="['/login']"
            routerLinkActive="active">
            Log In
          </button>
          <button mat-icon-button
            [matMenuTriggerFor]="featureMenu">
            <span fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="5px">
              <mat-icon>menu</mat-icon>
            </span>
          </button>
          <mat-menu #featureMenu="matMenu"
            class="menu">
            <button mat-menu-item
              [ngxScrollTo]="'#howItWorksSection'">
              <mat-icon>auto_fix_high</mat-icon>
              <span>How It Works</span>
            </button>
            <ng-container *ngFor="let link of headerLinks">
              <button mat-menu-item
                *ngIf="link.feature !== 'home'"
                [ngxScrollTo]="'#' + link.feature + 'Section'">
                <mat-icon>{{ link.icon }}</mat-icon>
                <span>{{ link.label }}</span>
              </button>
            </ng-container>
            <button mat-menu-item
              [ngxScrollTo]="'#pricingSection'">
              <mat-icon>sell</mat-icon>
              <span>Pricing</span>
            </button>
            <button mat-menu-item
              [ngxScrollTo]="'#FAQSection'">
              <mat-icon>help_center</mat-icon>
              <span>FAQ</span>
            </button>
          </mat-menu>

        </ng-container>
      </ng-template>
    </div>

  </mat-toolbar>
</div>
