import { User } from "./User";
import { LocationData } from "./Report";
import { Timestamp } from "./Timestamp";

export class QuizResults {
  uid: string;
  firstName: string;
  lastName: string;
  createdDate: Timestamp;

  residency: string;
  age: string;
  experienceLevel: string;
  assignedSex: string;
  genderIdentity: string;
  race: string[];
  ancestralBackground: string[];
  zipcode: string;
  curlPattern: string;
  strandThickness: string;
  porosity: string;
  hairLength: string;
  hairDensity: string;
  heatTransformation: string;
  chemicalTransformation: string[];
  currentState: string;
  physicalTransformation: string;
  routine: string[];
  scalpAndHairHealth: string[];
  preExistingConditionsAndHealth: string[];
  values: any;
  unfoundKeys: Object[] = [];
  locationData: LocationData;

  sections = [
    "experienceLevel",
    "residency",
    "age",
    "assignedSex",
    "genderIdentity",
    "race",
    "ancestralBackground",
    "zipcode",
    "curlPattern",
    "strandThickness",
    "porosity",
    "hairLength",
    "hairDensity",
    "heatTransformation",
    "chemicalTransformation",
    "currentState",
    "physicalTransformation",
    "routine",
    "scalpAndHairHealth",
    "preExistingConditionsAndHealth",
  ];

  constructor(results?: any) {
    if (results) Object.assign(this, results);
  }

  public parseQuizResults(
    values: any,
    answerKey: {
      quizAnswer: string;
      recommendationTerm: string;
      section: string;
    },
    user?: User,
    uid?: string,
    displayName?: string
  ): QuizResults {

    let _user: User | {
      uid: string,
      displayName: string,
    } = user ? user : {
      uid: uid || "",
      displayName: displayName || '',
    }

    this.uid = _user.uid;
    this.firstName = _user.displayName.split(' ')[0];
    this.lastName = _user.displayName.split(' ')[1];
    this.createdDate = new Timestamp(new Date());
    this.values = values;

    const valuesToMap = this.prepareValuesToMap(values);

    this.sections.forEach(section => {
      let recommendationTerm;
      let key;
      switch (section) {
        case "zipcode":
        case "age":
        case "residency":
          this[section] = valuesToMap[section][0];
          break;
        case "experienceLevel":
        case "ancestralBackground":
          this[section] = valuesToMap[section];
          break;
        case "physicalTransformation":
        case "porosity":
          key = valuesToMap[section].join(" + ");
          recommendationTerm = this.mapSingleValuesToAnswerKey(
            key,
            section,
            answerKey
          );
          let recommendationTermTemp;
          if (recommendationTerm === "Porosity: Error") {
            if (values.P3[0] === "P3a") recommendationTermTemp = "Porosity: Low";
            if (values.P3[0] === "P3b") recommendationTermTemp = "Porosity: Normal";
            if (values.P3[0] === "P3c") recommendationTermTemp = "Porosity: High";
          }
          recommendationTerm = recommendationTermTemp || recommendationTerm;
          this[section] = recommendationTerm;
          break;
        case "hairDensity":
          key = valuesToMap[section].join(" + ");
          recommendationTerm = this.mapSingleValuesToAnswerKey(
            key,
            section,
            answerKey
          );
          this.hairDensity = recommendationTerm;
          break;
        case "heatTransformation":
          key = valuesToMap[section]
            .map(value => value)
            .filter(value => value)
            .join(" + ");
          this.heatTransformation = this.mapSingleValuesToAnswerKey(
            key,
            section,
            answerKey
          );
          const symptomCount = values.HM5 ?.length;
          this.heatTransformation = this.heatTransformationOverride(symptomCount || 0, this.heatTransformation);
          break;
        case "routine":
        case "race":
        case "chemicalTransformation":
        case "preExistingConditionsAndHealth":
        case "scalpAndHairHealth":
          this[section] = this.mapMultipleValuesToAnswerKey(
            valuesToMap[section],
            section,
            answerKey
          );
          break;
        default:
          this[section] = this.mapSingleValuesToAnswerKey(
            valuesToMap[section][0],
            section,
            answerKey
          );
          break;
      }
    });
    return this;
  }

  private mapSingleValuesToAnswerKey(
    key: string,
    section: string,
    answerKey: {
      quizAnswer: string;
      recommendationTerm: string;
      section: string;
    }
  ): string {
    let recommendationTermToReturn = answerKey[key] ?.recommendationTerm;
    if (!recommendationTermToReturn) {
      this.throwKeyError(key, section);
    } else {
      recommendationTermToReturn = recommendationTermToReturn.trim();
    }
    return recommendationTermToReturn;
  }

  private mapMultipleValuesToAnswerKey(
    keys: any,
    section: string,
    answerKey: {
      quizAnswer: string;
      recommendationTerm: string;
      section: string;
    }
  ): string[] {
    var recommendationTermsToReturn = keys
      .map((key: string) => {
        let recommendationTerm = answerKey[key] ?.recommendationTerm;
        if (!recommendationTerm) {
          this.throwKeyError(key, section);
        } else {
          recommendationTerm = recommendationTerm.trim();
        }
        return recommendationTerm;
      })
      .filter((value: string) => {
        return value;
      });
    return recommendationTermsToReturn;
  }

  private prepareValuesToMap(values: any): any {
    let valuesToMap = {
      experienceLevel: values.experienceLevel,
      residency: [values.D1 || []],
      age: values.D2 || [],
      assignedSex: [values.D3 || []],
      genderIdentity: [values.D4 || []],
      race: values.D5 || [],
      ancestralBackground: values.D6 || [],
      zipcode: values.EF1 || [],
      curlPattern: [values.CP1 || []],
      strandThickness: [values.TH1 || []],
      porosity: [values.P1 || [], values.P2 || [], values.P3 || []],
      hairLength: [values.HL1 || []],
      hairDensity: [values.HD1 || [], values.HD2 || []],
      heatTransformation: [
        values.HM1 || [],
        values.HM2 || [],
        values.HM3 || [],
        values.HM4 || [],
      ], // TODO .concat(values.HM5 || [])
      chemicalTransformation: values.CM1 || [],
      currentState: [values.PM1 || []],
      physicalTransformation: [values.PS1 || [], values.PS2 || []],
      routine: [].concat(values.R1 || [], values.R2 || []),
      scalpAndHairHealth: [].concat(values.SH1 || [], values.SH2 || []),
      preExistingConditionsAndHealth: [].concat(
        values.CH1 || [],
        values.CH2 || [],
        values.CH3 || [],
        values.CH4 || [],
        values.CH5 || []
      ),
    };

    Object.keys(valuesToMap).forEach(section => {
      let sectionValues = valuesToMap[section];
      if (typeof sectionValues === "object") {
        sectionValues = sectionValues.filter((value: any) => {
          return value !== undefined && value !== "" && value.length > 0;
        })
        valuesToMap[section] = sectionValues;
      }
      else {
        valuesToMap[section] = sectionValues;
      }
    })
    return valuesToMap;
  }

  private heatTransformationOverride(symptomCount: number, value: string): string {
    if (symptomCount > 1) {
      switch (value) {
        case "Heat Damage: Not Likely":
          switch (symptomCount) {
            case 0:
              return "Heat Damage: Not Likely";
            case 1:
              return "Heat Damage: Less Likely";
            default:
              return "Heat Damage: Likely";
          }
        case "Heat Damage: Less Likely":
          switch (symptomCount) {
            case 0:
              return "Heat Damage: Less Likely";
            default:
              return "Heat Damage: Likely";
          }
        default:
          return "Heat Damage: Likely";
      }
    }
    else {
      return value;
    }
  }

  private throwKeyError(key: string, section: string): void {
    this.unfoundKeys.push({
      "section": section,
      "key": key,
    });
    console.error(
      `Unable to map quiz value in ${section} to answer key: ${key}`
    );
  }

  public parse(data: any): QuizResults {
    Object.assign(this, data);
    return this;
  }
}
