<div class=""
  *ngIf="layoutProperties$ | async as layoutProperties">
  <div class="gray-background"></div>
  <button mat-button
    [@fade]="!loading.loading$"
    class="back-button alt-button"
    [routerLink]="['/welcome']"
    routerLinkActive="active">
    <div fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="15px">
      <mat-icon>
        arrow_back_ios
      </mat-icon>
      <span>Back to Home</span>
    </div>
  </button>
  <div class="container"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="35px">
    <a [routerLink]="['/']"
      routerLinkActive="active"> <img class="logo"
        src="/assets/images/logo/logo-filled-circle.svg" /></a>
    <ng-container>
      <h2 *ngIf="!displaySignup">Log in to Curl Cupid™</h2>
      <h2 *ngIf="displaySignup">Sign up for Curl Cupid™</h2>
      <mat-divider></mat-divider>
      <h3 class="header">See visible results in your hair today with Curl Cupid™.</h3>
      <ng-container *ngIf="loading.loading$ | async; else pageLoaded">
        <div class="loading-container">
          <app-loading></app-loading>
        </div>
      </ng-container>
      <ng-template #pageLoaded>
        <form class="form"
          [formGroup]="logInForm"
          (ngSubmit)="onLogInSubmit()"
          *ngIf="!displaySignup">
          <div class="form-field-group"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="0px">
            <mat-form-field class="form-field"
              color="secondary"
              appearance="outline"
              [floatLabel]="true">
              <mat-label class="form-label">Email</mat-label>
              <input class="form-input"
                matInput
                formControlName="email"
                type="email"
                placeholder="email@example.com">
              <mat-error class="form-error"
                *ngIf="logInForm.controls['email'].hasError('email')">
                Please enter a valid email address
              </mat-error>
              <mat-error class="form-error"
                *ngIf="logInForm.controls['email'].hasError('required')">
                An email is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form-field"
              color="secondary"
              appearance="outline"
              [floatLabel]="true">
              <mat-label class="form-label">Password</mat-label>
              <input class="form-input"
                matInput
                type="password"
                formControlName="pwd">
              <mat-error class="form-error"
                *ngIf="logInForm.controls['pwd'].hasError('required')">
                Please enter a password
              </mat-error>
              <mat-error class="form-error"
                *ngIf="logInForm.controls['pwd'].hasError('minlength')">
                Your password must be at least 8 characters
              </mat-error>
            </mat-form-field>
            <div class="full-width"
              fxLayout="column"
              fxLayoutAlign="start center"
              fxLayoutGap="15px">
              <button class="button form login-button"
                mat-raised-button
                color="accent"
                type="submit"
                [disabled]="!logInForm.valid">
                Log In
              </button>
              <button class="button form login-button"
                mat-stroked-button
                color="accent"
                type="button"
                (click)="logInWithGoogle()">
                <div fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="15px">
                  <mat-icon class="provider-icon"
                    svgIcon="google"></mat-icon><span class="login-with-google">Log in with Google</span>
                </div>
              </button>

            </div>
          </div>
        </form>
      </ng-template>

    </ng-container>

    <div *ngIf="displaySignup"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="25px">
      <p class="mat-small"><b>How do you want to sign up?</b></p>
      <div fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="full-width">

        <button class="button full-width"
          mat-stroked-button
          color="accent"
          (click)="onSignupWithEmail()"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="15px">
          <div fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="15px">
            <mat-icon class="provider-icon">email</mat-icon>
            <span>Sign up with email</span>
          </div>
        </button>
        <button class="button full-width"
          mat-stroked-button
          color="accent"
          (click)="onSignupWithGoogle()"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="15px">
          <div fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="15px">
            <mat-icon class="provider-icon"
              svgIcon="google"></mat-icon>
            <span>Sign up with Google</span>
          </div>
        </button>
      </div>
    </div>
    <div class="full-width"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="15px">
      <ng-container *ngIf="loading.loading$ | async; else altButtonsLoaded">
      </ng-container>
      <ng-template #altButtonsLoaded>
        <button mat-button
          class="alt-button mat-caption"
          (click)="toggleSignup()"
          *ngIf="!displaySignup">Sign Up</button>
        <button mat-button
          *ngIf="displaySignup"
          class="alt-button mat-caption"
          (click)="toggleSignup()">Already have an account?</button>
        <button mat-button
          class="alt-button mat-caption"
          (click)="onPasswordReset()">Trouble logging in?</button>
      </ng-template>

    </div>

  </div>
</div>