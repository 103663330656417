<div class="container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  *ngIf="layoutProperties$ | async as layoutProperties"
  fxLayoutGap="25px">

  <p class="subsection-header"><b>WELCOME</b></p>

  <key-point class="letter-container"
    [layoutProperties$]="layoutProperties$"
    [backgroundColor]="gradient"
    [raised]="true"
    *ngIf="layoutProperties$ | async as layoutProperties">
    <h1 class="h1">{{ report.results.firstName }}, welcome to your Curl Cupid™ Report!</h1>
    <p>Thank you for taking the Curl Cupid™ Quiz. Your hair is important to us, and we recognize that taking care of it can sometimes be a challenge without first knowing its needs.</p>
    <p>The Curl Cupid™ Quiz was created to get you better acquainted with your hair through an individual profile. We want to help you get started (or continue) your healthy hair journey.</p>
    <p>The Curl Cupid™ Quiz considers numerous factors to build your individual hair profile. These factors include hair properties, effects, transformations, health, and environmental factors. We analyzed your quiz responses to provide you
      with
      recommendations that were meant for YOUR unique hair. We hope our recommendations help you get to know your hair and how to care for it.</p>
    <p>Thank you for making us a part of your journey!</p>
    <p>With care, <br>The Glimmer 411 Team</p>
    <div class="brand-container">
      <a class="brand"><img src="../../../asse../../../assets/images/logo/gradient-inline-trans.svg"></a>
    </div>
  </key-point>
</div>