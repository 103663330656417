<div class="container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  *ngIf="layoutProperties$ | async as layoutProperties"
  fxLayoutGap="{{ layoutProperties.spacing }}">

  <p class="subsection-header"><b>INTRODUCTION</b></p>

  <key-point [layoutProperties$]="layoutProperties$"
    [backgroundColor]="gradient"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="10px">
    <h1 class="h1">Hair Properties</h1>
    <p>There are a lot of things to consider when getting to know your hair. What is your curl pattern and strand thickness? Do you know your porosity and hair density? Have you considered how your hair length could affect your hair care?</p>
    <p class="">If you’ve never asked yourself these questions or you don’t have the answers to these questions, don’t worry. We’ve got you covered! Here’s the 411 on your hair properties.</p>
  </key-point>

  <div [ngStyle]="{'width': '100%'}">
    <mat-tab-group dynamicHeight
      mat-stretch-tabs
      class="blue"
      [ngStyle]="{'width': '100%'}">
      <mat-tab label="Curl Pattern">
        <div fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="{{ layoutProperties.spacing }}">
          <p class="subsection-header"><b>ABOUT YOUR CURL PATTERN</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]='true'>
            <span class="h1">Curl Pattern</span>
            <div fxLayout="row"
              class="definition-bubble"
              fxLayoutAlign="center start"
              fxLayoutGap="15px">
              <p class="">Curl pattern is the natural shape of your hair strands as they grow. <b>Your hair follicle’s shape determines what curl pattern you’ll have.</b> Follicles that are round and circular lead to straighter hair types whereas
                flatter
                and
                more oval-shaped follicles lead to curlier hair types.</p>
            </div>
          </key-point>

          <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
            fxLayoutAlign="start stretch"
            fxLayoutGap="{{ layoutProperties.spacing }}">

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px">
                <mat-icon class='blue-text'>
                  stars
                </mat-icon>
                <span class="h3 blue-text">The 411: Keeping It Real</span>
              </div>
              <p class="">We’ve found that Curl pattern can be affected in three major ways: <b>curl elasticity, curl formation</b> and <b>curl retention.</b> In the section below we will go into detail about interactions that can affect your curl
                pattern
                and make it act or look differently.</p>
            </key-point>

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px">
                <span class="h3">Your Curl Pattern</span>
                <p class="h2 blue-text"><b>{{ report.hairProperties.curlPattern.narrative.CP1.data }}</b></p>
              </div>
              <p class="">{{report.results.curlPattern + " hair " +  report.hairProperties.curlPattern.narrative.CP1D.data?.description }}</p>
            </key-point>

          </div>

          <p class="subsection-header"><b>WHAT WE RECOMMEND</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]="false">
            <p>When taking care of your hair there are several factors you need to consider. They are the factors that you can control, and the factors that you can’t.</p>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'"
            class="hair-type">
            <p class="h3">In Your Control</p>
            <p class="h2 blue-text"><b>Chemical Transformation + Heat Transformation</b></p>
            <p>You are {{ report.results.heatTransformation?.split(': ')[1].toLowerCase() }} to have heat damage and have applied/apply the following chemical transformations to your hair:</p>
            <ul>
              <li *ngFor="let transformation of report.results.chemicalTransformation">{{ transformation.split('Chemical Transformation: ')[1] }}</li>
            </ul>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.curlPattern.inYourControl.CP1_ET1_ET2R.data?.recommendation; else noRecommendation1">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p class="">{{ report.hairProperties.curlPattern.inYourControl.CP1_ET1_ET2R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation1>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'">
            <p class="h3">Out of Your Control</p>
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="0px">
              <p class="h2 blue-text"><b>{{ report.results.hairDensity }}</b></p>
            </div>
            <p>You don’t have direct control over what your hair density is, but you need to consider its effects on your curl pattern for better hair care practices.</p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.curlPattern.outOfYourControl.CP1_HD1R.data?.recommendation; else noRecommendation2">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p>{{ report.hairProperties.curlPattern.outOfYourControl.CP1_HD1R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation2>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'">
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="0px">
              <p class="h2 blue-text"><b>Technique Recommendations</b></p>
            </div>
            <p>Technique recommendations are a compilation of the best technique methods for your curls. We share tips and tricks to help you make the best of your products and your hair characteristics to improve your styling results.</p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Technique Recommendations
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.curlPattern.inYourControl.CP1_ET1_ET2R.data?.techniques; else noCPTechniques">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.curlPattern.inYourControl.CP1_ET1_ET2R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-template #noCPTechniques>
                  <span class="mat-small">No technique recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>
        </div>
      </mat-tab>

      <mat-tab label="Strand Thickness">
        <div fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="{{ layoutProperties.spacing }}">
          <p class="subsection-header"><b>ABOUT YOUR STRAND THICKNESS</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]='true'>
            <span class="h1">Strand Thickness</span>
            <div fxLayout="row"
              class="definition-bubble"
              fxLayoutAlign="center start"
              fxLayoutGap="15px">
              <p class="">Strand thickness is the measurement of the width of a single hair strand. Not to be confused with hair density, strand thickness is a <b>key determinant of the strength</b> of your individual hair strands, thus your hair.
              </p>
            </div>
          </key-point>

          <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
            fxLayoutAlign="start stretch"
            fxLayoutGap="{{ layoutProperties.spacing }}">

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '100%' : '50%' }}">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px">
                <mat-icon class='blue-text'>
                  stars
                </mat-icon>
                <span class="h3 blue-text">The 411: Keeping It Real</span>
              </div>
              <p class="">We’ve found that strand thickness cannot be changed but <b>certain choices and natural occurrences can make your strands weaker or stronger over time</b>. We’ve found that oftentimes, changes in one hair characteristic bleed
                into
                another,
                changing the way your hair looks and feels.</p>
            </key-point>

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px">
                <span class="h3">Your Strand Thickness</span>
                <p class="h2 blue-text"><b>{{ report.hairProperties.strandThickness.narrative.ST1.data?.split(': ')[1] }}</b></p>
              </div>
              <p class="">{{ report.hairProperties.strandThickness.narrative.ST1D.data?.description || errorMessage }}</p>
            </key-point>

          </div>

          <p class="subsection-header"><b>WHAT WE RECOMMEND</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]="false">
            <p>When taking care of your hair there are several factors you need to consider. They are the factors that you can control, and the factors that you can’t.</p>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'"
            class="hair-type">
            <p class="h3">In Your Control</p>
            <p class="h2 blue-text"><b>{{ report.results.currentState }}</b></p>
            <p>With <b>{{ report.results.strandThickness?.split(': ')[1].toLowerCase() }} hair</b>, you need to consider how your styling choices could be affecting your hair especially since your hair is in
              {{ report.results.currentState?.split('Current State: ')[1] }}. </p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.strandThickness.inYourControl.ST1_ET4R.data?.recommendation; else noRecommendation3">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p>{{ report.hairProperties.strandThickness.inYourControl.ST1_ET4R.data?.recommendation }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation3>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'">
            <p class="h3">Out of Your Control</p>
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="0px">
              <p class="h2 blue-text"><b>{{ report.results.hairDensity }}</b></p>
            </div>
            <p>You don’t have direct control over what your hair density is, but you need to consider its effects on your curl pattern for better hair care practices.</p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.strandThickness.outOfYourControl.ST1_HL1R.data?.recommendation; else noRecommendation3">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p>{{ report.hairProperties.strandThickness.outOfYourControl.ST1_HL1R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation3>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'">
            <p class="h2 blue-text"><b>Technique Recommendations</b></p>
            <p>Technique recommendations are a compilation of the best technique methods for your curls. We share tips and tricks to help you make the best of your products and your hair characteristics to improve your styling results.
            </p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Technique Recommendations
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.strandThickness.outOfYourControl.ST1_HL1R.data?.techniques; else noSTTechniques1">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.strandThickness.outOfYourControl.ST1_HL1R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="report.hairProperties.strandThickness.inYourControl.ST1_ET4R.data?.techniques">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.strandThickness.inYourControl.ST1_ET4R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-template #noSTTechniques1>
                  <span class="mat-small">No technique recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>
        </div>
      </mat-tab>

      <mat-tab label="Porosity">
        <div fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="{{ layoutProperties.spacing }}">
          <p class="subsection-header"><b>ABOUT YOUR POROSITY</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]='true'>
            <span class="h1">Porosity</span>
            <div fxLayout="row"
              class="definition-bubble"
              fxLayoutAlign="center start"
              fxLayoutGap="15px">
              <p class=""><b>Porosity is a measure of how well your hair absorbs and retains moisture.</b> Porosity is determined by how tightly bound your hair cuticles are. The tighter your cuticles are, the closer to low porosity your hair is;
                meaning
                your hair does not absorb moisture well, but retains it well. The looser your cuticles are, the closer to high porosity your hair is; meaning your hair absorbs moisture well, but doesn’t retain it well.</p>
            </div>
          </key-point>

          <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
            fxLayoutAlign="start stretch"
            fxLayoutGap="{{ layoutProperties.spacing }}">

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px">
                <mat-icon class='blue-text'>
                  stars
                </mat-icon>
                <span class="h3 blue-text">The 411: Keeping It Real</span>
              </div>
              <p class=""><b>Porosity is one of THE most important hair properties</b> that can make or break your entire hair experience. It’s easy to diagnose and easy to pinpoint it’s influences. It can only be altered by chemical or heat
                transformations
                but once it’s been altered it’s difficult to revert without starting over.</p>
            </key-point>

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px">
                <span class="h3">Your Porosity</span>
                <p class="h2 blue-text"><b>{{ report.results.porosity }}</b></p>
              </div>
              <p class="">{{ report.hairProperties.porosity.narrative.P1D.data?.description || errorMessage }}</p>
            </key-point>
          </div>

          <p class="subsection-header"><b>WHAT WE RECOMMEND</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]="false">
            <p>When taking care of your hair there are several factors you need to consider. They are the factors that you can control, and the factors that you can’t.</p>
          </key-point>


          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'"
            class="hair-type">
            <p class="h3">In Your Control</p>
            <p class="h2 blue-text"><b>Chemical Transformation & Heat Transformation</b></p>
            <p>You are {{ report.results.heatTransformation?.split('Heat Damage: ')[1].toLowerCase() }} to have heat damage and apply or have applied the following chemical transformations to your hair: </p>
            <ul>
              <li *ngFor="let transformation of report.results.chemicalTransformation">{{ transformation?.split('Chemical Transformation: ')[1] }}</li>
            </ul>
            <p>Your porosity in mind, these transformations can cause some new interactions.</p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.porosity.inYourControl.P1_ET1_ET2R.data?.recommendation; else noRecommendation3">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p>{{ report.hairProperties.porosity.inYourControl.P1_ET1_ET2R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation3>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'">
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="0px">
              <p class="h2 blue-text"><b>Technique Recommendations</b></p>
            </div>
            <p>Technique recommendations are a compilation of the best technique methods for your curls. We share tips and tricks to help you make the best of your products and your hair characteristics to improve your styling results.
            </p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Technique Recommendations
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.porosity.inYourControl.P1_ET1_ET2R.data?.techniques; else noPTechniques2">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.porosity.inYourControl.P1_ET1_ET2R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-template #noPTechniques2>
                  <span class="mat-small">No technique recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>
        </div>
      </mat-tab>

      <mat-tab label="Hair Length">
        <div fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="{{ layoutProperties.spacing }}">
          <p class="subsection-header"><b>ABOUT YOUR HAIR LENGTH</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]='true'>
            <span class="h1">Hair Length</span>
            <div fxLayout="row"
              class="definition-bubble"
              fxLayoutAlign="center start"
              fxLayoutGap="15px">
              <p class="">Hair length is, simply, how long your hair is. Your hair length is determined by how fast your hair grows, how well you can retain your length, and your personal styling choices.</p>
            </div>
          </key-point>

          <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
            fxLayoutAlign="start stretch"
            fxLayoutGap="{{ layoutProperties.spacing }}">

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px">
                <mat-icon class='blue-text'>
                  stars
                </mat-icon>
                <span class="h3 blue-text">The 411: Keeping It Real</span>
              </div>
              <p class=""><b>Your hair length is a reflection of your hair’s health.</b> Your hair’s length is highly influenced by styling choices (how we manipulate and care for our hair) as well as underlying scalp or hair symptoms (things that
                may
                be
                happening to our hair).</p>
            </key-point>

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px">
                <span class="h3">Your Hair Length</span>
                <p class="h2 blue-text"><b>{{ report.results.hairLength?.split('Hair Length: ')[1] }}</b></p>
              </div>
              <p class="">{{ report.hairProperties.hairLength?.narrative.HL1D.data?.description || errorMessage }}</p>
            </key-point>
          </div>

          <p class="subsection-header"><b>WHAT WE RECOMMEND</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]="false">
            <p>When taking care of your hair there are several factors you need to consider. They are the factors that you can control, and the factors that you can’t.</p>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'"
            class="hair-type">
            <p class="h3">In Your Control</p>
            <p class="h2 blue-text"><b>Physical Transformation + Heat Transformation + Current State + Chemical Transformation</b></p>
            <p>You are {{ report.results.heatTransformation?.split('Heat Damage: ')[1].toLowerCase() }} to have heat damage and your hare is in {{ report.results.currentState?.toLowerCase() }}. You also apply or have applied the following chemical
              transformations to your hair: </p>
            <ul>
              <li *ngFor="let transformation of report.results.chemicalTransformation">{{ transformation.split('Chemical Transformation: ')[1] }}</li>
            </ul>
            <p>Given these factors, we recommend the following: </p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.hairLength.inYourControl.HL1_ET1_ET3_ET4_ET2R.data?.recommendation; else noRecommendation1">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p class="">{{ report.hairProperties.hairLength.inYourControl.HL1_ET1_ET3_ET4_ET2R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation1>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'"
            class="hair-type">
            <p class="h3">In Your Control</p>
            <p class="h2 blue-text"><b>Scalp & Hair Health</b></p>
            <p>You indicated that you are experience the following symptoms:</p>
            <ul>
              <li *ngFor="let symptom of report.results.scalpAndHairHealth">{{ symptom.split('Scalp Health: ')[1] }}</li>
            </ul>
            <p>These symptoms in combination with your hair length can cause some interesting interactions. To help ease some of these symptoms, we recommend you do a few things.</p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.hairLength.inYourControl.HL1_H1R.data?.recommendation; else noRecommendation1">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p class="">{{ report.hairProperties.hairLength.inYourControl.HL1_H1R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation1>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'">
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="0px">
              <p class="h2 blue-text"><b>Technique Recommendations</b></p>
            </div>
            <p>Technique recommendations are a compilation of the best technique methods for your curls. We share tips and tricks to help you make the best of your products and your hair characteristics to improve your styling results.
            </p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Technique Recommendations
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.hairLength.inYourControl.HL1_H1R.data?.techniques; else noHLTechniques2">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.hairLength.inYourControl.HL1_H1R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="report.hairProperties.hairLength.inYourControl.HL1_ET1_ET3_ET4_ET2R.data?.techniques">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.hairLength.inYourControl.HL1_ET1_ET3_ET4_ET2R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-template #noHLTechniques2>
                  <span class="mat-small">No technique recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>
        </div>
      </mat-tab>

      <mat-tab label="Hair Density">
        <div fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="{{ layoutProperties.spacing }}">
          <p class="subsection-header"><b>ABOUT YOUR HAIR DENSITY</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]='true'>
            <span class="h1">Hair Density</span>
            <div fxLayout="row"
              class="definition-bubble"
              fxLayoutAlign="center start"
              fxLayoutGap="15px">
              <p class="">Hair density is a measure of how many individual hair strands you have on your scalp. Your hair density informs how thick (or thin) your hair appears to be. In other words, it is determined by the proximity of your hair
                strands
                to
                each other on your scalp. It’s impossible to count each of your individual hair strands (nobody has time for that!), but there are other ways of determining your hair density.</p>
            </div>
          </key-point>

          <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
            fxLayoutAlign="start stretch"
            fxLayoutGap="{{ layoutProperties.spacing }}">

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px">
                <mat-icon class='blue-text'>
                  stars
                </mat-icon>
                <span class="h3 blue-text">The 411: Keeping It Real</span>
              </div>
              <p class="">As we age, manipulate and train our hair, <b>hair density can change depending on the quality of care we provide our tresses.</b> Long term hair care choices, physical and/or chemical manipulations have the potential to
                increase
                or
                decrease our hair’s density, depending on whether or not we provide the hair care essentials.</p>
            </key-point>

            <key-point [layoutProperties$]="layoutProperties$"
              [backgroundColor]="'white'"
              fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px">
                <span class="h3">Your Hair Density</span>
                <p class="h2 blue-text"><b>{{ report.results.hairDensity?.split('Hair Density: ')[1] }}</b></p>
              </div>
              <p class="">{{ report.hairProperties.hairDensity.narrative.HD1D.data?.description || errorMessage }}</p>
            </key-point>
          </div>

          <p class="subsection-header"><b>WHAT WE RECOMMEND</b></p>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="gradient"
            [fullGradient]="false">
            <p>When taking care of your hair there are several factors you need to consider. They are the factors that you can control, and the factors that you can’t.</p>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'"
            class="hair-type">
            <p class="h3">In Your Control</p>
            <p class="h2 blue-text"><b>Scalp & Hair Health</b></p>
            <p>You indicated that you are experience the following symptoms:</p>
            <ul>
              <li *ngFor="let symptom of report.results.scalpAndHairHealth">{{ symptom.split('Scalp Health: ')[1] }}</li>
            </ul>
            <p>They may not seem connected, but scalp and hair symptoms can occur when you don’t account for your hair’s density. Here's a bit of advice we give to people with hair like yours:</p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.hairDensity.inYourControl.HD1_H1R.data?.recommendation; else noRecommendation1">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p class="">{{ report.hairProperties.hairDensity.inYourControl.HD1_H1R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation1>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'"
            class="hair-type">
            <p class="h3">In Your Control</p>
            <p class="h2 blue-text"><b>Current State + Physical Transformation + Chemical Transformation</b></p>
            <p>You apply or have applied the following chemical transformations to your hair:</p>
            <ul>
              <li *ngFor="let transformation of report.results.chemicalTransformation">{{ transformation.split('Chemical Transformation: ')[1] }}</li>
            </ul>
            <p>Your hair is also in {{ report.hairProperties.hairDensity.inYourControl.ET4.data?.split('Current State: ')[1] }} and you apply
              {{ report.hairProperties.hairDensity.inYourControl.ET3.data?.split('Physical Transformation: ')[1] }} to your hair. Here's what we recommend:</p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Recommendation
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.hairDensity.inYourControl.HDET2_ET4_ET3R.data?.recommendation; else noRecommendation1">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px">
                    <mat-icon class='blue-text'>
                      vpn_key
                    </mat-icon>
                    <p class="">{{ report.hairProperties.hairDensity.inYourControl.HDET2_ET4_ET3R.data?.recommendation || errorMessage }}</p>
                  </div>
                </ng-container>
                <ng-template #noRecommendation1>
                  <span class="mat-small">No recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>

          <key-point [layoutProperties$]="layoutProperties$"
            [backgroundColor]="'white'">
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="0px">
              <p class="h2 blue-text"><b>Technique Recommendations</b></p>
            </div>
            <p>Technique recommendations are a compilation of the best technique methods for your curls. We share tips and tricks to help you make the best of your products and your hair characteristics to improve your styling results.
            </p>
            <mat-accordion [displayMode]="'flat'"
              [multi]="true">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-title">
                    View Technique Recommendations
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngIf="report.hairProperties.hairDensity.inYourControl.HDET2_ET4_ET3R.data?.techniques; else noHDTechniques2">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.hairDensity.inYourControl.HDET2_ET4_ET3R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="report.hairProperties.hairDensity.inYourControl.HD1_H1R.data?.techniques">
                  <div fxLayout="row"
                    fxLayoutAlign="start start"
                    fxLayoutGap="15px"
                    *ngFor="let technique of report.hairProperties.hairDensity.inYourControl.HD1_H1R.data?.techniques">
                    <mat-icon class='blue-text'>
                      lens_blur
                    </mat-icon>
                    <p>{{ technique }}</p>
                  </div>
                </ng-container>
                <ng-template #noHDTechniques2>
                  <span class="mat-small">No technique recommendations to display at this time.</span>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </key-point>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>