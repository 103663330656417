<div class=""
  *ngIf="layoutProperties$ | async as layoutProperties">
  <div class="form-field radio-form-field"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="35px">
    <ng-container *ngIf="currentField.hasInputIcons; else radiosWithoutIcons">

      <mat-grid-list class="radio-group"
        cols="{{ layoutProperties.isMobile ? '2' : '3' }}"
        rowHeight="15rem"
        gutterSize="15px"
        [ngClass]="{
      'has-input-icons': currentField.hasInputIcons
      }">
        <mat-grid-tile [colspan]="1"
          [rowspan]="1"
          *ngFor="let option of currentField.options"
          class="radio"
          id="{{ currentField.id + option.value }}"
          [ngClass]="{
                        'checked': currentField.values.includes(option.value)
                      }">
          <div class="inner-container"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
            (click)="onCheck(option.value)">
            <img class="input-icon"
              *ngIf="option.icon !== null"
              src="{{ currentField.inputIcons[currentField.options.indexOf(option)].url }}">
            <p class="input-label">{{ option.label }}</p>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-container>
    <ng-template #radiosWithoutIcons>
      <ng-container *ngIf="currentField.options.length < 4; else regularRadio">
        <mat-grid-list class="radio-group"
          cols="{{ layoutProperties.isMobile ? '2' : '3' }}"
          rowHeight="{{ layoutProperties.isMobile ? '10rem' : '15rem' }}"
          gutterSize="15px"
          [ngClass]="{
        'has-input-icons': true
        }">
          <mat-grid-tile [colspan]="1"
            [rowspan]="1"
            *ngFor="let option of currentField.options"
            class="radio"
            id="{{ currentField.id + option.value }}"
            [ngClass]="{
                          'checked': currentField.values.includes(option.value)
                        }">
            <div class="inner-container"
              fxLayout="column"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
              (click)="onCheck(option.value)"
              matRipple>
              <p class="input-label no-icon">{{ option.label }}</p>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-container>
      <ng-template #regularRadio>
        <mat-radio-group class="radio-group"
          fxLayout="column"
          fxLayoutGap="20px">
          <mat-radio-button color="accent"
            class="radio"
            *ngFor="let option of currentField.options"
            id="{{ currentField.id + option.value }}"
            [value]="option.value"
            [checked]="currentField.values.includes(option.value)"
            (click)="onCheck(option.value)">
            <span class="input-label">
              {{ option.label }}
            </span>
          </mat-radio-button>
        </mat-radio-group>
      </ng-template>
    </ng-template>
  </div>
</div>