import { Component, OnInit, Input } from '@angular/core';
import { MediaObserverService, LayoutProperties } from "../../../services/media-observer/media-observer.service";
import { Observable } from 'rxjs';
import { Product } from '../../../../../functions/src/models/Product';
import { Report } from '@app/models/Report';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { fade } from '../../../animations/fade.animation';

@Component({
  selector: 'product-page',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.less'],
  animations: [fade]
})
export class ProductComponent implements OnInit {
  @Input() product: Product;
  @Input() report$: Observable<Report>;
  layoutProperties$: Observable<LayoutProperties>;
  href: string;

  constructor(
    private layoutObserver: MediaObserverService,
    public analytics: AnalyticsService
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.href = window.location.href;
  }

  ngOnInit(): void {
    this.analytics.logViewedProductEvent(this.product);
  }

  public viewProductPurchasePage(url: string) {
    this.analytics.logClickedProductButtonEvent(this.product);
    window.open(url);
  }
}
