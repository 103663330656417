<div class="key-point-container"
  fxLayout="row"
  fxLayoutAlign="start stretch"
  fxLayoutGap="0px"
  *ngIf="layoutProperties$ | async as layoutProperties"
  [ngStyle]="{
              'border-radius': layoutProperties.borderRadius,
              'background': fullGradient ? backgroundColor : 'white'
              }"
  [ngClass]="{
                'blue': backgroundColor === 'blue',
                'full-gradient': fullGradient,
                'raised': true
            }">
  <span class="left"
    [ngStyle]="{
                'border-top-left-radius': layoutProperties.borderRadius,
                'border-bottom-left-radius': layoutProperties.borderRadius,
                'display': fullGradient ? 'none' : backgroundColor === 'white' ? 'none' : 'block',
                'background': backgroundColor
              }">
  </span>
  <div class="right"
    [ngStyle]="{
                'padding': layoutProperties.innerPadding,
                'border-top-right-radius': layoutProperties.borderRadius,
                'border-bottom-right-radius': layoutProperties.borderRadius
              }"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="15px">
    <div class="body"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px">
      <ng-content></ng-content>
    </div>
  </div>
</div>