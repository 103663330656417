import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-pwd-reset-dialog',
  templateUrl: './pwd-reset-dialog.component.html',
  styleUrls: ['./pwd-reset-dialog.component.less']
})
export class PwdResetDialogComponent implements OnInit {
  pwdResetForm = this.fb.group({
    email: ['', [
      Validators.email,
      Validators.required]
    ]
  })
  success: boolean = false;
  error: { code: string, message: string };

  constructor(
    private fb: FormBuilder,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  onPwdReset() {
    const email = this.pwdResetForm.value.email;
    this.auth.sendPasswordResetEmail(email)
    .then(_ => {
      this.resetSuccessful();
    })
    .catch(error => {
      this.resetUnsuccessful(error);
    })
  }

  private resetSuccessful() {
    this.success = true;
  }

  private resetUnsuccessful(error: { code: string, message: string }) {
    this.error = error;
    this.success = false;
  }


}
