<div class="outer-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="0px"
  *ngIf="layoutProperties$ | async as layoutProperties">
  <div class="inner-container"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="25px">
    <div mat-dialog-content
      class="content"
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutGap="15px">
      <h3>Create an Account</h3>
      <form class="form"
        [formGroup]="signUpForm"
        (ngSubmit)="onSignUpSubmit()">
        <div class="form-field-group"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <mat-form-field class="form-field"
            color="accent"
            appearance="outline"
            [floatLabel]="true">
            <mat-label class="form-label">First Name</mat-label>
            <input class="form-input"
              matInput
              formControlName="firstName"
              placeholder="First Name">
            <mat-error class="form-error"
              *ngIf="signUpForm.hasError('required')">
              Please enter your first name.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field"
            color="accent"
            appearance="outline"
            [floatLabel]="true">
            <mat-label class="form-label">Last Name</mat-label>
            <input class="form-input"
              matInput
              formControlName="lastName"
              placeholder="Last Name">
            <mat-error class="form-error"
              *ngIf="signUpForm.hasError('required')">
              Please enter your last name.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field"
            color="accent"
            appearance="outline"
            [floatLabel]="true">
            <mat-label class="form-label">Phone</mat-label>
            <input class="form-input"
              matInput
              formControlName="phoneNumber"
              placeholder="xxx-xxx-xxxx">
            <mat-error class="form-error"
              *ngIf="signUpForm.controls['phoneNumber'].hasError('pattern')">
              Please enter a valid phone number
            </mat-error>
            <mat-error class="form-error"
              *ngIf="signUpForm.hasError('required')">
              A phone number is required
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field"
            color="accent"
            appearance="outline"
            [floatLabel]="true">
            <mat-label class="form-label">Email</mat-label>
            <input class="form-input"
              matInput
              formControlName="email"
              placeholder="Ex: email@example.com">
            <mat-error class="form-error"
              *ngIf="signUpForm.controls['email'].hasError('email')">
              Please enter a valid email address
            </mat-error>
            <mat-error class="form-error"
              *ngIf="signUpForm.controls['email'].hasError('required')">
              An email is required
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field"
            color="accent"
            appearance="outline"
            [floatLabel]="true">
            <mat-label class="form-label">Password</mat-label>
            <input class="form-input"
              matInput
              type="password"
              formControlName="pwd">
            <mat-error class="form-error"
              *ngIf="signUpForm.controls['pwd'].hasError('required')">
              Please enter a password
            </mat-error>
            <mat-error class="form-error"
              *ngIf="signUpForm.controls['pwd'].hasError('minlength')">
              Your password must be at least 8 characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="footer"
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="15px">
          <mat-error class="mat-small error-message"
            *ngIf="hasError">{{ errorMessage }}</mat-error>
          <button class="button signup-button"
            mat-flat-button
            color="accent"
            [disabled]="!signUpForm.valid">Complete Sign Up
          </button>
        </div>
      </form>
    </div>
  </div>
</div>