<div class="container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  *ngIf="layoutProperties$ | async as layoutProperties"
  fxLayoutGap="{{ layoutProperties.spacing }}">

  <p class="subsection-header"><b>INTRODUCTION</b></p>

  <ng-container *ngIf="report.environmentalFactors; else noEF">
    <key-point [layoutProperties$]="layoutProperties$"
      [backgroundColor]="gradient"
      [raised]='true'
      class="key-point"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px">
      <h1 class="h1">Environmental Factors</h1>
      <p>Believe it or not, <b>our environment plays a huge role in how our hair behaves.</b> Weather, humidity, sunshine, air quality, water quality, and other factors are the silent culprits behind these effects. Although we can’t control weather
        patterns
        or your location, we can help you adjust your hair care routine to meet your hair’s needs.
      </p>
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="15px">
        <div fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px">
          <mat-icon class="green-text">explore</mat-icon>
          <span class="mat-small">Your Location:</span>
          <span class="h4"> {{ report.results.locationData?.city + ', ' + report.results.locationData?.state }}</span>
        </div>
      </div>
    </key-point>

    <mat-divider></mat-divider>

    <p class="subsection-header"><b>ABOUT YOUR ENVIRONMENT</b></p>

    <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
      fxLayoutAlign="start start"
      fxLayoutGap="{{ layoutProperties.spacing}}">

      <key-point [layoutProperties$]="layoutProperties$"
        [backgroundColor]="'white'"
        *ngIf="report.environmentalFactors.narrative.EF2.data?.value"
        fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="h3">Wind</span>
          <p class="h2 green-text"><b>{{ report.environmentalFactors.narrative.EF2.data?.recommendation }}</b></p>
          <p class="mat-small"><b>{{ report.environmentalFactors.narrative.EF2.data?.value + ' MPH on Average' }}</b></p>
        </div>
        <p class="">{{ report.environmentalFactors.narrative.EF2.data?.description }}</p>
      </key-point>


      <key-point [layoutProperties$]="layoutProperties$"
        [backgroundColor]="'white'"
        *ngIf="report.environmentalFactors.narrative.EF3.data?.value"
        fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="h3">Temperature</span>
          <p class="h2 green-text"><b>{{ report.environmentalFactors.narrative.EF3.data?.recommendation }}</b></p>
          <p class="mat-small"><b>{{ report.environmentalFactors.narrative.EF3.data?.value + ' ℉ on Average' }}</b></p>
        </div>
        <p class="">{{ report.environmentalFactors.narrative.EF3.data?.description }}</p>
      </key-point>
    </div>

    <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
      fxLayoutAlign="start start"
      fxLayoutGap="{{ layoutProperties.spacing}}">

      <key-point [layoutProperties$]="layoutProperties$"
        [backgroundColor]="'white'"
        *ngIf="report.environmentalFactors.narrative.EF4.data?.value"
        fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="h3">Precipitation</span>
          <p class="h2 green-text"><b>{{ report.environmentalFactors.narrative.EF4.data?.recommendation }}</b></p>
          <p class="mat-small"><b>{{ report.environmentalFactors.narrative.EF4.data?.value + ' Inches of Rainfall Per Year' }}</b></p>
        </div>
        <p class="">{{ report.environmentalFactors.narrative.EF4.data?.description }}</p>
      </key-point>


      <key-point [layoutProperties$]="layoutProperties$"
        [backgroundColor]="'white'"
        *ngIf="report.environmentalFactors.narrative.EF6.data?.value"
        fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="h3">Humidity</span>
          <p class="h2 green-text"><b>{{ report.environmentalFactors.narrative.EF6.data?.recommendation }}</b></p>
          <p class="mat-small"><b>{{ report.environmentalFactors.narrative.EF6.data?.value*100 + '% Humidity'}}</b></p>
        </div>
        <p class="">{{ report.environmentalFactors.narrative.EF6.data?.description }}</p>
      </key-point>
    </div>

    <div fxLayout="{{ layoutProperties.isMobile ? 'column' : 'row' }}"
      fxLayoutAlign="start start"
      fxLayoutGap="{{ layoutProperties.spacing}}">

      <key-point [layoutProperties$]="layoutProperties$"
        [backgroundColor]="'white'"
        *ngIf="report.environmentalFactors.narrative.EF5.data?.value"
        fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="h3">UV</span>
          <p class="h2 green-text"><b>{{ report.environmentalFactors.narrative.EF5.data?.recommendation }}</b></p>
          <p class="mat-small"><b>{{ 'UV Index Rating: ' +  report.environmentalFactors.narrative.EF5.data?.value}}</b></p>
        </div>
        <p class="">{{ report.environmentalFactors.narrative.EF5.data?.description }}</p>
      </key-point>

      <key-point [layoutProperties$]="layoutProperties$"
        [backgroundColor]="'white'"
        *ngIf="report.environmentalFactors.narrative.EF7.data?.value"
        fxFlex="{{ layoutProperties.isMobile ? '1 1 100%' : '1 1 50%' }}">
        <div fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="0px">
          <span class="h3">Air Quality</span>
          <p class="h2 green-text"><b>{{ report.environmentalFactors.narrative.EF7.data?.recommendation }}</b></p>
          <p class="mat-small"><b>{{ 'Air Quality Index Rating: ' +  report.environmentalFactors.narrative.EF7.data?.value}}</b></p>
        </div>
        <p class="">{{ report.environmentalFactors.narrative.EF7.data?.description }}</p>
      </key-point>
    </div>

    <key-point [layoutProperties$]="layoutProperties$"
      [backgroundColor]="'white'">
      <div fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="0px">
        <span class="h3">Water Hardness</span>
        <p class="green-text"><b>Coming Soon!</b></p>
      </div>
      <p class="">This feature is not yet available. Stay tuned for updates!</p>
    </key-point>

  </ng-container>
  <ng-template #noEF>
    <key-point [layoutProperties$]="layoutProperties$"
      [backgroundColor]="gradient"
      [raised]='true'
      class="key-point"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px">
      <h1 class="h1">Environmental Factors</h1>
      <p>Believe it or not, <b>our environment plays a huge role in how our hair behaves.</b> Weather, humidity, sunshine, air quality, water quality, and other factors are the silent culprits behind these effects. Although we can’t control weather
        patterns
        or your location, we can help you adjust your hair care routine to meet your hair’s needs.
      </p>
      <p class="mat-small"><b>This section covers:</b></p>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="environmental-factor">
        <mat-icon>landscape</mat-icon>
        <span>Wind</span>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="environmental-factor">
        <mat-icon>landscape</mat-icon>
        <span>Temperature</span>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="environmental-factor">
        <mat-icon>landscape</mat-icon>
        <span>Precipitation</span>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="environmental-factor">
        <mat-icon>landscape</mat-icon>
        <span>Humidity</span>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="environmental-factor">
        <mat-icon>landscape</mat-icon>
        <span>UV</span>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="environmental-factor">
        <mat-icon>landscape</mat-icon>
        <span>Air Pollution</span>
      </div>
      <div fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="environmental-factor">
        <mat-icon>landscape</mat-icon>
        <span>Water Hardness (Coming Soon)</span>
      </div>
    </key-point>
    <key-point [layoutProperties$]="layoutProperties$"
      [backgroundColor]="gradient"
      [raised]='true'
      class="key-point"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px">
      <p>Unfortunately Curl Cupid™ only supports Environmental Factors recommendations for clients in the United States. Check back soon for an update!</p>
    </key-point>
  </ng-template>


</div>