<div class="outer-container"
  *ngIf="layoutProperties$ | async as layoutProperties">
  <navigation-bar *ngIf="displayNavigation"></navigation-bar>

  <ng-container *ngIf="loading.loading$ | async; else pageLoaded">
    <div class="loading-container">
      <app-loading></app-loading>
    </div>
  </ng-container>

  <ng-template #pageLoaded>
    <div class="content-container"
      [ngStyle]="{
        'padding': overridePadding ? '0' : layoutProperties.outerPadding,
        'padding-bottom': '14rem',
        'margin-top': layoutProperties.isMobile && !displayNavigation ? '0' : displayNavigation ? '4rem' : '0',
        'padding-top': overridePadding ? '0' : layoutProperties.isMobile && !displayNavigation ? '25px' : layoutProperties.outerPadding.split(' ')[0]
      }"
      fxLayout="column"
      fxLayoutAlign="start center"
      [@fade]="!loading.loading$">
      <ng-content #pageToDisplay></ng-content>
    </div>
  </ng-template>

  <footer [displayNavigation]="displayNavigation"></footer>

</div>