import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@app/models/User';
import { Report } from '@app/models/Report';
import { LayoutProperties } from "../../../../services/media-observer/media-observer.service";
import { fade } from '../../../../animations/fade.animation';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less'],
  animations: [fade]
})
export class UserProfileComponent implements OnInit {
  @Input() user$: Observable<User>;
  @Input() report$: Observable<Report>;
  @Input() layoutProperties$: Observable<LayoutProperties>;
  @Input() displayAll: boolean = true;
  user: User;
  delayExpansionPanelAnimation = true;
  firstNameOverride: string;
  lastNameOverride: string;
  adminMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.adminId === environment.adminId) {
        this.adminMode = true;
      }
      this.firstNameOverride = params.firstName;
      this.lastNameOverride = params.lastName;
    })
  }

  ngOnInit(): void {
    this.user$.subscribe(user => {
      this.user = user;
      setTimeout(_ => { this.delayExpansionPanelAnimation = false }, 1000);
    });
  }

}
