<div class="container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  *ngIf="layoutProperties$ | async as layoutProperties"
  fxLayoutGap="{{ layoutProperties.spacing }}">

  <p class="subsection-header"><b>INTRODUCTION</b></p>

  <key-point [layoutProperties$]="layoutProperties$"
    [backgroundColor]="gradient"
    [raised]='true'
    class="key-point"
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="10px">
    <h1 class="h1">Health</h1>
    <p>Your hair is an extension of your body. Just like any other body part, it also reacts to your physical health amongst other factors. <b>Many health conditions have an effect on your hair as they do other body parts.</b> These conditions can
      influence
      the health, look, and versatility of your hair. When your body isn’t feeling well, chances are it’ll show in your hair. It is important to take into account any pre-existing health conditions that may present hair side-effects when working to
      understand your hair and it’s needs. We aren’t physicians, but we’ve put together some information to guide you towards a healthier hair journey for you.
    </p>
  </key-point>

  <mat-tab-group dynamicHeight
    mat-stretch-tabs
    class="orange"
    [ngStyle]="{'width': '100%'}">
    <mat-tab label="Scalp Health">
      <div fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}">
        <p class="subsection-header"><b>ABOUT YOUR SCALP HEALTH</b></p>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="gradient"
          [fullGradient]='true'
          [raised]='true'>
          <span class='h1'>Scalp Health</span>
          <p class="">This metric will help describe how healthy your scalp and hair strands are and uncover underlying conditions that may be affecting your hair and scalp health. This scalp health particularly is often the most ignored part of your
            hair.
            We will help describe how healthy your protective layer (skin) covering your head is. Scalp health influences hair growth, moisture retention, and how your hair styles and feels.</p>
        </key-point>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="10px">
            <mat-icon class='orange-text'>
              stars
            </mat-icon>
            <span class="h3 orange-text">The 411: Keeping It Real</span>
          </div>
          <p class="">Your hair’s <b>health starts at the roots and with your skin</b>. Most times, the state of your strands can be traced back to how healthy your scalp is. Any alterations to your scalp or skin including your go to style, how much
            heat
            and chemicals you use can then make your curls behave differently. Your scalp is the foundation, so start there!</p>
        </key-point>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <span class="h3">Scalp & Hair Health</span>
            <p class="h2 orange-text"><b>Your Symptoms </b></p>
          </div>
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="25px">
            <p>You've reported that you're currently experiencing the following scalp symptoms:</p>
            <div fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="25px">
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="15px"
                *ngFor="let symptom of report.health.scalpAndHairHealth.inYourControl.H3D.data">
                <ng-container *ngIf="!report.hiddenKeys.includes(symptom)">
                  <div fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="15px"
                    class="health-symptom">
                    <mat-icon>stream</mat-icon>
                    <span>{{ symptom.key.split('| ')[1]  }}</span>
                  </div>
                  <p>{{ symptom.description }}</p>
                </ng-container>
              </div>
            </div>
          </div>
        </key-point>
      </div>
    </mat-tab>

    <mat-tab label="Pre-Existing Conditions & Health">
      <div fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ layoutProperties.spacing }}">
        <p class="subsection-header"><b>ABOUT YOUR PRE-EXISTING CONDITIONS & HEALTH</b></p>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="gradient"
          [fullGradient]='true'
          [raised]='true'>
          <span class='h1'>Pre-Existing Conditions & Health</span>
          <p class="">Pre-existing conditions and health are medical conditions or factors that affect your hair health. Though we are not medical professionals, we understand the importance of considering these conditions throughout your hair care
            journey. Being aware of these conditions and their effects on your hair can provide you with the first step to a more informed hair care experience. </p>
        </key-point>

        <key-point [layoutProperties$]="layoutProperties$"
          [backgroundColor]="'white'"
          [raised]='true'
          class="key-point">
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="15px">
            <span class="h3">Pre-Existing Conditions & Health</span>
            <p class="h2 orange-text"><b>Your Conditions </b></p>
          </div>
          <div fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="25px">
            <ng-container *ngIf="report.health.preExistingConditionsAndHealth.narrative.H10D.data?.length < report.results.preExistingConditionsAndHealth?.length; else foundAllConditions">
              <p>You've reported that you're currently experiencing the following health conditions, {{ report.health.preExistingConditionsAndHealth.narrative.H10D.data.length  }} of which we can help you with immediately:</p>
              <ul [ngStyle]="{'margin-bottom': '0'}">
                <ng-container *ngFor="let condition
                  of
                  report.results.preExistingConditionsAndHealth">
                  <li *ngIf="!report.hiddenKeys.includes(condition)">{{ condition.split(': ')[1] }}</li>
                </ng-container>
              </ul>
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="25px"
                *ngFor="let condition of report.health.preExistingConditionsAndHealth.narrative.H10D.data">
                <ng-container *ngIf="!report.hiddenKeys.includes(condition); else noCondition">
                  <div fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="15px"
                    class="health-symptom">
                    <mat-icon>stream</mat-icon>
                    <span>{{ condition.key.split(': ')[1] }}</span>
                  </div>
                  <p>{{ condition.description }}</p>
                </ng-container>
                <ng-template #noCondition>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #foundAllConditions>
              <p>You've reported that you're currently experiencing the following health conditions:</p>
              <div fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="25px"
                *ngFor="let condition of report.health.preExistingConditionsAndHealth.narrative.H10D.data">
                <ng-container *ngIf="!report.hiddenKeys.includes(condition); else noCondition">
                  <div fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="15px"
                    class="health-symptom">
                    <mat-icon>stream</mat-icon>
                    <span>{{ condition.key.split(': ')[1] }}</span>
                  </div>
                  <p>{{ condition.description }}</p>
                </ng-container>
                <ng-template #noCondition>
                </ng-template>
              </div>
            </ng-template>
          </div>
        </key-point>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>