<div *ngIf="layoutProperties$ | async as layoutProperties"
  class="container"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="5px">
  <h3 mat-dialog-title>{{ data.title }}</h3>
  <div mat-dialog-content
    class="content"
    fxLayout="column"
    fxLayoutAlign="start start">
    <p>{{ data.message }}</p>
  </div>
  <div class="button-container"
    fxLayout="row"
    fxLayoutAlign="start stretch"
    fxLayoutGap="10px">
    <ng-container *ngIf="data.buttonList; else standardButtons">
      <button mat-flat-button
        class="button"
        color="{{ button.isWarning ? 'warn' : 'accent'}}"
        *ngFor="let button of data.buttonList"
        [ngStyle]="{'width': 100/data.buttonList.length + '%' }"
        [mat-dialog-close]="button.onClose">{{ button.text }}</button>
    </ng-container>
    <ng-template #standardButtons>
      <button mat-flat-button
        color="accent"
        class="button"
        [ngStyle]="{'width': layoutProperties.isMobile ? '100%' : 'auto'}"
        (click)="oncloseDialog()">Close</button>
    </ng-template>
  </div>
</div>