import { Component, OnInit, Input } from '@angular/core';
import { MediaObserverService, LayoutProperties } from "../../../services/media-observer/media-observer.service";
import { LoadingService } from '../../../services/loading/loading.service';
import { Observable } from 'rxjs';
import { Product } from '../../../../../functions/src/models/Product';
import { Report } from '@app/models/Report';
import { Router } from '@angular/router';

@Component({
  selector: 'product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.less']
})
export class ProductListingComponent implements OnInit {
  @Input() height: string;
  @Input() product: Product;
  @Input() report$: Observable<Report>;
  layoutProperties$: Observable<LayoutProperties>;

  constructor(
    private loading: LoadingService,
    private layoutObserver: MediaObserverService,
    private router: Router
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
  }

  ngOnInit(): void {
  }

  loadProduct() {
    setTimeout(() => {
      this.router.navigate(['/products'], {
        queryParams: {
          productId: this.product.id
        }
      });
    }, 500);
  }

}
