import { Component, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@app/models/User';
import { ProductKit, ProductKitReference } from '@app/models/ProductKit';
import { Report } from '@app/models/Report';
import { Product } from '@app/models/Product';
import { MediaObserverService, LayoutProperties } from "../../../services/media-observer/media-observer.service";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from '../../../services/auth/auth.service';
import { ReportService } from '../../../services/report/report.service';
import { DatabaseService } from '../../../services/database/database.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { fade } from '../../../animations/fade.animation';


@Component({
  selector: 'app-product-kits',
  templateUrl: './product-kits.component.html',
  styleUrls: ['./product-kits.component.less'],
  animations: [fade]
})
export class ProductKitsComponent implements OnInit {
  user$: Observable<User>;
  user: User;
  report$: Observable<Report>;

  layoutProperties$: Observable<LayoutProperties>;

  currentProduct: Product;
  products: Product[];
  productsToDisplay: Product[][];
  sections: string[];
  types: string[];
  subtypes: string[];
  brands: string[];
  porosities: string[];
  symptoms: string[];
  specifics: string[];
  xFrees: string[];

  filterBy: string[];
  selectedFilters: string[] = [];
  combinedFilters: {
    filter: string,
    filterBy: string
  }[] = [];
  combinedFiltersString: string = "";
  productAttributesIndexArray: [string, any, string][];
  delayExpansionPanelAnimation = true;

  productCatalogColumns: number;
  productIndex: number;
  chunk: number;

  productKitReferences: ProductKitReference[];
  productKitMenu = {
    stylingKit: {
      id: "stylingKit",
      name: "Styling Kit",
      img: "../../../../assets/images/products/product-kits/styling-kit.png",
      icon: "pan_tool",
      color: "#75B754"
    },
    moistureKit: {
      id: "moistureKit",
      name: "Moisture Kit",
      img: "../../../../assets/images/products/product-kits/moisture-kit.png",
      icon: "clean_hands",
      color: "#D73B71"
    },
    sleepKit: {
      id: "sleepKit",
      name: "Sleep Kit",
      img: "../../../../assets/images/products/product-kits/sleep-kit.png",
      icon: "nightlight",
      color: "#E6693B"
    },
    toolKit: {
      id: "toolKit",
      name: "Tool Kit",
      img: "../../../../assets/images/products/product-kits/tool-kit.png",
      icon: "content_cut",
      color: "#647FD5"
    },
    washKit: {
      id: "washKit",
      name: "Wash Kit",
      img: "../../../../assets/images/products/product-kits/wash-kit.png",
      icon: "shower",
      color: "#EEAC57"
    },
  };
  productKits$: Observable<ProductKit[]>;
  currentKit: ProductKit;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  constructor(
    private reportService: ReportService,
    private route: ActivatedRoute,
    private auth: AuthService,
    public layoutObserver: MediaObserverService,
    public database: DatabaseService,
    public loading: LoadingService,
    public analytics: AnalyticsService
  ) {
    this.loadReport();
    this.productKits$ = this.loadKits();
    this.productKits$.subscribe(kits => {
      this.currentKit = kits[0];
      if (this.currentKit) this.logProductKitViewedEvent();
      this.loadProducts();
    })
    this.loading.loadingOff();
  }

  ngOnInit(): void {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.productCatalogColumns = 2;
    this.layoutProperties$.subscribe(properties => {
      switch (properties.layoutSize) {
        case "xs":
          this.productCatalogColumns = 1;
          break;
        case "sm":
          this.productCatalogColumns = 2;
          break;
        case "md":
          this.productCatalogColumns = 3;
          break;
        case "lg":
          this.productCatalogColumns = 3;
          break;
        case "xl":
          this.productCatalogColumns = 3;
          break;
        default:
          this.productCatalogColumns = 2;
          break;
      }
    })
    this.loadUser();
  }

  private loadUser() {
    this.user$ = this.auth.user$;
    this.user$.subscribe(user => {
      this.user = user;
      this.productKitReferences = user.productKits;
    })
  }

  private async loadReport() {
    this.loading.loadingOn();
    this.report$ = this.reportService.report$;
    if (!this.report$) return this.loading.loadingOff();
  }

  private loadKits() {
    return this.database.getProductKits();
  }

  private loadProducts() {
    this.productKits$.subscribe(kits => {
      let kit = kits.find(kit => kit.id === this.currentKit.id);
      this.database.getProductsById(kit.products).then((products: Product[]) => {
        this.products = products;
        this.prepFilters(this.products);
        this.route.queryParams.subscribe(params => {
          const productId = params.productId;
          if (productId) {
            this.currentProduct = this.products.filter(product => {
              return product.id.toString() === productId;
            })[0];
          }
          else {
            this.currentProduct = null;
            this.loadProductsToDisplay()
          }
        });
      })
        .catch(error => {
          console.error(error)
        })
    })
  }

  private loadProductsToDisplay() {
    let i: number, j: number, temparray: Product[][] = [];
    let chunk = this.productCatalogColumns;
    this.chunk = chunk;
    for (i = 0, j = this.products.length; i < j; i += chunk) {
      temparray.push(this.products.slice(i, i + chunk));
    }
    this.productsToDisplay = temparray;
    setTimeout(_ => { this.delayExpansionPanelAnimation = false }, 100)
  }

  toggleCurrentKit(kitId: string) {
    this.productKits$.subscribe(kits => {
      this.currentKit = kits.find(kit => kit.id === kitId);
    });
    this.loadProducts();
  }

  private prepFilters(products: Product[]) {
    this.productAttributesIndexArray = [
      ["productSubtype", "subtypes", "Subtype"],
      ["brand", "brands", "Brand"],
      ["porosity", "porosities", "Porosity"],
      ["scalpAndHairHealth", "symptoms", "Symptom"],
      ["productSpecifics", "specifics", "Specifics"],
      ["productType", "types", "Type"],
      ["xFree", "xFrees", "X-Free"],
    ]

    this.productAttributesIndexArray.forEach((index) => {
      this[index[1]] = this.indexProductAttributes(products, index[0]);
    })
  }

  private indexProductAttributes(products: Product[], attribute: string) {
    let resultsAsSet = new Set();
    let resultsToReturn = [];
    products.map(product => {
      switch (attribute) {
        case 'brand':
          resultsAsSet.add(product[attribute]);
          break;
        default:
          if (!product[attribute]) break;
          if (typeof product[attribute] === 'object') {
            product[attribute].forEach((a: string) => {
              resultsAsSet.add(a);
            })
          }
          else {
            resultsAsSet.add(product[attribute]);
          }
          break;
      }
    })
    resultsToReturn = Array.from(resultsAsSet).sort();
    return resultsToReturn;
  }

  updateProductFilter(filter: string, filterBy: string) {
    let combinedFilter = { filter: filter, filterBy: filterBy };
    if (this.selectedFilters.includes(filter)) {
      this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);
      this.combinedFilters = this.combinedFilters.filter(f => {
        return f.filter !== filter;
      })
    }
    else {
      this.selectedFilters.push(filter);
      this.combinedFilters.push(combinedFilter);
      this.analytics.logFilteredProductEvent(filter, filterBy)
    }

    const tempFilters = this.combinedFilters.map(filterObject => {
      return `${filterObject.filterBy} | ${filterObject.filter}`;
    })

    this.combinedFiltersString = tempFilters.join(', ');
    this.loadProductsToDisplay();
    window.scrollTo(0, 0);
  }

  clearCurrentProduct() {
    this.currentProduct = null;
    this.loadProductsToDisplay();
    this.scrollToIndex(this.productIndex);
  }

  clearFilters() {
    this.selectedFilters = [];
    this.combinedFilters = [];
    this.combinedFiltersString = "";
    this.loadProductsToDisplay();
  }

  private scrollToIndex(index: number) {
    setTimeout(() => {
      this.viewPort.scrollToIndex(index);
    });
  }

  public logProductKitViewedEvent() {
    this.analytics.logViewedProductKitEvent(this.currentKit);
  }

}
