import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { LoadingService } from './services/loading/loading.service';
import { environment } from '../environments/environment';

import { AuthService } from './services/auth/auth.service';
import { DatabaseService } from './services/database/database.service';
import { QuizService } from './services/quiz/quiz.service';
import { ReportService } from './services/report/report.service';
import { MediaObserverService } from './services/media-observer/media-observer.service'
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [
  ]
})

export class AppComponent {
  isMobile: boolean;
  outerPadding: string;
  maxWidth: string;
  timeOfDay: string;
  greeting: string;
  hasCreatedReport: boolean;
  hasCreatedRoutine: boolean;

  routes: Object = {
    "/home": {
      label: "Home",
      route: "home",
      title: "Home",
      icon: "home"
    },
    "/quiz": {
      label: "Quiz",
      route: "quiz",
      title: "Evaluate Your Hair",
      icon: "help"
    },
    '/report': {
      label: "Report",
      route: "report",
      title: "Review Your Report",
      icon: "assignment"
    },
    '/routine': {
      label: "Routine",
      route: "routine",
      title: "Review Your Routine",
      icon: "event_available"
    }
  }

  footerLinks: Object = [
    {
      route: "/home",
      label: this.routes['/home'].label,
      title: this.routes['/home'].title,
      icon: this.routes['/home'].icon,
    },
    {
      route: "/quiz",
      label: this.routes['/quiz'].label,
      title: this.routes['/quiz'].title,
      icon: this.routes['/quiz'].icon,
    },
    {
      route: "/report",
      label: this.routes['/report'].label,
      title: this.routes['/report'].title,
      icon: this.routes['/report'].icon,
    },
    {
      route: "/routine",
      label: this.routes['/routine'].label,
      title: this.routes['/routine'].title,
      icon: this.routes['/routine'].icon,
    }
  ];



  constructor(
    public auth: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public layoutObserver: MediaObserverService,
    private db: DatabaseService,
    private quizService: QuizService,
    private reportService: ReportService,
    public loading: LoadingService,
    private title: Title
  ) {
    this.matIconRegistry.addSvgIcon(
      `google`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/google.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `facebook`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/facebook.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `instagram`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/instagram.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `keeping_it_real`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/keeping-it-real.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `fist`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/fist.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `amazon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/amazon.svg")
    );
    this.init();
  }

  private async init() {
    this.loading.loadingOn();
    await this.db.init();
    this.reportService.init();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let title;
        if (event.url === '/') {
          title = "Curl Cupid™ - Welcome";
        }
        else {
          let url = event.url.split('/')[1];
          const slug = url.charAt(0).toUpperCase() + url.slice(1)
          title = `Curl Cupid™ - ${slug}`;
        }
        this.title.setTitle(title);
        if (event.url === "/report") {
          this.loadGtagScript();
        }
        else {
          if (document.getElementById('gtagScript')) {
            document.getElementById('gtagScript').remove();
          }
        }
      }
      window.scrollTo(0, 0)
    });

    this.loading.loadingOff();
  }


  signOut() {
    this.auth.signOut().then(_ => {
      this.router.navigate(['/login']);
    })
  }

  ngOnInit(): void {
    this.layoutObserver.layoutObserver$.subscribe(result => {
      this.isMobile = result.isMobile;
      this.outerPadding = result.outerPadding;
      this.maxWidth = result.maxWidth;
    })
  }

  loadGtagScript() {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.id = "gtagScript";
    script.innerHTML = `<!-- Event snippet for Purchase Report conversion page -->
      gtag('event', 'conversion', {
          'send_to': 'AW-381219942/i1K_CL764ZICEObo47UB',
          'transaction_id': ''
      });`;
    head.insertBefore(script, head.firstChild);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

}
