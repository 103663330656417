import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { MediaObserverService, LayoutProperties } from "../../services/media-observer/media-observer.service";
import { ReportService } from '../../services/report/report.service';
import { DatabaseService } from '../../services/database/database.service';
import { Report } from '../../../../functions/src/models/Report';
import { BlogArticle } from '@app/models/BlogArticle';
import { LoadingService } from "../../services/loading/loading.service";
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { fade } from '../../animations/fade.animation';

@Component({
  selector: 'home-page',
  host: {
    class: 'page',
  },
  animations: [fade],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  isMobile: boolean;
  outerPadding: string;
  spacing: string;
  maxWidth: string;
  hasCreatedReport: boolean;
  hasCreatedRoutine: boolean;
  nextStepsRowHeight;
  nextStepsColumnCount;
  layoutProperties$: Observable<LayoutProperties>
  report: Report;
  blogs$: Observable<BlogArticle[]>;

  nextStepsLinks = [
    {
      id: "quiz",
      step: "Evaluate",
      title: "1. Begin Quiz",
      subtitle: "The starting point for any and every hair type.",
      icon: "equalizer",
      route: "/quiz",
      number: 1
    },
    {
      id: "report",
      step: "Learn",
      title: "2. Explore Report",
      subtitle: "Hair care recommendations made for you.",
      icon: "assignment",
      route: "/report",
      number: 2
    },

    {
      id: "products",
      step: "Shop",
      title: "3. Shop Products",
      subtitle: "Browse and purchase your favorite products.",
      icon: "shopping_cart",
      route: "/products",
      number: 3
    }
  ]

  constructor(
    private reportService: ReportService,
    public auth: AuthService,
    public layoutObserver: MediaObserverService,
    public database: DatabaseService,
    public loading: LoadingService,
    public analytics: AnalyticsService
  ) {
    this.layoutProperties$ = this.layoutObserver.layoutObserver$;
    this.layoutProperties$.subscribe(result => {
      switch (result.layoutSize) {
        case 'xs':
          this.nextStepsRowHeight = '100px';
          this.nextStepsColumnCount = 8;
          break;
        case 'sm':
          this.nextStepsRowHeight = '100px';
          this.nextStepsColumnCount = 8;
          break;
        case 'md':
          this.nextStepsRowHeight = '160px';
          this.nextStepsColumnCount = 8;
          break;
        case 'lg':
        case 'xl':
        default:
          this.nextStepsRowHeight = '150px';
          this.nextStepsColumnCount = 8;
          break;
      }
    })
    this.hasCreatedReport = this.reportService.paidReports && this.reportService.paidReports.length > 0;
    this.reportService.report$ ?.subscribe(report => {
      this.report = report;
    });
    this.blogs$ = this.database.getBlogs();
  }

  ngOnInit(): void {
  }

  openLink(url: string, event: string) {
    window.open(url);
    this.analytics.logClickedLinkEvent('home', event);
  }
}
