import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AnalyticsService } from '../analytics/analytics.service';

import { QuizField, QuizOption } from "@app/models/QuizField";
import { QuizFamily } from "@app/models/QuizFamily";
import { QuizState } from "@app/models/QuizState";
import { QuizResults } from "@app/models/QuizResults";
import { User } from "@app/models/User";

import { ReportService } from "../report/report.service";
import { DatabaseService } from "../database/database.service";

@Injectable({
  providedIn: "root"
})

/*
  Controller for Curl Cupid quiz. Handles navigation, state management, quiz submission, and generating dummy data for test reports.
 */
export class QuizService {
  private initialFieldId: string = "INTRO1";
  // quiz state handled by QuizState class
  private state: QuizState;
  // store quiz answers as we go
  private results: QuizResults;
  private user: User;
  private quizFields: {
    [id: string]: QuizField;
  };
  private quizFamilies: {
    [id: string]: QuizFamily;
  };
  private reportAnswerKey: {
    quizAnswer: string;
    recommendationTerm: string;
    section: string;
  };
  private experienceLevel: string;

  unfoundKeys = [];

  constructor(
    private reportService: ReportService,
    private db: DatabaseService,
    private analytics: AnalyticsService
  ) { }

  // private initFamilies(data: any)

  public async init() {
    this.db.user$.subscribe(user => {
      this.user = user;
    });
    this.quizFields = await this.db.getQuizFields();
    this.quizFamilies = await this.db.getQuizFamilies();
    this.state = new QuizState(this.quizFields);
    this.state.initState(this.initialFieldId);
    this.results = null;
  }

  public startQuiz(experienceLevel: string): [QuizField, QuizFamily, boolean] {
    this.analytics.logEvent('quiz', "quiz_started");
    this.experienceLevel = experienceLevel;
    this.state.startQuiz(experienceLevel);
    const currentField = this.getCurrentQuizField();
    const currentFamily = this.getCurrentQuizFamily(currentField);
    return [currentField, currentFamily, this.state.isInProgress];
  }

  public async resetQuiz() {
    this.state.fieldTrack = null;
    this.state = null;
    await this.init();
    console.log(this.state)
    return this.state.isInProgress;
  }

  public isFirstField(): boolean {
    return this.state.isFirstField;
  }

  public navigateQuiz(
    forward: boolean, // forward or backward navigation?
    currentField: QuizField
  ): QuizField {
    let nextState: Object; // future state to be created
    const [newFieldTrack, storedValues] = this.updateQuizValues(
      currentField,
      this.state
    );

    if (forward) {
      nextState = this.nextQuizField(this.state, newFieldTrack, storedValues);
    } else {
      nextState = this.previousQuizField(this.state);
    }

    this.updateQuizState(nextState);
    return this.getCurrentQuizField();
  }

  public updateQuizField(currentField: QuizField) {
    this.updateQuizValues(currentField, this.state);
  }

  // return which field we're currently looking at
  public getCurrentQuizField(): QuizField {
    return this.state.currentField();
  }

  // return which field family we're currently looking at
  public getCurrentQuizFamily(currentField: QuizField): QuizFamily {
    return this.quizFamilies[currentField.family];
  }

  public validateField(field: QuizField): any {
    const values = field.values;
    switch (field.type) {
      case "text":
        if (field.id === "dem-1") {
          if (values.length < 1) {
            return {
              isValid: false,
              error: "no-age"
            };
          }
        }
        if (values.length < 1) {
          return {
            isValid: false,
            error: "no-text"
          };
        }
        break;
      case "select":
      case "checkbox":
      case "radio":
        if (values.length < 1) {
          return {
            isValid: false,
            error: "no-input"
          };
        }
        break;
      case "info":
        break;
      default:
        return {
          isValid: false,
          error: "no-input"
        };
    }
    return {
      isValid: true
    };
  }

  private updateQuizValues(
    currentField: QuizField,
    currentState: QuizState
  ): [QuizField[], Object] {
    const quizFields = currentState.quizFields;
    const fieldTrack = currentState.fieldTrack;
    let storedValues = currentState.storedValues;

    const values = currentField.values || [];

    const fieldIdsToAdd: string[] = this.getNextFieldIds(
      currentField,
      currentField.type,
      values,
      currentField.nav,
      storedValues
    );

    let newFieldTrack: QuizField[] = [];
    let trackFieldIds: Set<string> = new Set();
    fieldTrack.map(field => trackFieldIds.add(field.id));

    fieldIdsToAdd
      .filter(fieldId => {
        return !trackFieldIds.has(fieldId);
      })
      .map(fieldId => {
        const field = quizFields[fieldId];
        newFieldTrack.push(field);
      });

    values.forEach(value => {
      switch (currentField.id) {
        case "D2":
          const age = new Number(value);
          let valueToStore: string;
          if (age < 12) valueToStore = "D2a";
          if (12 <= age && age < 17) valueToStore = "D2b";
          if (17 <= age && age < 24) valueToStore = "D2c";
          if (25 <= age && age < 34) valueToStore = "D2d";
          if (35 <= age && age < 44) valueToStore = "D2e";
          if (45 <= age && age < 54) valueToStore = "D2f";
          else valueToStore = "D2g";
          storedValues[valueToStore] = true;
          break;
        case "EF1":
          storedValues["EF1"] = value;
          break;
        default:
          storedValues[value] = true;
          break;
      }
    });

    storedValues['experienceLevel'] = this.experienceLevel;

    return [newFieldTrack, storedValues];
  }

  private getNextFieldIds(
    field: QuizField,
    type: string,
    values: string[],
    nav: any,
    storedValues: any
  ): string[] {
    let nextFieldId: string;

    let val: string;
    let fieldIdsToAdd: Set<string> = new Set();

    switch (type) {
      case "radio":
        val = values[0];
        switch (field.id) {
          case "D6":
            if (storedValues["D6"]) {

            }
          case "HM2":
            if (
              storedValues["HM1a"] && storedValues["HM2a"]
            ) {
              nextFieldId = "HM5";
              break;
            }
          default:
            nextFieldId = nav[val] ? nav[val] : nav.default;
            break;
        }
        fieldIdsToAdd.add(nextFieldId);
        break;

      case "checkbox":
        if (values.length <= 1) {
          val = values[0];
          if (val === "EF0") {
            if (storedValues.D1 !== "United States of America (USA)") {
              nextFieldId = "EF2";
              fieldIdsToAdd.add(nextFieldId);
            }
          } else {
            nextFieldId = nav[val] ? nav[val] : nav.default;
            fieldIdsToAdd.add(nextFieldId);
          }
        } else {
          values.map(val => {
            nextFieldId = nav[val] ? nav[val] : nav.default;
            fieldIdsToAdd.add(nextFieldId);
          });
        }
        break;

      case "info":
        switch (field.id) {
          case "EF0":
            if (storedValues["United States of America (USA)"]) {
              nextFieldId = "EF1";
            }
            else {
              nextFieldId = "EF2";
            }
            break;
          default:
            nextFieldId = nav.default;
            fieldIdsToAdd.add(nextFieldId);
            break;
        }
        fieldIdsToAdd.add(nextFieldId);
        break;

      default:
        nextFieldId = nav.default;
        fieldIdsToAdd.add(nextFieldId);
        break;
    }

    return Array.from(fieldIdsToAdd);
  }

  private updateQuizState(nextState: Object): void {
    this.state.updateState(nextState);
  }

  private nextQuizField(
    state: QuizState,
    newFieldTrack: QuizField[],
    storedValues: Object
  ): Object {
    const history = state.history;
    const fieldTrack = state.fieldTrack;
    const currentFieldIndex = state.currentFieldIndex;
    let nextState: Object;

    nextState = {
      history: history.concat([
        {
          fieldTrack: this.deepCopy(fieldTrack),
          storedValues: this.deepCopy(storedValues)
        }
      ]),
      fieldTrack: fieldTrack.concat(newFieldTrack),
      storedValues: storedValues,
      currentFieldIndex: currentFieldIndex + 1,
      isFirstField: currentFieldIndex + 1 <= 0
    };

    return nextState;
  }

  private previousQuizField(state: QuizState): Object {
    let history = state.history;
    const currentFieldIndex = state.currentFieldIndex;

    if (history.length <= 1) {
      return state;
    }

    const previousFieldTrack = history[history.length - 1].fieldTrack;
    const previousStoredValues = history[history.length - 1].storedValues;
    history.pop();

    const previousState = {
      history: history,
      fieldTrack: previousFieldTrack,
      storedValues: previousStoredValues,
      currentFieldIndex: currentFieldIndex - 1,
      isFirstField: currentFieldIndex - 1 <= 0
    };

    return previousState;
  }

  public async generateReport(values?: any, uid?: string, displayName?: string): Promise<void> {
    this.analytics.logEvent('quiz', "quiz_submitted");
    this.reportAnswerKey = await this.db.getQuizAnswerKey();
    const valuesTemp = values || this.state.getStoredValues();
    const user = this.user;
    this.results = new QuizResults().parseQuizResults(
      valuesTemp,
      this.reportAnswerKey,
      uid && displayName ? null : user,
      uid,
      displayName
    )
    const unfoundKeys = this.results.unfoundKeys;
    this.unfoundKeys.concat(unfoundKeys);
    return this.reportService.generateReport(this.results);
  }

  /*

  TEST

  */

  public async testQuiz(): Promise<void | any[]> {
    const field = this.state.quizFields[this.initialFieldId];
    let storedValues: Object = {};
    const track = this.randomizeQuizResults(field, []);
    track.forEach(field => {
      const values = field.values || [];
      switch (field.type) {
        case "info":
          break;
        case "checkbox":
          storedValues[field.id] = values;
          break;
        default:
          storedValues[field.id] = values[0];
          break;
      }
    });
    storedValues["experienceLevel"] = "beginner";
    storedValues["D2"] = ["24"];
    storedValues["EF1"] = ["90015"];
    storedValues["D1"] = "United States of America (USA)";
    return this.generateReport(storedValues);
  }

  private randomizeQuizResults(field: QuizField, track: QuizField[]): QuizField[] {
    if (field.id === "REVIEW") {
      return [field];
    } else {
      track.push(field);
      const nav = field.nav;
      const options = field.options;
      const storedValues = field.values;
      let nextFieldId: string;

      if (!field.isInput) {
        nextFieldId = this.getNextFieldIds(
          field,
          field.type,
          [nav.default],
          nav,
          storedValues
        )[0];
      } else {
        let optionsToReturn: QuizOption[] = [];
        if (options.length < 1) {
          optionsToReturn = [{ value: "N/A", label: "N/A" }];
        } else {
          if (field.type === "checkbox") {
            const countToReturn = this.getRandomInteger(options);
            let count = 0;
            let seen = [];
            while (count < 2 && count < 3) {
              let i = this.getRandomInteger(options);
              if (seen.includes(i)) {
                continue;
              }
              else {
                seen.push(i);
                optionsToReturn.push(options[i]);
              }
              count += 1;
            }
          }
          else {
            const countToReturn = 1;
            let count = 0;
            let seen = [];
            while (count < countToReturn) {
              let i = this.getRandomInteger(options);
              if (seen.includes(i)) {
                continue;
              }
              else {
                seen.push(i);
                optionsToReturn.push(options[i]);
                count += 1;
              }
            }
          }
        }

        nextFieldId = this.getNextFieldIds(
          field,
          field.type,
          optionsToReturn.map(val => val.value),
          nav,
          storedValues
        )[0];

        field.values = optionsToReturn.map(val => val.value);
      }

      const nextField = this.state.quizFields[nextFieldId];
      track.concat(this.randomizeQuizResults(nextField, track));
      return track;
    }
  }

  private getRandomInteger(values: any[]) {
    const x = values.length;
    const min = 1;
    const max = Math.floor(x);
    return Math.floor(Math.random() * (max - min) + min);
  }

  private deepCopy(input: any): any {
    let output: object, value: string, key: string;

    if (typeof input !== "object" || input === null) {
      return input; // Return the value if input is not an object
    }

    // Create an array or object to hold the values
    output = Array.isArray(input) ? [] : {};

    for (key in input) {
      value = input[key];

      // Recursively (deep) copy for nested objects, including arrays
      output[key] = this.deepCopy(value);
    }

    return output;
  }
}
