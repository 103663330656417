import { QuizResults } from "./QuizResults";
import { Timestamp } from "./Timestamp";
import { of } from "rxjs";
declare var require: any
const fetch = require('node-fetch');
import { format } from "url";

export class Report {
  uid: string;
  id: string;
  resultsId: string;
  createdDate: Timestamp;
  welcome: Welcome;
  demographics: Demographics;
  hairProperties: HP;
  effectsAndTransformations: ET;
  environmentalFactors: EF;
  health: Health;
  productSubtypes: {
    subtypes: string[],
    section: string
  }[] = [];
  products: string[];
  results: QuizResults;
  unfoundKeys = {};
  families = [
    "welcome",
    "demographics",
    "hairProperties",
    "effectsAndTransformations",
    "environmentalFactors",
    "health",
  ]
  hiddenKeys = [
    "Heat Damage: None of the above",
    "Routine: None",
    "None of the above",
    "Scalp Health: None",
    "Scalp Health: None",
    "Scalp Health: Prefer Not to Say",
    "Scalp Health: Prefer Not to Say",
    "Pre-Existing Conditions and Health: Not Applicable",
    "Pre-Existing Conditions and Health: None",
    "Pre-Existing Conditions and Health: None",
    "Demographics: Prefer not to disclose",
    "Demographics: Prefer not to disclose",
    "Pre-Existing Conditions and Health: Prefer not to say",
    "Pre-Existing Conditions and Health: Prefer Not to Say",
    "Pre-Existing Conditions and Health: No Chemotherapy",
    "Pre-Existing Conditions and Health: No Menopause or Andropause",
  ]
  isPaid = false;
  viewed = false;

  async generate(config: any, db: any, quizResults: QuizResults): Promise<Report> {
    this.createdDate = new Timestamp(new Date());
    this.results = quizResults;
    this.uid = quizResults.uid;

    for (const family of this.families) {
      switch (family) {
        case "welcome":
          this.welcome = this.generateWelcome(quizResults);
          break;
        case "demographics":
          this.demographics = this.generateDemographics(quizResults);
          break
        case "hairProperties":
          this.hairProperties = await this.generateHP(quizResults, db);
          break;
        case "health":
          this.health = await this.generateHealth(quizResults, db);
          break;
        case "environmentalFactors":
          if (quizResults.residency === "United States of America (USA)" || quizResults.residency[0] === "United States of America (USA)") {
            this.environmentalFactors = await this.generateEF(quizResults, db, config);
          }
          break;
        case "effectsAndTransformations":
          this[family] = await this.generateReportFamilies(family, quizResults, db);
          break;
        default:
          break;
      }
    }
    return of(this).toPromise();
  }

  private generateWelcome(quizResults: QuizResults): Welcome {
    const result = {
      W1: quizResults.firstName,
      W2: quizResults.lastName,
    }
    return result;
  }

  private generateDemographics(quizResults: QuizResults): Demographics {
    const result = {
      D1: quizResults.firstName,
      D2: quizResults.lastName,
      D3: quizResults.residency,
      D4: quizResults.age,
      D5: quizResults.assignedSex,
      D6: quizResults.genderIdentity,
      D7: quizResults.race,
      D8: quizResults.ancestralBackground,
    };
    return result;
  }

  private async generateReportFamilies(family: string, quizResults: QuizResults, db: any): Promise<any> {
    let resultToReturn: ET | undefined;
    switch (family) {
      case "effectsAndTransformations":
        resultToReturn = new ET();
        break;
      default:
        resultToReturn = new ET();
        break;
    }
    for (const section of Object.keys(resultToReturn)) {
      let result = resultToReturn[section];
      result = await this.mapInputValues(result, family, quizResults, db);
      resultToReturn[section] = result;
    }

    return resultToReturn;
  }

  private async generateHP(quizResults: QuizResults, db: any): Promise<HP> {
    let resultToReturn = new HP();
    for (const section of Object.keys(resultToReturn)) {
      for (const innerSection of Object.keys(resultToReturn[section])) {
        let result = resultToReturn[section][innerSection];
        result = await this.mapInputValues(result, section, quizResults, db);
        resultToReturn[section][innerSection] = result;
      }
    }

    return resultToReturn;
  }

  private async generateHealth(quizResults: QuizResults, db: any): Promise<Health> {
    let resultToReturn = new Health();
    for (const section of Object.keys(resultToReturn)) {
      for (const innerSection of Object.keys(resultToReturn[section])) {
        let result = resultToReturn[section][innerSection];
        result = await this.mapInputValues(result, 'health', quizResults, db);
        resultToReturn[section][innerSection] = result;
      }
    }

    return resultToReturn;
  }



  public async generateEF(quizResults: QuizResults, db: any, config: any) {

    const locationData: LocationData = await fetchLocationData(quizResults.zipcode, config);

    if (this.results) this.results.locationData = locationData;

    let resultToReturn = new EF();
    for (const section of Object.keys(resultToReturn)) {
      let result = resultToReturn[section];
      result = await this.mapEFInputValues(result, locationData, quizResults, db);
      resultToReturn[section] = result;
    }


    return resultToReturn;
  }

  private async mapInputValues(result: any, section: string, quizResults: QuizResults, db: any): Promise<any> {
    for (const inputId of Object.keys(result)) {
      let input: RNTInput = result[inputId];
      try {
        let data = await this.lookupRNTInput(input, section, quizResults, db);
        if (input.type === "recommendation") {
          if (Array.isArray(data)) {
            data = data.map(d => {
              d.techniques = d.techniques ?.split('\n');
              d.routines = d.routines ?.split('\n');
              d.productTypes = d.products ?.split('\n');
            })
          }
          else {
            data.techniques = data.techniques ?.split('\n');
            data.routines = data.routines ?.split('\n');
            data.productTypes = data.products ?.split('\n');
          }
        }
        input.data = data;
      }
      catch {
        input.data = undefined;
        console.log("error: ", input.id)
      }
      result[input.id] = input;
    }
    return result;
  }

  private async mapEFInputValues(result: any, locationData: LocationData, quizResults: QuizResults, db: any): Promise<any> {
    const section = "environmentalFactors";
    for (const inputId of Object.keys(result)) {
      let input: RNTInput = result[inputId];
      try {
        let data = await this.lookupRNTInput(input, section, quizResults, db, locationData);
        if (input.type === "recommendation") {
          if (Array.isArray(data)) {
            data = data.map(d => {
              d.techniques = d.techniques ?.split('\n');
              d.routines = d.routines ?.split('\n');
              d.productTypes = d.products ?.split('\n');
            })
          }
          else {
            data.techniques = data.techniques ?.split('\n');
            data.routines = data.routines ?.split('\n');
            data.productTypes = data.products ?.split('\n');
          }
        }
        input.data = data;
      }
      catch {
        input.data = undefined;
        console.log("error: ", input.id)
      }
      result[input.id] = input;
    }
    return result;
  }

  private async lookupRNTInput(input: RNTInput, section: string, quizResults: QuizResults, db: any, locationData?: LocationData): Promise<RNTInputData | any> {
    switch (input.type) {
      case "result":
        return this.retrieveResult(input, section, quizResults);
      case "description":
      case "recommendation":
        return this.retrieveDescriptionAndRecommendation(input, section, quizResults, db);
      case "multipleDescription":
        return this.retrieveMultipleDescription(input, section, quizResults, db);
      case "apiLookup":
        if (locationData) {
          return this.retrieveApiLookup(input, section, db, locationData);
        }
        return;
      default:
        return
    }
  }

  private retrieveResult(input: RNTInput, section: string, quizResults: QuizResults) {
    let key = input.key;
    let data = quizResults[key];
    if (!data) this.unfoundKeys[input.id] = [input, key, section];
    if (data ?.products) this.productSubtypes.push({
      subtypes: data.products.split('\n'),
      section: section,
    });
    return quizResults[input.key];
  }

  private async retrieveDescriptionAndRecommendation(input: RNTInput, section: string, quizResults: QuizResults, db: any): Promise<any> {
    let key = this.createRNTKey(input, quizResults);
    let ref = db.ref(`${section}/${key}`);
    return ref.once('value').then(snapshot => {
      let data = snapshot.val();
      if (!data) this.unfoundKeys[input.id] = [input, key, section];
      if (data ?.products) this.productSubtypes.push({
        subtypes: data.products.split('\n'),
        section: section,
      });
      return data;
    }).catch(err => {
      console.log(err)
      return err;
    });
  }

  private async retrieveMultipleDescription(input: RNTInput, section: string, quizResults: QuizResults, db: any): Promise<any[]> {
    let resultToReturn: any[] = [];

    for (const inputToLookup of quizResults[input.key]) {
      let key = `${input.id} | ${inputToLookup}`;
      let ref = db.ref(`${section}/${key}`);
      await ref.once('value').then(snapshot => {
        let data = snapshot.val();
        if (!data) this.unfoundKeys[input.id] = [input, key, section];
        if (data ?.products) this.productSubtypes.push({
          subtypes: data.products.split('\n'),
          section: section,
        });
        if (data) resultToReturn.push(data);
      }).catch(err => {
        console.log(err)
      });
    }

    return resultToReturn;
  }

  private async retrieveApiLookup(input: RNTInput, section: string, db: any, locationData: LocationData) {
    let value;
    let bucketedValue;
    let key;

    switch (input.key) {
      case 'zipcode':
        bucketedValue = "Zipcode"
        key = `${input.id} | ${bucketedValue}`;
        break;
      case 'windspeed':
        value = locationData.windspeed;
        if (value < 5) bucketedValue = "<5";
        else if (5 <= value && value < 10) bucketedValue = "5-10";
        else bucketedValue = "10+";
        key = `${input.id} | ${bucketedValue}`;
        break;
      case 'temperature':
        value = locationData.temperature;
        if (value < 45) bucketedValue = "<45";
        else if (45 <= value && value < 65) bucketedValue = "45-65";
        else if (65 <= value && value < 85) bucketedValue = "65-85";
        else bucketedValue = "85+";
        key = `${input.id} | ${bucketedValue}`;
        break;
      case 'precipitation':
        value = locationData.precipitation;
        if (value < 22.34) bucketedValue = "Barely Rainy";
        else if (22.34 <= value && value < 36.30) bucketedValue = "Moderately Rainy";
        else bucketedValue = "Very Rainy";
        key = `${input.id} | ${bucketedValue}`;
        break;
      case 'uv':
        value = locationData.uv;
        if (value < 2) bucketedValue = "<2";
        else if (2 <= value && value < 5) bucketedValue = "2-5";
        else if (5 <= value && value < 7) bucketedValue = "5-7";
        else bucketedValue = "10+";
        key = `${input.id} | ${bucketedValue}`;
        break;
      case 'humidity':
        value = locationData.humidity;
        if (value < 0.20) bucketedValue = "<20";
        else if (0.20 <= value && value < 0.40) bucketedValue = "20-40";
        else if (0.40 <= value && value < 0.60) bucketedValue = "40-60";
        else if (0.60 <= value && value < 0.80) bucketedValue = "60-80";
        else bucketedValue = "80+";
        key = `${input.id} | ${bucketedValue}`;
        break;
      case 'aqi':
        value = locationData.aqi;
        if (value < 50) bucketedValue = "<50";
        else if (50 <= value && value < 100) bucketedValue = "50-100";
        else bucketedValue = "100+";
        key = `${input.id} | ${bucketedValue}`;
        break;
      default:
        break;
    }

    let ref = db.ref(`${section}/${key}`);
    return ref.once('value').then(snapshot => {
      let data = snapshot.val();
      if (!data) this.unfoundKeys[input.id] = [input, key, section];
      if (data ?.products) this.productSubtypes.push({
        subtypes: data.products.split('\n'),
        section: section,
      });
      data.value = value;
      return data;
    }).catch(err => {
      console.log(err)
      return err;
    });
  }

  private createRNTKey(input: RNTInput, quizResults: QuizResults): string {
    let subkeyLookup: string[] = [];
    input.key.split('+').map(subkey => subkey.trim()).forEach(subkey => {
      if (subkey.startsWith('**')) {
        subkeyLookup.push(`${subkey.split('**')[1]}`);
      }
      else {
        let result = quizResults[subkey];
        if (typeof result === "object") {
          result = Array.from(new Set(result));
          result = result.filter((s: string) => {
            switch (s) {
              case "Scalp Health: Prefer Not to Say":
              case "Scalp Health: None":
              case "Scalp Health: Allergic Reactions":
              case "Scalp Health: Hyper-sensitivity":
              case "Pre-Existing Conditions and Health: None":
              case "Pre-Existing Conditions and Health: No Menopause or Andropause":
              case "Pre-Existing Conditions and Health: Prefer Not to Say":
              case "Pre-Existing Conditions and Health: Not Applicable":
              case "Pre-Existing Conditions and Health: Other Medications":
              case "Pre-Existing Conditions and Health: Other Conditions":
              case "Chemical Transformation: Untreated or Natural":
                return false
              default:
                return true;;
            }
          }).forEach((s: string) => {
            subkeyLookup.push(s);
          })
        }
        else {
          result = result.trim();
          if (input.id === "CP1_ET1_ET2R" && subkey === "curlPattern") {
            result = `Type ${result[0]}`
          }
          switch (result) {
            case "Porosity: Normal or Closer to High":
            case "Porosity: Normal or Closer to Low":
              result = "Porosity: Normal";
              break;
            default:
              break;
          }
          subkeyLookup.push(result);
        }
      }
      return;
    });

    subkeyLookup = subkeyLookup.sort();
    const RNTkey = `${input.id.split('_').join('')} | ${subkeyLookup.join(" + ")}`;
    return RNTkey;
  }



}

////////////////////////////////////////////////
// WEATHER APIS ////////////////////////////////
////////////////////////////////////////////////


export async function fetchLocationData(zipcode: string, config: any) {
  const url = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/weatherdata/historysummary";

  const now: Date = new Date();

  const options = {
    method: 'GET',
    redirect: 'follow',
  };

  const startDateTime = `${now.getFullYear() - 1}-${now.getMonth() + 1}-${now.getDate() - 1}`;
  const endDateTime = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate() - 1}`;
  const minYear = `${now.getFullYear() - 1}`;
  const maxYear = `${now.getFullYear()}`;

  const params = format({
    query: {
      goal: "historysummary",
      aggregateHours: "24",
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      collectStationContributions: false,
      maxStations: "-1",
      maxDistance: "-1",
      minYear: minYear,
      maxYear: maxYear,
      chronoUnit: "years",
      breakBy: "self",
      dailySummaries: "true",
      shortColumnNames: "false",
      sendAsDatasource: "false",
      allowAsynch: "false",
      contentType: "json",
      unitGroup: "us",
      key: config.env.vc_api_key,
      locations: zipcode,
    },
  });

  let locationData: LocationData = {};
  return fetch(url + params, options)
    .then((res: any) => res.json())
    .then((res: any) => {
      if (res.locations) {
        locationData.temperature = res.locations[zipcode].values[0].temp;
        locationData.windspeed = res.locations[zipcode].values[0].wspd_mean;
        locationData.precipitation = res.locations[zipcode].values[0].precip;
      }
      return fetchAirQualityData(config, zipcode);
    })
    .then((res: any) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          let data = res[i];
          if (data.ParameterName === "PM2.5") {
            locationData.aqi = data.AQI;
          }
        }
      }
      return fetchGeocodeData(config, zipcode)
    })
    .then((res: any) => {
      if (res.status === "OK") {
        locationData.lat = res.results[0].geometry.location.lat;
        locationData.long = res.results[0].geometry.location.lng;
        var address_components = res.results[0].address_components;
        // unpack city and state from address components
        address_components.forEach((component: any) => {
          component.types.forEach((type: any) => {
            if (type === 'locality') {
              locationData.city = component.long_name;
            }
            if (type === "administrative_area_level_1") {
              locationData.state = component.short_name;
            }
          })
        })
      }
      if (locationData.lat && locationData.long) {
        return fetchDarkSkyData(config, locationData);
      }
      else return { error: "No location found." }
    })
    .then((res: any) => {
      if (!res.error && res.daily) {
        locationData.humidity = res.daily.data[0].humidity;
        locationData.uv = res.daily.data[0].uvIndex;
      }
      return locationData;
    })
    .catch((err: any) => {
      console.log('error', err);
      return err;
    });
}

export async function fetchGeocodeData(config: any, zipcode: string) {
  const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + zipcode + '&key=' + config.env.geocode_api_key + '&type=json&_';

  const options = {
    headers: { "Content-Type": "application/json" },
  };

  // fetch geocode api to retrieve city and state from zip code
  return fetch(url, options).then(res => res.json());
}

export async function fetchAirQualityData(config: any, zipcode: string) {
  const url = "https://www.AirNowAPI.org/aq/forecast/zipCode/";
  const options = {
    method: 'GET',
    redirect: 'follow',
  };
  const params = format({ // visual crossing
    query: {
      zipCode: zipcode,
      format: "application/json",
      api_key: config.env.airnow_api_key,
    },
  });
  return fetch(url + params, options).then((res: any) => res.json());
}

export async function fetchDarkSkyData(config: any, locationData: LocationData) {
  const time = Math.round(new Date(new Date().setHours(12, 0, 0, 0)).getTime()
    / 1000);

  const apiKey = config.env.darksky_api_key;
  const location = locationData.lat + "," + locationData.long + ",";
  const options = {
    method: 'GET',
    redirect: 'follow',
  };
  const url = "https://api.darksky.net/forecast/" + apiKey + "/" + location + time;
  return fetch(url, options).then((res: any) => res.json());
}

////////////////////////////////////////////////
// INTERFACES //////////////////////////////////
////////////////////////////////////////////////

export interface ReportReference {
  id: string,
  createdDate: Timestamp,
  isPaid?: boolean,
}

export interface RNTInputData {
  narrativeInputId: string,
  key: string,
  type: string,
  mainSection: string,
  sectionVariables: string,
  sectionsForKey: string,
  narrative: string,
  description: string,
  recommendation: string,
  routines: string[],
  techniques: string[],
  productTypes: string[],
  sourceTitles: string[],
  sourceLinks: string[],
}

export interface RNTInput {
  id: string,
  key: string,
  type: string,
  data?: RNTInputData | RNTInputData[] | string,
}

export interface LocationData {
  zipcode?: string,
  city?: string,
  state?: string,
  long?: number,
  lat?: number,
  temperature?: number,
  windspeed?: number,
  precipitation?: number,
  aqi?: number,
  humidity?: number,
  uv?: number,
}

export interface Welcome {
  W1: string,
  W2: string,
};

export interface Demographics {
  D1: string,
  D2: string,
  D3: string,
  D4: string,
  D5: string,
  D6: string,
  D7: string[],
  D8: string[],
};

class HP {

  curlPattern = {
    narrative: {
      CP1: {
        id: "CP1",
        key: "curlPattern",
        type: "result",
        data: null,
      },
      CP1D: {
        id: "CP1D",
        key: "curlPattern",
        type: "description",
        data: null,
      },
    },

    inYourControl: {
      ET1: {
        id: "ET1",
        key: "heatTransformation",
        type: "result",
        data: null,
      },
      ET2: {
        id: "ET2",
        key: "chemicalTransformation",
        type: "result",
        data: null,
      },
      CP1_ET1_ET2R: {
        id: "CP1_ET1_ET2R",
        key: "curlPattern + heatTransformation + chemicalTransformation",
        type: "recommendation",
        data: null,
      },
    },

    outOfYourControl: {
      CP1_HD1R: {
        id: "CP1_HD1R",
        key: "curlPattern + hairDensity",
        type: "recommendation",
        data: null,
      },
      CP1_HL1R: {
        id: "CP1_HL1R",
        key: "curlPattern + hairLength",
        type: "recommendation",
        data: null,
      },
    },
  };

  strandThickness = {
    narrative: {
      ST1: {
        id: "ST1",
        key: "strandThickness",
        type: "result",
        data: null,
      },
      ST1D: {
        id: "ST1D",
        key: "strandThickness",
        type: "description",
        data: null,
      },
    },

    inYourControl: {
      ST1: {
        id: "ST1",
        key: "strandThickness",
        type: "result",
        data: null,
      },
      ET4: {
        id: "ET4",
        key: "currentState",
        type: "result",
        data: null,
      },
      ST1_ET4R: {
        id: "ST1_ET4R",
        key: "strandThickness + currentState",
        type: "recommendation",
        data: null,
      },
    },

    outOfYourControl: {
      HD1: {
        id: "HD1",
        key: "hairDensity",
        type: "result",
        data: null,
      },
      HL1: {
        id: "HL1",
        key: "hairLength",
        type: "result",
        data: null,
      },
      ST1: {
        id: "ST1",
        key: "strandThickness",
        type: "result",
        data: null,
      },
      ST1_HL1R: {
        id: "ST1_HL1R",
        key: "strandThickness + hairLength",
        type: "recommendation",
        data: null,
      },
    },
  };

  porosity = {
    narrative: {
      P1: {
        id: "P1",
        key: "porosity",
        type: "result",
        data: null,
      },
      P1D: {
        id: "P1D",
        key: "porosity",
        type: "description",
        data: null,
      },
    },

    inYourControl: {
      ET1: {
        id: "ET1",
        key: "heatTransformation",
        type: "result",
        data: null,
      },
      ET2: {
        id: "ET2",
        key: "chemicalTransformation",
        type: "result",
        data: null,
      },
      P1_ET1_ET2R: {
        id: "P1_ET1_ET2R",
        key: "porosity + heatTransformation + chemicalTransformation",
        type: "recommendation",
        data: null,
      },
    },
  };

  hairLength = {
    narrative: {
      HL1: {
        id: "HL1",
        key: "hairLength",
        type: "result",
        data: null,
      },
      HL1D: {
        id: "HL1D",
        key: "hairLength",
        type: "description",
        data: null,
      },
    },

    inYourControl: {
      ET1: {
        id: "ET1",
        key: "heatTransformation",
        type: "result",
        data: null,
      },
      ET2: {
        id: "ET2",
        key: "chemicalTransformation",
        type: "result",
        data: null,
      },
      ET3: {
        id: "ET3",
        key: "physicalTransformation",
        type: "result",
        data: null,
      },
      ET4: {
        id: "ET4",
        key: "currentState",
        type: "result",
        data: null,
      },
      HL1_ET1_ET3_ET4_ET2R: {
        id: "HL1_ET1_ET3_ET4_ET2R",
        key: "hairLength + heatTransformation + chemicalTransformation + currentState + physicalTransformation",
        type: "recommendation",
        data: null,
      },
      H1: {
        id: "H1",
        key: "scalpAndHairHealth",
        type: "result",
        data: null,
      },
      H2: {
        id: "H2",
        key: "scalpAndHairHealth",
        type: "result",
        data: null,
      },
      HL1_H1R: {
        id: "HL1_H1R",
        key: "hairLength + scalpAndHairHealth",
        type: "recommendation",
        data: null,
      },
    },
  };

  hairDensity = {
    narrative: {
      HD1: {
        id: "HD1",
        key: "hairDensity",
        type: "result",
        data: null,
      },
      HD1D: {
        id: "HD1D",
        key: "hairDensity",
        type: "description",
        data: null,
      },
    },

    inYourControl: {
      H1: {
        id: "H1",
        key: "scalpAndHairHealth",
        type: "result",
        data: null,
      },
      H2: {
        id: "H2",
        key: "scalpAndHairHealth",
        type: "result",
        data: null,
      },
      HD1_H1R: {
        id: "HD1_H1R",
        key: "hairDensity + scalpAndHairHealth",
        type: "recommendation",
        data: null,
      },
      ET2: {
        id: "ET2",
        key: "chemicalTransformation",
        type: "result",
        data: null,
      },
      ET3: {
        id: "ET3",
        key: "physicalTransformation",
        type: "result",
        data: null,
      },
      ET4: {
        id: "ET4",
        key: "currentState",
        type: "result",
        data: null,
      },
      HDET2_ET4_ET3R: {
        id: "HDET2_ET4_ET3R",
        key: "chemicalTransformation + currentState + physicalTransformation",
        type: "recommendation",
        data: null,
      },
    },
  };

}

export class ET {
  narrative = {
    ET1: {
      id: "ET1",
      key: "heatTransformation",
      type: "result",
      data: null,
    },
    ET1D: {
      id: "ET1D",
      key: "heatTransformation",
      type: "description",
      data: null,
    },
    ET2: {
      id: "ET2",
      key: "chemicalTransformation",
      type: "result",
      data: null,
    },
    ET2D: {
      id: "ET2D",
      key: "chemicalTransformation",
      type: "description",
      data: null,
    },
    ET3: {
      id: "ET3",
      key: "physicalTransformation",
      type: "result",
      data: null,
    },
    ET3D: {
      id: "ET3D",
      key: "physicalTransformation",
      type: "description",
      data: null,
    },
    ET4: {
      id: "ET4",
      key: "currentState",
      type: "result",
      data: null,
    },
    ET4D: {
      id: "ET4D",
      key: "currentState",
      type: "description",
      data: null,
    },
    ET5D: {
      id: "ET5D",
      key: "heatTransformation + chemicalTransformation + currentState + physicalTransformation",
      type: "description",
      data: null,
    },
    ET5R: {
      id: "ET5R",
      key: "heatTransformation + chemicalTransformation + currentState + physicalTransformation",
      type: "recommendation",
      data: null,
    },
  }
};

export class EF {
  narrative = {
    EF1: {
      id: "EF1",
      key: "zipcode",
      type: "result",
      data: null,
    },
    EF2: {
      id: "EF2",
      key: "windspeed",
      type: "apiLookup",
      data: null,
    },
    EF3: {
      id: "EF3",
      key: "temperature",
      type: "apiLookup",
      data: null,
    },
    EF4: {
      id: "EF4",
      key: "precipitation",
      type: "apiLookup",
      data: null,
    },
    EF5: {
      id: "EF5",
      key: "uv",
      type: "apiLookup",
      data: null,
    },
    EF6: {
      id: "EF6",
      key: "humidity",
      type: "apiLookup",
      data: null,
    },
    EF7: {
      id: "EF7",
      key: "aqi",
      type: "apiLookup",
      data: null,
    },
  }
};

class Health {
  scalpAndHairHealth = {
    narrative: {
      H1: {
        id: "H1",
        key: "scalpAndHairHealth",
        type: "result",
        data: null,
      },
      H1D: {
        id: "H1D",
        key: "scalpAndHairHealth",
        type: "multipleDescription",
        data: null,
      },
      H2: {
        id: "H2",
        key: "scalpAndHairHealth",
        type: "result",
        data: null,
      },
    },
    inYourControl: {
      H3: {
        id: "H3",
        key: "scalpAndHairHealth",
        type: "result",
        data: null,
      },
      H3D: {
        id: "H3D",
        key: "scalpAndHairHealth",
        type: "multipleDescription",
        data: null,
      },
      H4: {
        id: "H4",
        key: "**Scalp Health: Breakage, Split Ends, or Patchy Hair",
        type: "description",
        data: null,
      },
      H5: {
        id: "H5",
        key: "**Scalp Health: Pus",
        type: "description",
        data: null,
      },
      H6: {
        id: "H6",
        key: "**Scalp Health: Breakage, Split Ends, or Patchy Hair",
        type: "description",
        data: null,
      },
      H7: {
        id: "H7",
        key: "**Scalp Health: Pus",
        type: "description",
        data: null,
      },
    },
  }

  preExistingConditionsAndHealth = {
    narrative: {
      H8: {
        id: "H8",
        key: "preExistingConditionsAndHealth",
        type: "result",
        data: null,
      },
      H9: {
        id: "H8",
        key: "preExistingConditionsAndHealth",
        type: "result",
        data: null,
      },
      H10: {
        id: "H10",
        key: "preExistingConditionsAndHealth",
        type: "result",
        data: null,
      },
      H10D: {
        id: "H10D",
        key: "preExistingConditionsAndHealth",
        type: "multipleDescription",
        data: null,
      },
    },
  }
};
